import { Button } from '@jobber/components/Button';
import React, { useState } from 'react';
import LogModal from './LogModal';

function ActivityLog() {
	const [show, setShow] = useState(false);
	return (
		<>
			<Button icon="redo" size='small' type='secondary' label="Activity Log" onClick={() => setShow(!show)} />
			{show && <LogModal open={show} onCancel={() => setShow(false)} onConfirm={() => {}} />}
		</>
	);
}

export default ActivityLog;
