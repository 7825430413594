import { Button } from '@jobber/components/Button';
import { showToast } from '@jobber/components/Toast';
import React, { useEffect, useState } from 'react';
import { postToServer } from './requests';
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Checkbox } from '@jobber/components/Checkbox';

function QuoteDateRangeModal({ open, onConfirm, onCancel }) {
	const [statuses, setStatuses] = useState(['approved']);
	const handleCheckboxChange = (e, status) => {
		if (e) {
			setStatuses((prevStatuses) => [...prevStatuses, status]);
		} else {
			setStatuses((prevStatuses) => prevStatuses.filter((s) => s !== status));
		}
	};

	useEffect(() => {
		console.log(statuses);
	}, [statuses]);

	const [date, setDate] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection'
		}
	]);
	return (
		<>
			{open ? (
				<div role="dialog" class="QGRLFHPoV5E-" tabindex="0" style={{ zIndex: '99999' }}>
					<div class="_4WzGOMUGj5I-" style={{ opacity: '0.8' }}></div>
					<div class="WLId2NJBQcQ-" style={{ opacity: '1', transform: 'none' }}>
						<div class="wjrPpIUNNrk-" data-testid="modal-header">
							<h3 class="asDB9Ra2UpA- kA3NtPPWbP0- vCrvF55YQ9o- fLix4jA-RkU-">Date Range</h3>
						</div>
						<div class="_4On2F-4nWuY- SRYe3DkjH1c-">
							<div>
								<Checkbox
									label={'Sync Approved Quotes?'}
									checked={statuses.includes('approved')}
									onChange={(e) => handleCheckboxChange(e, 'approved')}
								/>
							</div>
							<div>
								<Checkbox
									label={'Sync Converted Quotes?'}
									checked={statuses.includes('converted')}
									onChange={(e) => handleCheckboxChange(e, 'converted')}
								/>
							</div>
							{/* <div class="_4On2F-4nWuY- SRYe3DkjH1c-">
								<p class="asDB9Ra2UpA- lziyWzzvuno- V6yPPLHLeuY- _1g0uMAwFAsk-">Please select date range to continue.</p>
							</div> */}
							<div>
								<DateRangePicker
									rangeColors={['#7DB00E']}
									onChange={(item) => setDate([item.selection])}
									moveRangeOnFirstSelection={false}
									ranges={date}
								/>
							</div>
						</div>
						<div class="_62c8HLZFwvs-">
							<div class="p2s7GtpoZz0-">
								<Button
									label="Start"
									onClick={() => onConfirm(format(date?.[0]?.startDate, 'yyyy-MM-dd'), format(date?.[0]?.endDate, 'yyyy-MM-dd'), statuses)}
								/>
								<button class="U9VsquOtk2I- amIh7vPETfo- vDKO-cmh8vY- FXqN12jWtPo-" type="button" onClick={onCancel}>
									<span class="asDB9Ra2UpA- kA3NtPPWbP0- V6yPPLHLeuY- mRpfVoMiIMM-">Cancel</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}

export default QuoteDateRangeModal;
