import { Button } from "@jobber/components/Button";
import { Spinner } from "@jobber/components/Spinner";
import { showToast } from "@jobber/components/Toast";
import Form from 'react-bootstrap/Form';
import React, { useState } from "react";
import { postToServer } from "./requests";

function LockerModal({ open, onConfirm, onCancel }) {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const submitHandler = async () => {
    if (!password) {
      showToast({ message: "Password is required", variation: "error" });
      return;
    }
    setLoading(true);
    const result = await postToServer("verify-lock", { password });
    if (result.status) {
      if (result.isCorrectPassword) {
        setPassword('')
        onConfirm()
      } else {
        showToast({ message: "Password is not correct", variation: "error" });
      }
    } else {
      showToast({
        message: result.message || result.error,
        variation: "error",
      });
    }
    setLoading(false);
  };
  return (
    <>
      {open ? (
        <div
          role="dialog"
          class="QGRLFHPoV5E-"
          tabindex="0"
          style={{ zIndex: "99999" }}
        >
          <div class="_4WzGOMUGj5I-" style={{ opacity: "0.8" }}></div>
          <div class="WLId2NJBQcQ-" style={{ opacity: "1", transform: "none" }}>
            <div class="wjrPpIUNNrk-" data-testid="modal-header">
              <h3 class="asDB9Ra2UpA- kA3NtPPWbP0- vCrvF55YQ9o- fLix4jA-RkU-">
                It's Locked
              </h3>
            </div>
            <div class="_4On2F-4nWuY- SRYe3DkjH1c-">
              <div class="_4On2F-4nWuY- SRYe3DkjH1c-">
                <p class="asDB9Ra2UpA- lziyWzzvuno- V6yPPLHLeuY- _1g0uMAwFAsk-">
                  Please enter your password to continue.
                </p>
              </div>
              <div>
                <Form noValidate validated={password === ""}>
                  <Form.Control
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Password is required
                  </Form.Control.Feedback>
                </Form>
              </div>
            </div>
            <div class="_62c8HLZFwvs-">
              <div class="p2s7GtpoZz0-">
                {loading ? (
                  <Spinner size="base" />
                ) : (
                  <Button label="Submit" onClick={submitHandler} />
                )}
                <button
                  class="U9VsquOtk2I- amIh7vPETfo- vDKO-cmh8vY- FXqN12jWtPo-"
                  type="button"
                  onClick={onCancel}
                >
                  <span class="asDB9Ra2UpA- kA3NtPPWbP0- V6yPPLHLeuY- mRpfVoMiIMM-">
                    Cancel
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default LockerModal;
