import { createSlice } from "@reduxjs/toolkit";

const jobFilterSlice = createSlice({
  name: "filter",
  initialState: {
    jobFilters: [],
    jobFilterQuery: {},
    status:'All',
    entries: 0,
    runing: true,
    isSavedFilterApplied: false,
  },
  reducers: {
    setJobSavedFilters(state, action) {
      state.jobFilters = action.payload;
    },
    setIsSavedFilterAppliedFilters(state, action) {
      state.isSavedFilterApplied = !state.isSavedFilterApplied;
    },
    setStatusFilters(state, action) {
      state.status = action.payload;
    },
    setReduxEntries(state, action) {
      state.entries = action.payload;
      state.runing = false;
    },
    setRuning(state, action) {
      state.runing = action.payload;
    },
    setJobFilterQuery(state, action) {
      state.jobFilterQuery = action.payload;
    },
  },
});

export const { setJobSavedFilters, setReduxEntries, setRuning, setJobFilterQuery,setStatusFilters, setIsSavedFilterAppliedFilters } =
  jobFilterSlice.actions;
export default jobFilterSlice.reducer;
