import { useEffect, useState } from 'react';
import { Modal } from '@jobber/components/Modal';
import { Content } from '@jobber/components/Content';
import { Col, Row } from 'react-bootstrap';
import { FaCalendar, FaPhoneAlt } from 'react-icons/fa';

import { IoLocation } from 'react-icons/io5';
import { formatDateYearDay } from '../../../components/Common/Validations';

function VisitDetails({ open, onCancel, data }) {
	return (
		<>
			<Modal
				title={'Visit Details'}
				open={open}
				// primaryAction={{ label: 'Save', onClick: handleBody }}
				secondaryAction={{
					label: 'Cancel',
					onClick: onCancel
				}}
				onRequestClose={onCancel}
			>
				<Content>
					<Row>
						<Col lg={6}>
							<h5 className="fw-bold">{data?.title}</h5>
							<p>
								{data?.jobDetails?.client?.name} <br />
								{data?.jobDetails?.property?.address?.street} <br />
								{data?.jobDetails?.property?.address?.postalCode} <br />
							</p>
						</Col>
						<Col lg={6}>
							<div className="fw-bold fs-6 d-flex gap-3 align-items-center">
								<FaCalendar />
								{formatDateYearDay(data?.startAt)}-{formatDateYearDay(data?.endAt)}
							</div>
							{/* <div className="mt-3 fs-6 d-flex gap-3 align-items-center">
								<FaPhoneAlt />
							</div>
							<div className="mt-3 fs-6 d-flex gap-3 align-items-center">
								<IoLocation />
								Directions
							</div> */}
						</Col>
					</Row>
					<hr />
					<div className="mt-4">
						<h6 className="fw-bold">Instructions</h6>
						<p className="mt-2">{data.instructions || 'No Instructions'}</p>
					</div>
					<hr />
					<Row className="mt-4">
						<Col lg={5}>
							<div>
								<h6 className="fw-bold">Job</h6>
								<p className="mt-3">
									Job # {data.jobDetails?.jobNumber} <br /> {data.jobDetails?.title}
								</p>
							</div>
						</Col>
						<Col lg={7}>
							<div>
								<h6 className="fw-bold">Team</h6>
								<h6 className="mt-3">
									{data?.assignedUsers?.length <= 0 ? (
										<span className="badge bg-danger-subtle text-danger">{'Unassigned'}</span>
									) : (
										data?.assignedUsers?.map((a, i) => {
											return (
												<span key={i} className="badge bg-secondary me-1 mb-1">
													{a?.name}
												</span>
											);
										})
									)}
								</h6>
							</div>
						</Col>
						{/* <Col lg={4}>
							<div>
								<h6 className="fw-bold">Reminders</h6>
								<p className="mt-3">Email will be sent about 1 hour before</p>
							</div>
						</Col> */}
					</Row>
					<Row className="mt-4">
						<div>
							<h6 className="fw-bold">Line items</h6>
							<h6 className="fw-bold mt-3 d-flex justify-content-between gap-4">
								<span>Product / Service</span>
								<span>Qty</span>
							</h6>
							{data?.jobDetails?.lineItems?.map((l, i) => {
								return (
									<div key={i} className="d-flex justify-content-between gap-4">
										<p className="">
											<span className="fw-bold">{l?.name}</span> <br />
											<span>{l?.description}</span>
										</p>
										<p>{l?.quantity}</p>
									</div>
								);
							})}
						</div>
					</Row>
				</Content>
			</Modal>
		</>
	);
}

export default VisitDetails;
