import React from "react";

function ConfirmModal({ title, message, open, onConfirm, onCancel, buttonName = 'Delete' }) {
  return (
    <>
      {open ? (
        <div
          role="dialog"
          class="QGRLFHPoV5E-"
          tabindex="0"
          style={{ zIndex: "99999" }}
        >
          <div class="_4WzGOMUGj5I-" style={{ opacity: "0.8" }}></div>
          <div class="WLId2NJBQcQ-" style={{ opacity: "1", transform: "none" }}>
            <div class="wjrPpIUNNrk-" data-testid="modal-header">
              <h3 class="asDB9Ra2UpA- kA3NtPPWbP0- vCrvF55YQ9o- fLix4jA-RkU-">
                {title}
              </h3>
            </div>
            <div class="_4On2F-4nWuY- SRYe3DkjH1c-">
              <div class="_4On2F-4nWuY- SRYe3DkjH1c-">
                <p class="asDB9Ra2UpA- lziyWzzvuno- V6yPPLHLeuY- _1g0uMAwFAsk-">
                  {message}
                </p>
              </div>
            </div>
            <div class="_62c8HLZFwvs-">
              <div class="p2s7GtpoZz0-">
                <button
                  class="U9VsquOtk2I- amIh7vPETfo- _4W8X0dxZDK4- FXqN12jWtPo-"
                  type="button"
                  onClick={onConfirm}
                >
                  <span class="asDB9Ra2UpA- kA3NtPPWbP0- V6yPPLHLeuY- mRpfVoMiIMM-">
                    {buttonName}
                  </span>
                </button>
                <button
                  class="U9VsquOtk2I- amIh7vPETfo- vDKO-cmh8vY- FXqN12jWtPo-"
                  type="button"
                  onClick={onCancel}
                >
                  <span class="asDB9Ra2UpA- kA3NtPPWbP0- V6yPPLHLeuY- mRpfVoMiIMM-">
                    Cancel
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ConfirmModal;
