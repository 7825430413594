import React, { useEffect, useState } from 'react';
import { useNotesContext } from '../contextAPI/NotesContext';
import { Button } from '@jobber/components/Button';
import { Content } from '@jobber/components/Content';
import { Card } from '@jobber/components/Card';
import TagCloud from '../../Index/TagCloud/TagCloud';
import { Col, Row } from 'react-bootstrap';
import Filters from '../../Index/Filters/Filters';
import downFill from '../../../assets/icons/downFill.svg';
import sideFill from '../../../assets/icons/sideFill.svg';
import { Heading } from '@jobber/components/Heading';
import NotesSettings from './NotesSettings';
import { useDispatch, useSelector } from 'react-redux';
import { setUserTags } from '../../../store/tagsSlice';
import { getFromServer } from '../../../components/Common/requests';
import { Combobox } from '@jobber/components/Combobox';
import { useNotesFilter } from '../FilterQuery/NotesFilterQuery';

function FilterAndSettings() {
  const { settingsBar, triggerSettingsBar } = useNotesContext();
  const [tagCloud, setTagCloud] = useState(true);
  const [notesSettings, setNotesSettings] = useState(true);
  const [show, setShow] = useState(true);
  const { updateFilter } = useNotesFilter()

  const reloadTagCloudHandler = () => {
    setTagCloud(false);
    setTimeout(() => {
      setTagCloud(true);
    }, 10);
  };

  // status
  const [selected, setSelected] = useState([]);
  const statusHandler = () => {
    if (reduxFilters?.filterQuery?.filter) {
      if (reduxFilters?.filterQuery?.filter?.[0] == 'LeadAndActive') {
        setSelected([
          {
            id: 'LeadAndActive',
            label: 'Leads And Active'
          }
        ]);
      } else {
        setSelected([
          {
            id: reduxFilters?.filterQuery?.filter?.[0],
            label: reduxFilters?.filterQuery?.filter?.[0]
          }
        ]);
      }
    } else {
      setSelected([]);
    }
  };
  // useEffect(() => {
  //   statusHandler();
  // }, [reduxFilters?.isSavedFilterApplied]);

  return (
    <div className={`content ${settingsBar ? 'slide-in' : 'slide-out'}`}>
      <Card
        header={{
          title: 'Filter and Settings',
          action: <Button label="X" onClick={triggerSettingsBar} />
        }}
      >
        <Content>
          <Row>
            <Col lg={3}>
              <div className="d-flex pointer" onClick={() => setTagCloud((prev) => !prev)}>
                {tagCloud ? <img src={downFill} className="me-2" /> : <img src={sideFill} className="me-2" />}
                <Heading level={3}>Tag Cloud</Heading>
              </div>
              {tagCloud && (
                <div className="mt-3">
                  <TagCloud />
                </div>
              )}
            </Col>
            <Col lg={5}>
              <Filters reload={reloadTagCloudHandler} editSavedFilterId={null} />

              <div className="mt-4">
                <div className="d-flex pointer" onClick={() => setShow((prev) => !prev)}>
                  {show ? <img src={downFill} className="me-2" /> : <img src={sideFill} className="me-2" />}
                  <Heading level={3}>Filter by status</Heading>
                </div>
                  <div className='m-3'>
                    <Combobox
                      onSelect={(e) => {
                        setSelected(e);
                        updateFilter('filter', e?.[0]?.id);
                      }}
                      selected={selected}
                      label="Status"
                    >
                      <Combobox.Option id="All" label="All" />
                      <Combobox.Option id="LeadAndActive" label="Leads and Active" />
                      <Combobox.Option id="Lead" label="Leads" />
                      <Combobox.Option id="Active" label="Active" />
                      <Combobox.Option id="Archived" label="Archieved" />
                    </Combobox>
                  </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="d-flex pointer" onClick={() => setNotesSettings((prev) => !prev)}>
                {notesSettings ? <img src={downFill} className="me-2" /> : <img src={sideFill} className="me-2" />}
                <Heading level={3}>Notes Settings</Heading>
              </div>
              {notesSettings && (
                <div className="mt-3">
                  <NotesSettings />
                </div>
              )}
            </Col>
          </Row>
        </Content>
      </Card>
    </div>
  );
}

export default FilterAndSettings;
