import React, { createContext, useState } from "react";
import App from "./App";

const SocketContext = createContext();

function SocketProvider() {
  const [socket, setSocket] = useState(null);
  const [reloadUndo, setReloadUndo] = useState(true);
  const socketHandler = (sock) => {
    console.log('socket recieved')
    setSocket(sock);
  };
  const undoReloadHandler = () => {
    setReloadUndo(false)
    setTimeout(() => {
      setReloadUndo(true)
    }, 500);
  }
  return (
    <React.Fragment>
      <SocketContext.Provider value={{ socket, socketHandler, undoReloadHandler, reloadUndo }}>
        <App />
      </SocketContext.Provider>
    </React.Fragment>
  );
}

export default SocketProvider;
export {SocketContext}
