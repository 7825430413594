import React, { useState } from 'react';
import { formatDateTimeDay, formatNumber, formatNumberWithSpaces, formatYearDateTimeDay } from '../../../components/Common/Validations';
import TableDescription from './TableDescription';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPreservedR } from '../../../store/automationTable';
import { Checkbox } from '@jobber/components/Checkbox';
import { Heading } from '@jobber/components/Heading';

function TableRow({ s, visibleColumns, isRecycle, selectedIds, handleRowCheck }) {
	const dispatch = useDispatch();
	const preserveStateHandler = (event) => {
		if (!event.ctrlKey) {
			dispatch(setPreservedR(true));
		}
	};
	return (
		<tr
			className="pointer"
			style={{
				// position: 'relative',
				// zIndex: '1'
			}}
		>
			<td className='notAccept'>
				<Checkbox label={''} checked={selectedIds.includes(s.quoteId)} onChange={(e) => handleRowCheck(s.quoteId, e)} />
			</td>
			<TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.name}>
				<Heading level={5}>{s?.clientName}</Heading>
				<p style={{ color: 'var(--muted)' }}>{s?.clientSecondaryName}</p>
				{/* {s?.clientFirstName} {s?.clientLastName} */}
			</TableDescription>
			<TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.title}>
				{s?.quoteTitle}
			</TableDescription>
			<TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.address}>
				{s?.quoteProperty?.street}
			</TableDescription>
			<TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.zipCode}>
				{s?.quoteProperty?.postalCode}
			</TableDescription>
			<TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.phone}>
				{s?.clientPhone}
			</TableDescription>
			<TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.email}>
				{s.clientEmail}
			</TableDescription>
			{/* <TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.approved}>
        {s?.approved}
      </TableDescription> */}
			<TableDescription url={s?.jobberWebUri} isUrl={false} isVisible={visibleColumns?.details}>
				{!isRecycle ? (
					<Link
						to={{ pathname: `/advance-quotes/approved/${s._id}` }}
						className="text-primary animate text-decoration-underline"
						onClick={preserveStateHandler}
					>
						Details
					</Link>
				) : (
					'Restore to view Detail page'
				)}
			</TableDescription>
			<TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.totalCost}>
				<div>
					<span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(s?.totalCost))}</span>
				</div>
			</TableDescription>
			{/* <TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.amountPaid}>
        <div>
          <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(s?.groupAmountPaid))}</span>
        </div>
      </TableDescription>
      <TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.amountOwed}>
        <div>
          <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(s?.groupAmountOwed))}</span>
        </div>
      </TableDescription> */}
			{/* <TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.QBtotalCost}>
        <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(s?.totalQBCost))}</span>
      </TableDescription>
      <TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.QBamountPaid}>
        <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(s?.amountQBPaid))}</span>
      </TableDescription>
      <TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.QBamountOwed}>
        <span style={{ textWrap: 'nowrap' }}>${formatNumberWithSpaces(formatNumber(s?.amountQBOwed))}</span>
      </TableDescription> */}
			<TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.date}>
				{formatYearDateTimeDay(s?.quote_createdAt)}
			</TableDescription>
			<TableDescription url={s?.jobberWebUri} isVisible={visibleColumns?.approvedDate}>
				{s?.approved_at ? formatYearDateTimeDay(s?.approved_at) : '-'}
			</TableDescription>
		</tr>
	);
}

export default TableRow;
