import React, { useEffect } from 'react';
import { useVisitContext } from '../contextAPI/VisitContext';
import { Badge } from 'react-bootstrap';

function AssignedUsers() {
	const { users, selectedUsers, setSelectedUsers } = useVisitContext();

	// Toggle user selection by id
	const selectedUserHandler = (user) => {
		setSelectedUsers((prevSelectedUsers) => {
			if (prevSelectedUsers.some((selectedUser) => selectedUser.id === user.id)) {
				// Remove user by filtering out the selected user object
				return prevSelectedUsers.filter((selectedUser) => selectedUser.id !== user.id);
			} else {
				// Add user object to the selectedUsers list
				return [...prevSelectedUsers, user];
			}
		});
	};

	// Synchronize selectedUsers with users to remove non-existent users
	useEffect(() => {
		setSelectedUsers((prevSelectedUsers) => prevSelectedUsers.filter((selectedUser) => users.some((user) => user.id === selectedUser.id)));
	}, [users, setSelectedUsers]);

	return (
		<div className="d-flex gap-2 flex-wrap">
			{users?.map((user) => {
				const isSelected = selectedUsers.some((selectedUser) => selectedUser.id === user.id);
				return (
					<h5 key={user.id} onClick={() => selectedUserHandler(user)}>
						<Badge
							bg={isSelected ? 'dark' : 'light'}
							text={isSelected ? 'light' : 'dark'}
							className={`border border-1 ${isSelected ? "": "text-muted"}`}
							style={{ cursor: 'pointer' }}
						>
							{user.label}
						</Badge>
					</h5>
				);
			})}
		</div>
	);
}

export default AssignedUsers;
