import { Button } from '@jobber/components/Button';
import React, { useEffect, useState } from 'react';
import BulkTagModal from '../../../Index/BulkTagMangement/BulkTagModal';
import { getFromServer, postToServer } from '../../../../components/Common/requests';
import { Spinner } from '@jobber/components/Spinner';
import { showToast } from '@jobber/components/Toast';
import { useVisitFilters } from '../../FilterQuery/VisitFilterQuery';
import FailedTaskModal from '../../../../components/FailedTaskModal';

function BulkTag({ reload }) {
	const { buildQuery } = useVisitFilters();
	const [removeTags, setRemoveTags] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [multipleAddresses, setMultipleAddresses] = useState([]);
	const [loading, setLoading] = useState(false);

	const [includeSelected, setIncludeSelected] = useState([]);
	const [excludeSelected, setExcludeSelected] = useState([]);

	const [modalOpen, setModalOpen] = useState(false);
	const [failedData, setFailedData] = useState([]);

	const submitHandler = async (body) => {
		const queryString = buildQuery();
		setLoading(true);
		setShowModal(false);
		const result = await postToServer(`bulk-tags/${multipleAddresses?.length <= 0 ? 'true' : 'false'}?${queryString}`, body);
		if (result.status) {
			if (result?.multipleAddressFounded) {
				setShowModal(true);
				setIncludeSelected(result.tagsToAdd?.map((tag) => ({ id: tag, label: tag })));
				setExcludeSelected(result.tagsToDelete?.map((tag) => ({ id: tag, label: tag })));
				setMultipleAddresses(result.data);
			} else {
				showToast({
					message: 'Tag updated successfully'
				});
				setExcludeSelected([])
				setIncludeSelected([])
				setMultipleAddresses([]);
				reload();
			}

			if (result?.errors?.length) {
				setFailedData(result.errors);
				setModalOpen(true);
				showToast({
					message: 'Tags updated with some errors',
					variation: 'error'
				})
			}
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setLoading(false);
	};

	const init = async () => {
		const queryString = buildQuery();
		const result = await getFromServer(`get-client-tags?${queryString}`);
		if (result.status) {
			setRemoveTags(result.tags);
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
	};
	useEffect(() => {
		if (showModal) {
			init();
		}
	}, [showModal]);

	return (
		<div>
			<FailedTaskModal title='Failed process following jobs for tags!' showText={false} linkText='Add/Remove tag manually?' failedData={failedData} modalOpen={modalOpen} setModalOpen={setModalOpen} />
			{showModal && (
				<BulkTagModal
					headline="Bulk edit tags"
					open={showModal}
					removeTags={removeTags}
					onCancel={() => {
						setShowModal(false);
						setMultipleAddresses([]);
						setExcludeSelected([])
						setIncludeSelected([])
					}}
					onConfirm={submitHandler}
					multipleAddresses={multipleAddresses}
					exclude={excludeSelected}
					include={includeSelected}
				/>
			)}
			{loading ? <Spinner /> : <Button label={'Bulk edit tag'} onClick={() => setShowModal(true)} />}
		</div>
	);
}

export default BulkTag;
