import { Button } from '@jobber/components/Button'
import { Checkbox } from '@jobber/components/Checkbox'
import { Content } from '@jobber/components/Content'
import { Modal } from '@jobber/components/Modal'
import { Text } from '@jobber/components/Text'
import React, { useState } from 'react'

export default function DraftModal({ modalOpen, handleModalClose, handleSubmit, clientWithDrafts }) {
  const [uncheckedQuoteIds, setUncheckedQuoteIds] = useState([]);

  const handleCheckboxChange = (quoteId, draftQuoteNumber, checked) => {
    if (!checked) {
      // Add quoteId to the array if unchecked
      setUncheckedQuoteIds([...uncheckedQuoteIds, { quoteId, quoteNumber: draftQuoteNumber }]);
    } else {
      // Remove quoteId from the array if checked again
      setUncheckedQuoteIds(uncheckedQuoteIds.filter(item => item.quotesId !== quoteId));
    }
  };

  function primaryAction() {
    handleSubmit(uncheckedQuoteIds)
  }

  return (
    <Modal
      title={"These Clients Already have similar draft quotes"}
      open={modalOpen}
      onRequestClose={handleModalClose}
      primaryAction={{ label: "Submit", onClick: primaryAction }}
    >
      <Content>
        <Text>Unselect any clients you don&apos;t want quotes for; quotes will be generated for selected clients only, with draft quote numbers added to unselected ones.</Text>
        {clientWithDrafts.map((client, index) => (
          <div key={index} className="d-flex align-items-center justify-content-between p-2 border rounded">
            <Checkbox
              label={client.clientName}
              description={`${client.street}, ${client.postalCode}`}
              checked={!uncheckedQuoteIds.some(item => item.quoteId === client.quoteId)}
              onChange={(e) => handleCheckboxChange(client.quoteId, client.draftQuoteNumber, e)}
            />
            <Button
              label="View Client"
              onClick={() => window.open(client.clientWebUri)}
            />
          </div>
        ))}
      </Content>
    </Modal>
  )
}
