import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableTabs from './TableTabs';
import { Col, Row, Dropdown, DropdownItem } from 'react-bootstrap';
import { InputText } from '@jobber/components/InputText';
import { setSearchR } from '../../../store/automationTable';
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdSave } from 'react-icons/io';
import TotalLabels from '../TotalLabels/TotalLabels';
import { Glimmer } from '@jobber/components/Glimmer';
import TableRow from './TableRow';
import { Button } from '@jobber/components/Button';
import { Spinner } from '@jobber/components/Spinner';
import { Checkbox } from '@jobber/components/Checkbox';
import { useJobContext } from '../contextAPI/JobContext';

function QuotesTable({
  search,
  setSearch,
  toggleColumn,
  loading,
  showHeader,
  isSticky,
  columnVisibility,
  visibleColumns,
  onSort,
  getIconStyle,
  isAdvanceQuotes,
  data,
  isRecycle,
  dropdownToggleRef,
  toggleDropdown,
  showDropdown,
  saveLoader,
  saveUserSettingsHandler
}) {
  const dispatch = useDispatch();


  // multi row selection
  const { selectedIds, setSelectedIds } = useJobContext();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const handleRowCheck = (id, isChecked) => {
    if (isChecked) {
      if (!selectedIds.includes(id)) {
        setSelectedIds([...selectedIds, id]);
      }
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };

  const handleMasterCheck = (isChecked) => {
    setIsAllSelected(isChecked);
    if (isChecked) {
      const allIds = data.map((item) => item.quoteId);
      setSelectedIds((prevIds) => [...prevIds, ...allIds.filter((id) => !prevIds.includes(id))]);
    } else {
      setSelectedIds([]);
    }
  };

  useEffect(() => {
    const isChecked = data?.every((d) => selectedIds?.includes(d.quoteId));
    if (isChecked && data?.length > 0) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [selectedIds, data]);

  return (
    <div>
      <Row className="mt-2">
        <Col md={6}>
          <div className="d-flex gap-2" id="searchTerm">
            <InputText
              placeholder="Search"
              name="firstName"
              size="small"
              value={search}
              onChange={(e) => {
                dispatch(setSearchR(e));
                setSearch(e);
              }}
            />
            <div style={{ position: 'relative' }}>
              <Button label={'Manage Columns'} ref={dropdownToggleRef} onClick={toggleDropdown} />
              <div style={{ zIndex: '11', position: 'absolute' }}>
                <Dropdown show={showDropdown}>
                  <Dropdown.Menu>
                    <DropdownItem>Show and Hide Columns</DropdownItem>
                    <div className="ms-3">
                      {columnVisibility &&
                        columnVisibility?.map((c) => {
                          return (
                            <>
                              <Checkbox label={c.name} checked={visibleColumns[c.key]} onChange={() => toggleColumn(c.key)} />
                              <br />
                            </>
                          );
                        })}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {saveLoader ? (
              <Spinner size="base" />
            ) : isAdvanceQuotes ? (
              <Button label={'Save Settings'} type="secondary" onClick={saveUserSettingsHandler} />
            ) : (
              ''
            )}
          </div>
        </Col>
        <Col md={6}>{/* <TotalLabels totalAmount={totalAmount} loading={loading} /> */}</Col>
      </Row>
      {selectedIds?.length > 0 && <p className="mt-4 fw-bold">{selectedIds?.length} Items Selected</p>}
      <table data-resizable-columns-id="quotes" id="quotes__table" className={`mt-4 th--border table stable table-hover closedJob`}>
        {showHeader && (
          <thead>
            <tr className={`sticky z-2 check__sticky ${isSticky ? 'tag__category--sticky' : ''}`}>
              {/* column headers  */}
              <th className='notAccept' id='colCheckbox' style={{ width: '1%' }}>
                <Checkbox label={''} checked={isAllSelected} onChange={handleMasterCheck} />
              </th>
              {columnVisibility &&
                columnVisibility?.map((col, index) => {
                  return (
                    <th
                      key={index}
                      data-resizable-column-id={col.colName}
                      id={col.colName}
                      className={`accept ${visibleColumns[col.key] ? '' : 'd-none'}`}
                    >
                      {col?.filter ? (
                        <div className="d-flex justify-content-between">
                          <span className="drag__handle">{col.name}</span>
                          <div
                            className="icon-container"
                            onClick={(e) => {
                              e.stopPropagation();
                              onSort(col?.filter);
                            }}
                          >
                            <IoMdArrowDropup className={`icon-up ${getIconStyle(col?.filter, 'ASCENDING')}`} />
                            <IoMdArrowDropdown className={`icon-down ${getIconStyle(col?.filter, 'DESCENDING')}`} />
                          </div>
                        </div>
                      ) : (
                        <div className="drag__handle">{col.name}</div>
                      )}
                    </th>
                  );
                })}
            </tr>
          </thead>
        )}
        <tbody>
          {loading
            ? Array.from({ length: 2 }, (_, index) => (
              <tr>
                {Object.entries(visibleColumns)?.map(([key, value], index) => (
                  <React.Fragment key={index}>
                    {value && (
                      <td>
                        <Glimmer shape="rectangle" size="base" timing="speed" />
                      </td>
                    )}
                  </React.Fragment>
                ))}
                {isAdvanceQuotes && (
                  <td>
                    <Glimmer shape="rectangle" size="base" timing="speed" />
                  </td>
                )}
              </tr>
            ))
            : data &&
            data?.map((s, k) => {
              return (
                <TableRow
                  s={s}
                  selectedIds={selectedIds}
                  handleRowCheck={handleRowCheck}
                  visibleColumns={visibleColumns}
                  isRecycle={isRecycle}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default QuotesTable;
