import React, { useEffect, useState } from 'react';
import { Button } from '@jobber/components/Button';
import { Heading } from '@jobber/components/Heading';
import { Card } from '@jobber/components/Card';
import { Content } from '@jobber/components/Content';
import { Glimmer } from '@jobber/components/Glimmer';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '@jobber/components/Toast';
import ResetSidebar from '../../components/Common/ResetSidebar';
import { AdvanceNotesProvider } from './contextAPI/NotesContext';
import TopButtons from './Buttons/TopButtons';
import FilterAndSettings from './FilterAndSettings/FilterAndSettings';
import Table from './Table/Table';
import { NotesFilterProvider } from './FilterQuery/NotesFilterQuery';
import ShowAppliedFilters from '../../components/Common/ShowAppliedFilters';
import ActivityCalendar from './ActivityCalendar/ActivityCalendar';

function AdvanceNotes() {
	const [reload, setReload] = useState(true);
	const [showCalendar, setShowCalendar] = useState(false);
	const reloadHandler = () => {
		setReload(false);
		setTimeout(() => {
			setReload(true);
		}, 10);
	};
	return (
		<NotesFilterProvider>
			<ResetSidebar />
			<AdvanceNotesProvider>
				{showCalendar && <ActivityCalendar open={showCalendar} onCancel={() => setShowCalendar(false)} />}
				<div className="row">
					<div>
						<div className="d-flex justify-content-between align-items-center">
							<Heading level={1}>Advance Notes</Heading>
							<div className='d-flex gap-2'>
								<TopButtons />
								<Button label="Activity Calendar" onClick={() => setShowCalendar(true)} />
							</div>
						</div>
						<div className="mb-5">
							<ShowAppliedFilters page={'notes'} />
						</div>
						{reload && <FilterAndSettings />}
						<Table reload={reloadHandler} />
					</div>
				</div>
			</AdvanceNotesProvider>
		</NotesFilterProvider>
	);
}

export default AdvanceNotes;
