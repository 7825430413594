// visit table data

export const visitVisibleColumns = {
  clientName: true,
  title: true,
  instructions: true,
  propert: true,
  invoicing: true,
  assignedUser: true,
  status: true,
  type: true,
  total: true,
  schedule: true,
  createdAt: true,
  updatedAt: true
};

export const visitColumnVisibility = [
  {
    name: 'Name',
    key: 'clientName',
    filter: 'CLIENT_NAME',
    colName: 'colClientName'
  },
  {
    name: 'Title',
    key: 'title',
    filter: 'TITLE',
    colName: 'colTitle'
  },
  {
    name: 'Instructions',
    key: 'instructions',
    filter: 'INSTRUCTIONS',
    colName: 'colInstruction'
  },
  {
    name: 'Property',
    key: 'propert',
    filter: 'PROPERTY',
    colName: 'colProperty'
  },
  {
    name: 'Invoicing',
    key: 'invoicing',
    colName: 'colInvoicing'
  },
  {
    name: 'Assigned Team',
    key: 'assignedUser',
    colName: 'colAssignedUser'
  },
  {
    name: 'Status',
    key: 'status',
    filter: 'STATUS',
    colName: 'colStatus'
  },
  {
    name: 'Type',
    key: 'type',
    filter: 'TYPE',
    colName: 'colType'
  },
  {
    name: 'Total',
    key: 'total',
    filter: 'TOTAL',
    colName: 'colTotal'
  },
  {
    name: 'Schedule',
    key: 'schedule',
    colName: 'colSchedule'
  },
  {
    name: 'Created at',
    key: 'createdAt',
    filter: 'CREATED_AT',
    colName: 'colCreatedAt'
  },
  {
    name: 'Updated at',
    key: 'updatedAt',
    filter: 'UPDATED_AT',
    colName: 'colUpdatedAt'
  }
];

export const visitWidthData = [
  'colClientName',
  'colTitle',
  'colInstruction',
  'colProperty',
  'colInvoicing',
  'colAssignedUser',
  'colStatus',
  'colType',
  'colTotal',
  'colSchedule',
  'colCreatedAt',
  'colUpdatedAt'
];

// jobs table data
export const jobVisibleColumns = {
  checkbox: true,
  clientName: true,
  title: true,
  instructions: true,
  propert: true,
  invoicing: true,
  status: true,
  type: true,
  total: true,
  createdAt: true,
  updatedAt: true,
  closedAt: true,
};

export const jobColumnVisibility = [
  {
    name: 'Name',
    key: 'clientName',
    filter: 'CLIENT_NAME',
    colName: 'colClientName'
  },
  {
    name: 'Title',
    key: 'title',
    filter: 'TITLE',
    colName: 'colTitle'
  },
  {
    name: 'Instructions',
    key: 'instructions',
    filter: 'INSTRUCTIONS',
    colName: 'colInstruction'
  },
  {
    name: 'Property',
    key: 'propert',
    filter: 'PROPERTY',
    colName: 'colProperty'
  },
  {
    name: 'Schedule',
    key: 'invoicing',
    filter: 'SCHEDULE',
    colName: 'colInvoicing'
  },
  {
    name: 'Status',
    key: 'status',
    filter: 'STATUS',
    colName: 'colStatus'
  },
  {
    name: 'Type',
    key: 'type',
    filter: 'TYPE',
    colName: 'colType'
  },
  {
    name: 'Total',
    key: 'total',
    filter: 'TOTAL',
    colName: 'colTotal'
  },
  {
    name: 'Created at',
    key: 'createdAt',
    filter: 'CREATED_AT',
    colName: 'colCreatedAt'
  },
  {
    name: 'Updated at',
    key: 'updatedAt',
    filter: 'UPDATED_AT',
    colName: 'colUpdatedAt'
  },
  {
    name: 'Closed at',
    key: 'closedAt',
    filter: 'CLOSED_AT',
    colName: 'colClosedAt'
  }
];

export const jobWidthData = [
  'colClientName',
  'colTitle',
  'colInstruction',
  'colProperty',
  'colInvoicing',
  'colStatus',
  'colType',
  'colTotal',
  'colCreatedAt',
  'colUpdatedAt',
  'colClosedAt',
];
