import React, { useState, useEffect, useRef } from 'react';
import { useFilter } from '../FilterQuery/FilterQuery';
import { useLocation } from 'react-router';
import { useNotesFilters } from '../../AdvanceNotes/FilterQuery/NotesFilterQuery';
import { addToLocalStorageArray } from '../../../components/Common/Validations';

function TagSVG({ id, text, count, source, deleteTag, sameTagsClickHandler, excludeTagIdList, includeTagIdList }) {
	text = text;
	const padding = 10; // Padding around the text
	const minTagWidth = 30; // Minimum width for each tag
	const [textWidth, setTextWidth] = useState(0);
	const [countWidth, setCountWidth] = useState(0);
	const [svgWidth, setSvgWidth] = useState(118); // Initial total width of the SVG
	const [longTextWidth, setLongTextWidth] = useState(0); // Initial total width of the SVG
	const [refText, setRefText] = useState(0);

	const textRef = useRef();
	const location = useLocation();
	const isClientPage = location?.pathname?.includes('clients')

	const { addFilter } = location?.pathname?.includes('advance-notes') ? useNotesFilters() : useFilter();

	// Function to calculate text width (simple approximation)
	const calculateTextWidth = (text) => {
		const characterWidth = 8; // Average width of a character, adjust as needed
		return text?.length * characterWidth;
	};

	useEffect(() => {
		// Calculate widths for text and count
		const newTextWidth = calculateTextWidth(text) + 2 * padding;
		const newCountWidth = calculateTextWidth(String(count)) + 2 * padding;

		setTextWidth(Math.max(newTextWidth, minTagWidth));
		setCountWidth(Math.max(newCountWidth, minTagWidth));

		// Calculate total SVG width
		const totalSVGWidth = newTextWidth + newCountWidth + 34; // 34 is for non-rectangular part
		setSvgWidth(totalSVGWidth);

		const textWidth = textRef.current ? textRef.current.getBBox().width : 0;
		setRefText(textRef.current.getBBox().width);

		// Calculate total SVG width
		const totalWidth = textWidth + 2 * padding + 34;
		setLongTextWidth(Math.max(totalWidth, 80));
	}, [text, count, padding]);

	// click effect
	const [color1, setColor1] = useState('#D9D9D9');
	const [color2, setColor2] = useState('#B6B6B6');
	const [color3, setColor3] = useState('black');

	const colorChangeHandler = () => {
		let textArray = JSON.parse(localStorage.getItem(isClientPage ? 'tagCloudInclude' : 'notesTagCloudInclude')) || [];
		let tempIncluded = JSON.parse(localStorage.getItem(isClientPage ? 'tempFilter' : 'tempNotesFilter')).includeTags || []
		let tempExcluded = JSON.parse(localStorage.getItem(isClientPage ? 'tempFilter' : 'tempNotesFilter')).excludeTags || []
		let textArrayExclude = JSON.parse(localStorage.getItem(isClientPage ? 'tagCloudExclude' : "notesTagCloudExclude")) || [];

		if (textArray && textArray.includes(text) || tempIncluded && tempIncluded.includes(text)) {
			setColor1('#7DB00E');
			setColor3('white');
			setColor2('#9FD629');
		} else if (textArrayExclude && textArrayExclude.includes(text) || tempExcluded && tempExcluded.includes(text)) {
			setColor1('#ff0000');
			setColor3('white');
			setColor2('#F74F4F');
		} else {
			setColor1('#D9D9D9');
			setColor3('black');
			setColor2('#B6B6B6');
		}
	};

	// const excludeColorChangeHandler = () => {
	// 	if (textArray && textArray.includes(text)) {
	// 		setColor1('#ff0000');
	// 		setColor3('white');
	// 		setColor2('#9FD629');
	// 	} else {
	// 		setColor1('#D9D9D9');
	// 		setColor3('white');
	// 		setColor2('#F74F4F');
	// 	}
	// };

	useEffect(() => {
		colorChangeHandler();
	}, []);

	// useEffect(() => {
	//   if (excludeTagIdList && excludeTagIdList.includes(id)) {
	//     setColor1((prev) => {
	//       if (prev == "#D9D9D9") return "#ff0000";
	//       else return "#D9D9D9";
	//     });
	//     setColor3((prev) => {
	//       if (prev == "black") return "white";
	//       return "black";
	//     });
	//     setColor2((prev) => {
	//       if (prev == "#B6B6B6") return "#F74F4F";
	//       else return "#B6B6B6";
	//     });
	//   }
	// }, [excludeTagIdList]);

	const clickHandler = () => {
		addFilter('includeTags', text);
		addToLocalStorageArray(isClientPage ? 'tagCloudInclude' : 'notesTagCloudInclude', text);
		sameTagsClickHandler(text, 'includeTags');
		window.dispatchEvent(new Event("storage"));
	};

	useEffect(() => {
		window.addEventListener('storage', colorChangeHandler)
		return () => {
			window.addEventListener('storage', colorChangeHandler)
		}
	})

	const dblClickHandler = () => {
		addFilter('excludeTags', text);
		addToLocalStorageArray(isClientPage ? 'tagCloudExclude' : 'notesTagCloudExclude', text);
		sameTagsClickHandler(text, 'excludeTags');
		window.dispatchEvent(new Event("storage"));
	};

	// Debounce logic for single and double click
	const [clickTimeout, setClickTimeout] = useState(null);
	const doubleClickThreshold = 300; // milliseconds

	const handleClick = (event) => {
		if (clickTimeout) {
			clearTimeout(clickTimeout);
			setClickTimeout(null);
			if (color1 == '#7DB00E') clickHandler();
			else dblClickHandler();
		} else {
			const newTimeout = setTimeout(() => {
				if (color1 == '#ff0000') dblClickHandler();
				else clickHandler();
				setClickTimeout(null);
			}, doubleClickThreshold);
			setClickTimeout(newTimeout);
		}
	};

	return (
		<svg
			key={id}
			onClick={handleClick}
			className="pointer"
			// width={svgWidth - 30}
			height="23"
			viewBox={`0 0 ${svgWidth - 10} 30`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.0659 0L29.9999 14.934L14.934 30L3.53548 18.6015C1.58286 16.6489 1.58285 13.4831 3.53547 11.5304L15.0659 0Z"
				fill={color1}
			/>
			<circle cx="9" cy="15.0001" r="2" fill="white" />
			<rect x={refText + 34} y="0" width={countWidth} height="30" fill={color2} />
			<rect width={longTextWidth - 34} height="30" transform={`translate(15 0)`} fill={color1} />
			<text ref={textRef} x={(longTextWidth - 34) / 2 + 15} y="20" textAnchor="middle" fill={color3}>
				{text}
			</text>
			<text
				x={
					String(count).length == 1
						? longTextWidth - countWidth + 25
						: String(count).length == 2
							? longTextWidth - countWidth + 34
							: String(count).length == 3
								? longTextWidth - countWidth + 48
								: String(count).length == 4
									? longTextWidth - countWidth + 59
									: longTextWidth - countWidth + 70
				}
				y="20"
				textAnchor="middle"
				fill={color3}
			>
				{count}
			</text>
		</svg>
	);
}

export default TagSVG;
