import React, { useCallback, useEffect, useState } from 'react';
import TableRow from './TableRow';
import { useNotesFilter } from '../FilterQuery/NotesFilterQuery';
import { getFromServer } from '../../../components/Common/requests';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { showToast } from '@jobber/components/Toast';
import { Glimmer } from '@jobber/components/Glimmer';
import { Option, Select } from '@jobber/components/Select';
import Pagination from '../../../components/Common/Pagination';
import { useNotesContext } from '../contextAPI/NotesContext';
import { Button } from '@jobber/components/Button';
import { setNotesFilterQuery, setNotesSavedFilters, setReduxEntries } from '../../../store/notesFilterSlice';
import NoteSocket from '../../../socket/NoteSocket';
import { InputText } from '@jobber/components/InputText';
import { Spinner } from '@jobber/components/Spinner';
import { Combobox } from '@jobber/components/Combobox';
import useGetFromServer from '../../../utils/useGetFromServer';

function Table({ reload }) {
	const { settingsSaved } = useNotesContext();
	const dispatch = useDispatch();
	const { updateFilter, buildQuery, reloadFilter, setReloadFilter, filters, addFilterList, savedFilterId, handleSetSavedFilterId } =
		useNotesFilter();
	const reduxFilters = useSelector((store) => store.notesFilter);

	const [currentPage, setCurrentPage] = useState(parseInt(filters?.page?.[0]) || 1);
	const [data, setData] = useState([]);
	const [totalPage, setTotalPage] = useState(1);
	const [entries, setEntries] = useState(null);
	const [total, setTotal] = useState(0);
	const [selectedActions, setSelectedActions] = useState([]);
	const [limit, setLimit] = useState(10);
	// const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState('');

	useEffect(() => {
		const tempFilter = JSON.parse(localStorage.getItem('tempNotesFilter')) || {};
		if (tempFilter?.limit) setLimit(parseInt(tempFilter?.limit?.[0]));
		if (tempFilter?.page) setCurrentPage(parseInt(tempFilter?.page?.[0]));
	}, []);

	const queryString = buildQuery(); // Ensure you define this function based on your current filters
	const urlPath = `client-notes?${queryString}${savedFilterId ? `&filterId=${savedFilterId}` : ''}`;

	const { data: notesData, result, error, loading } = useGetFromServer(urlPath, [filters, settingsSaved]);
	useEffect(() => {
		setData(notesData)
	}, [notesData])

	const [pageLoad, setPageLoad] = useState(true);
	useEffect(() => {
		if (!loading) {
			if (result.status) {
				setEntries(result?.totalItems);
				dispatch(setReduxEntries(result?.totalItems));
				setTotalPage(result?.totalPages);
			} else if (error) {
				showToast({
					message: result.message || error,
					variation: 'error'
				});
			}
		}
	}, [result, loading]);

	// Handle initial load using a local state or other mechanism
	useEffect(() => {
		const tempFilter = JSON.parse(localStorage.getItem('tempNotesFilter')) || {};
		if (!tempFilter || Object.entries(tempFilter)?.length <= 0) {
			setPageLoad(false); // If no filter, start fetching
		}
	}, []);

	useEffect(() => {
		if (!pageLoad) {
			if (parseInt(filters?.page?.[0])) {
				setCurrentPage(parseInt(filters?.page?.[0]));
			}
		} else {
			setPageLoad(false);
		}
	}, [filters, settingsSaved]);

	const clearFilters = () => {
		dispatch(setNotesFilterQuery({}));
		localStorage.removeItem('notesTagCloudInclude');
		localStorage.removeItem("notesTagCloudExclude");
		setSelectedActions([]);
		addFilterList({});
		setLimit(10);
		reload();
	};
	const updateCorrectedNotesHandler = (soc) => {
		const updatedData = data?.map((row) => {
			if (row?.client?.id === soc.clientId) {
				const updatedNotes = row.client.note?.notes?.map((note) => {
					if (note?.id === soc.noteId) {
						return { ...note, correctedMessage: soc.correctedMessage };
					}
					return note;
				});
				return { ...row, client: { ...row.client, note: { ...row.client.note, notes: updatedNotes } } };
			}
			return row;
		});
		setData(updatedData);
	};
	const updateTranslatedNotesHandler = (soc) => {
		const updatedData = data?.map((row) => {
			if (row?.client?.id === soc.clientId) {
				const updatedNotes = row.client.note?.notes?.map((note) => {
					if (note?.id === soc.noteId) {
						return { ...note, translatedMessage: soc.correctedMessage };
					}
					return note;
				});
				return { ...row, client: { ...row.client, note: { ...row.client.note, notes: updatedNotes } } };
			}
			return row;
		});
		setData(updatedData);
	};
	const updateData = (lastNote, r, type = 'correctedNote') => {
		if (type == 'correctedNote') {
			const updatedData = data?.map((row) => {
				if (row?.client?.id === r?.client?.id) {
					const updatedNotes = [...row.client.note?.notes, lastNote];
					return { ...row, client: { ...row.client, note: { ...row.client.note, notes: updatedNotes } } };
				}
				return row;
			});
			setData(updatedData);
		} else {
			// lastnote refers as complete row after editing in row
			const updatedData = data?.map((row) => {
				if (row?.client?.id === r?.client?.id) {
					return { ...row, client: lastNote?.client };
				}
				return row;
			});
			setData(updatedData);
		}
	};

	// search debbouncing
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);

	const updateFilters = useCallback((filterName, value) => {
		updateFilter(filterName, value);
	}, []);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedSearchTerm(search);
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [search]);

	useEffect(() => {
		if (!pageLoad) {
			updateFilters('searchTerm', debouncedSearchTerm);
		} else {
			setPageLoad(false);
		}
	}, [debouncedSearchTerm]);

	// sorting
	const [sortConfig, setSortConfig] = useState({
		key: null,
		direction: 'ASCENDING'
	});
	const onSort = (key) => {
		updateFilter('sortKey', key);
		if (sortConfig?.key === key && sortConfig?.direction === 'ASCENDING') {
			setSortConfig({ key, direction: 'DESCENDING' });
			updateFilter('sortDirection', 'DESCENDING');
		} else {
			setSortConfig({ key, direction: 'ASCENDING' });
			updateFilter('sortDirection', 'ASCENDING');
		}
	};

	const getIconStyle = (key, direction) => {
		return sortConfig.key === key && sortConfig.direction === direction ? 'fs-5 text-dark' : 'fs-5 text-muted opacity-25';
	};

	useEffect(() => {
		if (filters.action == 'all') setSelectedActions([{ id: 'all', label: 'All' }]);
		if (filters.action == 'translated') setSelectedActions([{ id: 'translated', label: 'Translated' }]);
		if (filters.action == 'corrected') setSelectedActions([{ id: 'corrected', label: 'Corrected' }]);
		if (filters.action == 'updated') setSelectedActions([{ id: 'updated', label: 'Updated' }]);
	}, []);

	return (
		<div className="mt-3">
			<NoteSocket updateCorrectedNotes={updateCorrectedNotesHandler} updateTranslatedNotes={updateTranslatedNotesHandler} />
			<div className="row">
				<div className="col-lg-3 col-md-4 d-flex gap-2 align-items-center">
					<p className="pt-3">Show</p>
					<Select
						size="small"
						onChange={(e) => {
							updateFilter('limit', e);
							setLimit(e);
						}}
						value={limit}
					>
						{/* <Option value="all">All (Takes time to load)</Option> */}
						<Option value="10">10</Option>
						<Option value="20">20</Option>
						<Option value="50">50</Option>
					</Select>
					<p className="pt-3">entries</p>
				</div>
				<div className="col-lg-9 col-md-8 d-flex justify-content-end gap-3">
					<div>
						<Combobox
							onSelect={(e) => {
								setSelectedActions(e);
								updateFilter('action', e?.[0]?.id);
							}}
							selected={selectedActions}
							label="Actions"
						>
							<Combobox.Option id="all" label="All" />
							<Combobox.Option id="translated" label="Translated" />
							<Combobox.Option id="corrected" label="Corrected" />
							<Combobox.Option id="updated" label="Updated" />
						</Combobox>
					</div>
					<div>
						<Button label={'Clear Filters'} type="secondary" onClick={clearFilters} />
					</div>
					<div className="">
						<InputText
							placeholder="Search"
							name="firstName"
							size="small"
							value={search}
							onChange={(e) => {
								setSearch(e);
							}}
						/>
					</div>
				</div>
			</div>
			<div>
				<table className="table">
					<thead>
						<tr>
							<th style={{ width: '10px' }}></th>
							<th className="fw-bold">
								<div className="d-flex justify-content-between">
									<span>Client Name</span>
									<div
										className="icon-container"
										onClick={(e) => {
											e.stopPropagation();
											onSort('PRIMARY_NAME');
										}}
									>
										<IoMdArrowDropup className={`icon-up ${getIconStyle('PRIMARY_NAME', 'ASCENDING')}`} />
										<IoMdArrowDropdown className={`icon-down ${getIconStyle('PRIMARY_NAME', 'DESCENDING')}`} />
									</div>
								</div>
							</th>
							<th className="fw-bold">Address</th>
							<th className="fw-bold">Notes</th>
							<th className="fw-bold">Corrected & Translated AI Notes</th>
						</tr>
					</thead>
					<tbody>
						{loading
							? Array.from({ length: 2 }, (_, index) => (
								<tr>
									{Array.from({ length: 5 }, (_, index) => (
										<td>
											<Glimmer shape="rectangle" size="base" timing="speed" />
										</td>
									))}
								</tr>
							))
							: data &&
							data?.map((row, i) => {
								return <TableRow row={row?.client} updateData={(e, type) => updateData(e, row, type)} filterAction={selectedActions} />;
							})}
					</tbody>
				</table>
				{data?.length <= 0 && !loading ? <div className="text-center">No data found</div> : ''}
				<div className="d-md-flex justify-content-between">
					<div className="">
						<p> {entries} entries</p>
					</div>

					{totalPage == 1 ? (
						''
					) : (
						<div className="">
							<Pagination
								currentPage={currentPage}
								totalPages={totalPage}
								onPageChange={(page) => {
									setCurrentPage(page);
									updateFilter('page', page);
									window.scrollTo(0, 0);
								}}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Table;
