import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import { SocketContext } from '../SocketProvider';
import { showToast } from '@jobber/components/Toast';
import { Navigate, useNavigate } from 'react-router';

function NoteSocket({ updateCorrectedNotes, updateTranslatedNotes }) {
  const navigate = useNavigate();
  const { socket, socketHandler } = useContext(SocketContext);
  const [comingMsg, setComingMsg] = useState(null);
  const [comingTranslatedMsg, setComingTranslatedMsg] = useState(null);

  const userStore = useSelector((store) => store.user);
  const user = userStore?.user?.data;

  useEffect(() => {
    if (comingMsg) {
      updateCorrectedNotes(comingMsg);
    }
  }, [comingMsg]);

  useEffect(() => {
    if (comingTranslatedMsg) {
      updateTranslatedNotes(comingTranslatedMsg);
    }
  }, [comingTranslatedMsg]);

  useEffect(() => {
    if (!socket) {
      const newSocket = io(process.env.REACT_APP_API_URL);
      socketHandler(newSocket);
    }

    if (socket) {
      socket.on(`Genie_corrected_note_${user?._id}`, async (notify) => {
        console.log('correction socket', notify);
        setComingMsg(notify);
      
        // Send an acknowledgment back to the server
        socket.emit('noteCorrectionAck', { 
          socketUID: notify.socketUID 
        });
      });

      socket.on(`Genie_translated_note_${user?._id}`, (notify) => {
        setComingTranslatedMsg(notify);
        // Send an acknowledgment back to the server
        socket.emit('noteCorrectionAck', { 
          socketUID: notify.socketUID 
        });
      });

      socket.on('error', (errorMessage) => {
        console.error(errorMessage);
      });

      socket.on('connect', (successMessage) => {
        console.log('socket connected');
      });
    }
  }, [socket, , user?._id]);

  const joinRoom = async () => {
    if (socket && user?._id) {
      await socket.emit('joinGenieRoom', { userId: user?._id });
    }
  };

  useEffect(() => {
    joinRoom();
  }, [user?._id, socket]);
  return '';
}

export default NoteSocket;
