import { createSlice } from "@reduxjs/toolkit";

const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    showSidebar: true,
    start: false,
    stop: false,
  },
  reducers: {
    setShowSidebar(state, action) {
      if (action.payload == false) {
        document.body.classList.remove("body-pd");
        document.getElementById("header").classList.remove("body-pd");
      } else {
        document.body.classList.add("body-pd");
        document.getElementById("header").classList.add("body-pd");
      }
      state.showSidebar = action.payload;
    },
    setStartSyncR(state, action){
      state.start = action.payload;
    },
    setStopSyncR(state, action){
      state.stop = action.payload;
    }
  },
});

export const { setShowSidebar, setStartSyncR, setStopSyncR } = layoutSlice.actions;
export default layoutSlice.reducer;
