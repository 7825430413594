import React, { useEffect, useState } from 'react';
import { InputEmail } from '@jobber/components/InputEmail';
import { InputPassword } from '@jobber/components/InputPassword';
import { showToast } from '@jobber/components/Toast';
import { Button } from '@jobber/components/Button';
import { getFromServerByParameterToken, postToServerNoToken } from '../../components/Common/requests';
import { useNavigate } from 'react-router';
import { Heading } from '@jobber/components/Heading';
import { Link } from 'react-router-dom';
import { isValidEmail, validatePassword, validatePasswordChar } from '../../components/Common/Validations';
import { Spinner } from '@jobber/components/Spinner';
import { useDispatch } from 'react-redux';
import { setLoginUser } from '../../store/userSlice';
import logo from '../../assets/logoBlack.svg';
import Footer from './Components/Footer';

function Signin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginButton, setLoginButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobberLoading, setJobberLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    email: '',
    password: ''
  });
  const setValuesHandler = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault()
    setLoading(true);
    if (!formValues.email || !formValues.password) {
      showToast({ message: 'Please fill out all fields', variation: 'error' });
    } else if (!isValidEmail(formValues.email)) {
      showToast({ message: 'Email is not valid', variation: 'error' });
    } else if (!validatePassword(formValues.password)) {
      showToast({
        message: 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
        variation: 'error'
      });
    } else {
      const result = await postToServerNoToken(`user/login`, formValues);
      if (result.status) {
        const [tableSettings, quoteSettings, jobSettings, visitSettings, approvedQuoteSettings] = await Promise.all([
          getFromServerByParameterToken(`table-settings/${result.data.data._id}`, result.data?.access),
          getFromServerByParameterToken(`page-settings/${result.data.data._id}`, result.data?.access),
          getFromServerByParameterToken(`job-table-settings/${result.data.data._id}`, result.data?.access),
          getFromServerByParameterToken(`visit-table-Settings/${result.data.data._id}`, result.data?.access),
          getFromServerByParameterToken(`approved-quote/${result.data.data._id}`, result.data?.access)
        ]);
        if (tableSettings.status && quoteSettings.status && jobSettings.status && visitSettings.status) {
          localStorage.setItem('authUser', JSON.stringify(result.data));
          localStorage.setItem('userSettings', JSON.stringify(tableSettings.data));
          localStorage.setItem('userQuotesSettings', JSON.stringify(quoteSettings.data));
          localStorage.setItem('approvedQuotesSettings', JSON.stringify(approvedQuoteSettings.data));
          localStorage.setItem('jobSettings', JSON.stringify(jobSettings.data));
          localStorage.setItem('visitSettings', JSON.stringify(visitSettings.data));
          dispatch(setLoginUser(result.data));
          localStorage.removeItem('cTab');
          // dispatch(setUserSettings(tableSettings.data));
          showToast({
            message: 'User successfully login',
            variation: 'success'
          });
          if (result.data?.data?.isConnected) {
            document.body.classList.add('body-pd');
            // dispatch(setJobber(true));
            navigate('/clients');
          } else {
            setLoginButton(true);
          }
        } else {
          showToast({
            message: tableSettings.message || tableSettings.error + quoteSettings.message || quoteSettings.error,
            variation: 'error'
          });
        }
      } else {
        showToast({
          message: result.message || result.error,
          variation: 'error'
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const authUser = localStorage.getItem('authUser');
    if (authUser) {
      navigate('/clients');
    }
  }, []);

  const loginWithJobberHandler = () => {
    setJobberLoading(true);
    const auth = JSON.parse(localStorage.getItem('authUser'));
    window.location.href = process.env.REACT_APP_API_URL + `/auth/${auth.data._id}`;
  };

  return (
    <div className="row">
      <div className="text-center mb-4">
        {/* <img src={fullLogo} alt="" style={{ width: "60%" }} /> */}
        <Heading level={1}>JOBBER X THE ROYAL GATEWAY</Heading>
      </div>
      <div className="col-md-4"></div>
      <div className="col-md-4">
        <div className="d-flex justify-content-between mb-3">
          <Heading level={3}>Login</Heading>
          <Link to={'/signup'}>Signup</Link>
        </div>
        <div className="card">
          {!loginButton ? (
            <form className="card-body" onSubmit={submitHandler}>
              <InputEmail
                placeholder="Email"
                name="email"
                value={formValues.email}
                onChange={(value) => setValuesHandler('email', value)}
                validations={{
                  required: {
                    value: true,
                    message: 'Tell us your email'
                  }
                }}
              />
              <div className="mt-3"></div>

              <InputPassword
                placeholder="Password"
                name="password"
                hasVisibility={true}
                value={formValues.password}
                onChange={(value) => setValuesHandler('password', value)}
                validations={{
                  required: { value: true, message: 'Password is required' },
                  validate: validatePasswordChar,
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters'
                  }
                }}
              />
              <div className="mt-3"></div>
              {loading ? <Spinner size="base" /> : <Button label={'Login'} submit />}
            </form>
          ) : (
            <>
              <div className="text-center mt-3">
                <p className="fs-6">Please login with jobber to continue</p>
              </div>
              <div
                onClick={loginWithJobberHandler}
                className="mt-2 mb-3 pointer border rounded ms-5 me-5 pt-3 pb-3 d-flex gap-3 align-items-center justify-content-center"
              >
                {jobberLoading ? <Spinner size="base" /> : <img src={logo} alt="" />}
                <Heading level={2}>Connnect Jobber</Heading>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="col-md-4"></div>
      <Footer />
    </div>
  );
}

export default Signin;
