import React, { useEffect, useState } from 'react';
import {
  format,
  startOfMonth,
  endOfMonth,
  parseISO,
  isWithinInterval
} from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from '@jobber/components/Button';
import { Spinner } from '@jobber/components/Spinner';

const Charts = ({
  data,
  renderChart,
  title,
  groupDataByInterval,
  groupBy,
  pages,
  pageChangeHandler,
  getDate,
  isLoading = false,
  handleGroupBool
}) => {
  const [chartType, setChartType] = useState('BarChart');
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection'
    }
  ]);
  const [prevDateRange, setPrevDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const handleChartChange = (event) => {
    setChartType(event.target.value);
  };

  const displayDate = () => {
    if (!dateRange[0].startDate || !dateRange[0].endDate) return '';
    return `${format(dateRange[0].startDate, 'MM/dd/yyyy')} - ${format(dateRange[0].endDate, 'MM/dd/yyyy')}`;
  };

  const filteredData = data.filter((item) => {
    const date = parseISO(item.date);
    if (!dateRange[0].startDate || !dateRange[0].endDate) return true;
    return isWithinInterval(date, { start: dateRange[0].startDate, end: dateRange[0].endDate });
  });

  const getGroupedData = () => {
    const start = dateRange[0].startDate;
    const end = dateRange[0].endDate;
    const daysDiff = Math.round((end - start) / (1000 * 60 * 60 * 24));

    if (daysDiff >= 365) {
      return groupDataByInterval(filteredData, 'year');
    } else if (daysDiff >= 60) {
      return groupDataByInterval(filteredData, 'month');
    } else if (daysDiff >= 14) {
      return groupDataByInterval(filteredData, 'day');
    } else if (groupBy) {
      return groupDataByInterval(filteredData, groupBy);
    } else {
      return groupDataByInterval(filteredData, 'day');
    }
  };

  const groupedData = getGroupedData();

  const isWithinRange = (newRange, oldRange) => {
    const newStart = newRange[0].startDate;
    const newEnd = newRange[0].endDate;
    const oldStart = oldRange[0].startDate;
    const oldEnd = oldRange[0].endDate;

    return newStart >= oldStart && newEnd <= oldEnd;
  };

  const applyHandler = () => {
    if (!isWithinRange(dateRange, prevDateRange)) {
      setPrevDateRange(dateRange);
      getDate(dateRange);
    }
    setIsOpen(false);
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body p-4">
          <div className="d-flex flex-column align-items-center mb-2 gap-4">
            <h5 className='m-0'>{title}</h5>
            <div className='d-flex gap-2'>
              <div>
                <label>Date Range:</label>
                <div className="position-relative">
                  <div>
                    <input
                      readOnly
                      className="form-control"
                      value={displayDate()}
                      onClick={() => setIsOpen(!isOpen)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  {isOpen && (
                    <div className="datepicker" style={{ backgroundColor: 'white', top: '2.3rem', left: 0, right: 'auto' }}>
                      <div>
                        <div style={{ backgroundColor: 'white' }}>
                          <DateRangePicker
                            ranges={dateRange}
                            onChange={handleSelect}
                            direction="horizontal"
                            rangeColors={['#7DB00E']}
                            showMonthAndYearPickers={false}
                            staticRanges={[]}
                            inputRanges={[]}
                            className="no-sidebar"
                          />
                          <div className="p-2 text-end w-100">
                            <Button label="Apply" onClick={applyHandler} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label>Chart Type:</label>
                <select className="form-control" onChange={handleChartChange} value={chartType}>
                  <option value="PieChart">Pie Chart</option>
                  <option value="BarChart">Bar Chart</option>
                  <option value="LineChart">Line Chart</option>
                </select>
              </div>
              {pages && (
                <>
                  <div>
                    <label>Select Page:</label>
                    <select className="form-control" onChange={(e) => pageChangeHandler(e.target.value)}>
                      {pages.map((page) => (
                        <option key={page?._id} value={page?._id}>{page?.pageName}</option>
                      ))}
                    </select>
                  </div>
                  {/* <div className='d-flex align-items-end pb-2'>
                    <div>
                      <label className='form-check-label me-2' for="flexCheckDefault">Grouped?:</label>
                      <input class="form-check-input" type="checkbox" onChange={e => handleGroupBool(e.target.checked)} id="flexCheckDefault" />
                    </div>
                  </div> */}
                </>
              )}
            </div>
          </div>
          {isLoading ?
            (
              <div style={{
                height: '400px'
              }} className='d-flex align-items-center justify-content-center'>
                <Spinner size={"base"} />
              </div>
            ) : (
              <>
                {data.length ? (
                  <div className="col-md-12 d-flex flex-column align-items-center justify-content-center w-100">{renderChart(chartType, groupedData)}</div>
                ) : (
                  <div style={{
                    height: '400px'
                  }} className='d-flex align-items-center justify-content-center'>
                    <p>No data found</p>
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
}

export default Charts;
