import { Heading } from "@jobber/components/Heading";
import React, { useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { SiQuickbooks } from "react-icons/si";
import { getFromServer } from "../../../components/Common/requests";
import { useSelector } from "react-redux";
import { showToast } from "@jobber/components/Toast";

function QuickBooks() {
  const user = useSelector((store) => store.user);
  console.log(user);
  const [loading, setLoading] = useState(false);
  const loginWithQuickbookHandler = async () => {
    setLoading(true);
    const result = await getFromServer(
      `auth/quickbooks/${user?.user?.data?._id}`
    );
    console.log(result);
    showToast({
      message: "Please wait while we are redirecting",
    });
    window.location.href = result.data;
  };
  return (
    <div className="">
      {user?.user?.data?.isQBConnected ? (
        <div>
          <Alert variant={"success"}>Quickbooks is currently connected</Alert>
        </div>
      ) : (
        <>
          <div className="mt-4 mb-4">
            <h6>Please login with quickbooks to continue</h6>
          </div>
          <div
            onClick={loading ? null : loginWithQuickbookHandler}
            className="mt-2 mb-3 pointer"
          >
            <div className="d-flex gap-3 align-items-center">
              {loading ? (
                <Spinner size="base" />
              ) : (
                <SiQuickbooks className="fs-3" />
              )}
              <Heading level={2}>Connnect Quickbooks</Heading>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default QuickBooks;
