import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import { SocketContext } from "../SocketProvider";
import { showToast } from "@jobber/components/Toast";
import { Navigate, useNavigate } from "react-router";

function QuoteSocket({ socketCalled }) {
  const navigate = useNavigate();
  const { socket, socketHandler } = useContext(SocketContext);
  const [comingMsg, setComingMsg] = useState(null);

  const userStore = useSelector((store) => store.user);
  const user = userStore?.user?.data;

  useEffect(() => {
    if (comingMsg) {
      socketCalled()
    }
  }, [comingMsg]);

  useEffect(() => {
    if (!socket) {
      const newSocket = io(process.env.REACT_APP_API_URL);
      socketHandler(newSocket);
    }

    if (socket) {
      socket.on(`Genie_quote_${user?._id}`, (notify) => {
        setComingMsg(notify);
      });

      socket.on("error", (errorMessage) => {
        console.error(errorMessage);
      });

      socket.on("connect", (successMessage) => {
        console.log("socket connected");
      });
    }
  }, [socket]);

  const joinRoom = async () => {
    if (socket && user?._id) {
      await socket.emit("joinGenieRoom", { userId: user?._id });
    }
  };

  useEffect(() => {
    joinRoom();
  }, [user?._id, socket]);
  return "";
}

export default QuoteSocket;
