import { createSlice } from "@reduxjs/toolkit";

const visitFilterSlice = createSlice({
  name: "filter",
  initialState: {
    visitFilters: [],
    visitFilterQuery: {},
    status:'All',
    entries: 0,
    runing: true,
    isSavedFilterApplied: false,
  },
  reducers: {
    setVisitSavedFilters(state, action) {
      state.visitFilters = action.payload;
    },
    setIsSavedFilterAppliedFilters(state, action) {
      state.isSavedFilterApplied = !state.isSavedFilterApplied;
    },
    setStatusFilters(state, action) {
      state.status = action.payload;
    },
    setReduxEntries(state, action) {
      state.entries = action.payload;
      state.runing = false;
    },
    setRuning(state, action) {
      state.runing = action.payload;
    },
    setVisitFilterQuery(state, action) {
      state.visitFilterQuery = action.payload;
    },
  },
});

export const { setVisitSavedFilters, setReduxEntries, setRuning, setVisitFilterQuery,setStatusFilters, setIsSavedFilterAppliedFilters } =
  visitFilterSlice.actions;
export default visitFilterSlice.reducer;
