import React, { useEffect, useState } from 'react';
import { Button } from '@jobber/components/Button';
import { Heading } from '@jobber/components/Heading';
import Table from './Table/Table';
import { Card } from '@jobber/components/Card';
import { Content } from '@jobber/components/Content';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Glimmer } from '@jobber/components/Glimmer';
import { useDispatch, useSelector } from 'react-redux';
import { getFromServer } from '../../components/Common/requests';
import { addUniqueIds } from '../../components/Common/Validations';
import { setAllTags } from '../../store/tagsSlice';
import { showToast } from '@jobber/components/Toast';
import ResetSidebar from '../../components/Common/ResetSidebar';
import { useLocation } from 'react-router';
import { QuoteFilterProvider } from './FilterQuery/QuoteFilterQuery';
import ShowAppliedFilters from '../../components/Common/ShowAppliedFilters';

function AdvanceQuotes() {
  const location = useLocation();
  const dispatch = useDispatch();
  const bulkTags = useSelector((store) => store.bulkTag);
  const tagSlice = useSelector((store) => store.tag);
  const [filterReload, setFilterReload] = useState(true);
  const [tagCloudCategory, setTagCloudCategory] = useState(false);
  const [tagCategoryReload, setTagCategoryReload] = useState(true);
  const [tagReload, setTagReload] = useState(true);
  const [bulkTagMangementModal, setBulkTagMangementModal] = useState(false);
  const [bulkTagReload, setBulkTagReload] = useState(true);

  const [editSavedFilterId, setSavedFilterId] = useState(undefined);
  const reload = (id) => {
    if (id) {
      setSavedFilterId(id);
    } else {
      setSavedFilterId(undefined);
    }
    setTagCategoryReload(false);
    setTagReload(false);
    setFilterReload(false);
    setTimeout(() => {
      setTagCategoryReload(true);
      setFilterReload(true);
      setTagReload(true);
    }, 1);
  };

  useEffect(() => {
    const init = async () => {
      const result = await getFromServer('Client-Tags');
      console.log(result);
      if (result.status) {
        const addUniqueId = addUniqueIds(result.data);
        dispatch(setAllTags(addUniqueId));
        console.log(addUniqueId);
      } else {
        showToast({
          message: result.message || result.error,
          variation: 'error'
        });
      }
    };
    if (tagSlice?.allTags?.length <= 0) {
    }
    init();
  }, []);

  return (
    <QuoteFilterProvider>
      <ResetSidebar />
      <DndProvider backend={HTML5Backend}>
        <div className="row">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <Heading level={1}>{location?.pathname.includes('recycle') ? 'Recycle' : 'Advance'} Quotes</Heading>
            </div>
            <div>
              
            </div>
            <div className="mb-5">
              <ShowAppliedFilters page={'quotes'} />
            </div>
            <Table tagCloud={tagCloudCategory} reload={() => {}} />
          </div>
        </div>
      </DndProvider>
    </QuoteFilterProvider>
  );
}

export default AdvanceQuotes;
