import React, { useEffect, useState } from "react";

function TableDescription({ children, url, isUrl = true, isVisible }) {
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    if (clickCount === 1) {
      const timer = setTimeout(() => {
        window.location.href = url;
      }, 700);

      return () => clearTimeout(timer);
    }
  }, [clickCount, url]);

  const handleClick = (e) => {
    e.preventDefault();

    const newClickCount = clickCount + 1;
    setClickCount(newClickCount);

    if (newClickCount === 1) {
      setTimeout(() => {
        setClickCount(0);
      }, 700);
    } else if (newClickCount === 2) {
      setClickCount(0);
    }
  };
  return (
    <td className={`${isVisible ? "" : "d-none"}`}>
      {isUrl == true ? (
        <a href={url} className={`style__none`} onClick={handleClick}>
          {children}
        </a>
      ) : (
        children
      )}
    </td>
  );
}

export default TableDescription;
