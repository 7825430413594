import { Button } from '@jobber/components/Button';
import React, { useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Icon } from '@jobber/components/Icon';
import { Chip } from '@jobber/components/Chip';
import { IoMdClose } from 'react-icons/io';
import { InlineLabel } from '@jobber/components/InlineLabel';
import { useJobContext } from '../contextAPI/JobContext';
import { Checkbox } from '@jobber/components/Checkbox';

import { RadioGroup, RadioOption } from '@jobber/components/RadioGroup';
import { FormField } from '@jobber/components/FormField';

function Invoice({ oneOffJob }) {
  const { invoiceReminder, setInvoiceReminder, invoiceType, setInvoiceType, invoiceTime, handleInvoiceTime } = useJobContext();

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">Invoicing</h5>
          </div>
          <div className="mt-2">
            {oneOffJob ? (
              <Checkbox label={'Remind me to invoice when I close the job'} checked={invoiceReminder} onChange={setInvoiceReminder} />
            ) : (
              <div>
                <Row>
                  <Col lg={6}>
                    <div className="fw-bold mb-1">How do you want to invoice?</div>
                    <RadioGroup onChange={(value) => setInvoiceType(value)} value={invoiceType} inline>
                      <RadioOption value="VISIT_BASED" label="Per visit" />
                      <RadioOption value="FIXED_PRICE" label="Fixed price" />
                    </RadioGroup>
                  </Col>
                  <Col lg={6}>
                    <div className="fw-bold mb-1">When do you want to invoice?</div>
                    <FormField type={'select'} value={invoiceTime} onChange={handleInvoiceTime}>
                      <option value={'PERIODIC__RRULE:FREQ=MONTHLY;BYMONTHDAY=-1'}>Monthly on the last day of the month</option>
                      {invoiceType == 'VISIT_BASED' && <option value={'PER_VISIT'}>After each visit is completed</option>}
                      <option value={'NEVER'}>As needed—no reminders</option>
                      <option value={'ON_COMPLETION'}>Once when job is closed</option>
                      {/* <option disabled>or</option>
                      <option value={'custom'}>Custom schedule...</option> */}
                    </FormField>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default Invoice;
