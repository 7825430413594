import React, { useState, useEffect, useRef } from "react";

function TableTagSVG({ text }) {
  const padding = 10; // Padding around the text
  const additionalWidth = 34; // Width of non-rectangular part of the SVG
  const [svgWidth, setSvgWidth] = useState(0);
  const textRef = useRef();

  useEffect(() => {
    // Calculate text width based on rendered size
    const textWidth = textRef.current ? textRef.current.getBBox().width : 0;

    // Calculate total SVG width
    const totalWidth = textWidth + 2 * padding + additionalWidth;
    setSvgWidth(Math.max(totalWidth, 80)); // Ensure a minimum width if needed
  }, [text, padding]);

  return (
    <>
      <svg
        // width={svgWidth - 300}
        height="22"
        viewBox={`0 0 ${svgWidth - 20 } 30`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0659 0L29.9999 14.934L14.934 30L3.53548 18.6015C1.58286 16.6489 1.58285 13.4831 3.53547 11.5304L15.0659 0Z"
          fill="#D9D9D9"
        />
        <circle cx="9" cy="15.0001" r="2" fill="white" />
        <rect
          width={svgWidth - additionalWidth}
          height="30"
          transform="translate(15 6.10352e-05)"
          fill="#D9D9D9"
        />
        <text
          ref={textRef}
          x={(svgWidth - additionalWidth) / 2 + 15}
          y="15"
          textAnchor="middle"
          dominantBaseline="middle"
          fill="black"
        >
          {text}
        </text>
      </svg>
    </>
  );
}

export default TableTagSVG;
