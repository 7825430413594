import { createSlice } from "@reduxjs/toolkit";

const quotePrepSlice = createSlice({
  name: 'quoteprep',
  initialState: {
    quoteIds: [],
  },
  reducers: {
    setQuoteIds(state, action) {
      state.quoteIds = action.payload;
    },
  },
})

export const { setQuoteIds } = quotePrepSlice.actions;
export default quotePrepSlice.reducer;
