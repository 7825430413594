import { useContext, useEffect, useState } from 'react';
import { Modal } from '@jobber/components/Modal';
import { Content } from '@jobber/components/Content';
import { Tooltip } from '@jobber/components/Tooltip';
import { IoArrowUndoCircleSharp } from 'react-icons/io5';
import { MdDeleteForever } from 'react-icons/md';
import { deleteFromServer, getFromServer } from '../Common/requests';
import { showToast } from '@jobber/components/Toast';
import { formatYearDateTimeDay } from '../Common/Validations';
import { Spinner } from '@jobber/components/Spinner';
import { Glimmer } from '@jobber/components/Glimmer';
import { SocketContext } from '../../SocketProvider';
import downFill from '../../assets/icons/downFill.svg';
import sideFill from '../../assets/icons/sideFill.svg';
import Pagination from '../Common/Pagination';

const TableRow = ({ row, undoHandler, deleteHandler }) => {
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);

	function toggleShow(pageName) {
		if (pageName !== 'Job Automation') return;
		setShow(!show);
	}

	return (
		<>
			<tr>
				<td>{row.action}</td>
				<td>{row.pageName}</td>
				<td onClick={() => toggleShow(row.pageName)} style={{ cursor: 'pointer' }}>
					{row.pageName === 'Job Automation' && <>
						{show ? <img src={downFill} className="me-2" /> : <img src={sideFill} className="me-2" />}
					</>
					}
					{row.description}
				</td>
				<td>{formatYearDateTimeDay(row.createdAt)}</td>
				<td>
					{loading ? (
						<Spinner />
					) : (
						<div className="">
							<Tooltip message={'Undo operation'}>
								<IoArrowUndoCircleSharp
									className="fs-4 text-green"
									onClick={async () => {
										setLoading(true);
										await undoHandler(row._id);
										setLoading(false);
									}}
								/>
							</Tooltip>
							<span className="ms-2"></span>
							<Tooltip message={'Delete log'}>
								<MdDeleteForever
									className="fs-4 text-danger"
									onClick={async () => {
										setLoading(true);
										await deleteHandler(row._id);
										setLoading(false);
									}}
								/>
							</Tooltip>
						</div>
					)}
				</td>
			</tr>
			{show && (
				<tr>
					<td></td>
					<td colSpan={2}>
						<div style={{ maxHeight: '250px', overflow: 'auto' }}>
							{row.jobDetails.map(({ title, clientName }, index) => {
								return <p key={index + title}>{clientName} - {title}</p>
							})}
						</div>
					</td>
					<td></td>
					<td></td>
				</tr>
			)}
		</>
	);
};

function LogModal({ open, onCancel, onConfirm }) {
	const { undoReloadHandler } = useContext(SocketContext);
	const [pageLoad, setPageLoad] = useState(true);
	const [logs, setLogs] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [entries, setEntries] = useState(null);
	const init = async () => {
		setPageLoad(true);
		const result = await getFromServer(`activity-logs?page=${currentPage}`);
		if (result.status) {
			setLogs(result.data);
			setTotalPage(result.totalPages)
			setEntries(result.totalLogs)
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setPageLoad(false);
	};
	useEffect(() => {
		init();
	}, [currentPage]);

	const deleteHandler = async (id) => {
		const result = await deleteFromServer(`activity-logs/${id}`);
		if (result.status) {
			setLogs((previous) => previous.filter((p) => p._id != id));
			showToast({
				message: 'Log deleted successfully'
			});
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		onCancel();
	};
	const undoHandler = async (id) => {
		const result = await getFromServer(`undo/${id}`);
		if (result.status) {
			setLogs((previous) => previous.filter((p) => p._id != id));
			showToast({
				message: 'Operation restored successfully'
			});
			undoReloadHandler();
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		onCancel();
	};

	return (
		<>
			<Modal
				title={'Activity Logs'}
				open={open}
				size={'large'}
				// primaryAction={{ label: 'Save', onClick: handleBody }}
				secondaryAction={{
					label: 'Close',
					onClick: onCancel
				}}
				onRequestClose={onCancel}
			>
				<Content>
					<table className="table table-hover">
						<thead>
							<tr>
								<th>Action</th>
								<th>Page</th>
								<th>Description</th>
								<th>Date</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{pageLoad
								? Array.from({ length: 2 }, (_, index) => (
									<tr>
										{Array.from({ length: 5 }, (_, index) => (
											<td>
												<Glimmer shape="rectangle" size="base" timing="speed" />
											</td>
										))}
									</tr>
								))
								: logs?.map((l) => {
									return <TableRow row={l} deleteHandler={deleteHandler} undoHandler={undoHandler} />;
								})}
							{logs?.length <= 0 && !pageLoad ? (
								<tr>
									<td colSpan={5} className="text-center">
										No logs found
									</td>
								</tr>
							) : (
								''
							)}
						</tbody>
					</table>
					{totalPage == 1 ? (
						''
					) : (
						<div className="">
							<Pagination
								currentPage={currentPage}
								totalPages={totalPage}
								onPageChange={(page) => {
									setCurrentPage(page);
									window.scrollTo(0, 0);
								}}
							/>
						</div>
					)}
				</Content>
			</Modal>
		</>
	);
}

export default LogModal;
