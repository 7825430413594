import React from 'react';
import Header from './Header';

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="container mt-5 mb-5" style={{marginBottom:'10rem'}}>
        <h1>The Royal Gateway Privacy Policy</h1>
        <p>Effective Date: March 18, 2024</p>

        <h2>Introduction</h2>
        <p>
          This Privacy Policy describes how JOBBER Genie Herbe collects, uses, and discloses your information when
          you use our mobile application ("app"). It also explains the choices you have about your information and how you can contact us
          about this policy.
        </p>

        <h2>Information We Collect</h2>
        <p>
          We collect several types of information for various purposes to improve your experience with JOBBER Genie Herbe and to provide the
          services offered through our app. The information we collect can be categorized as follows:
        </p>

        <h3>Personal Information</h3>
        <ul>
          <li>
            If you choose to connect JOBBER Genie Herbe with your JOBBER account, we may collect your JOBBER username and
            password (credentials are stored securely using industry-standard practices). It's important to note that we **never** store
            your JOBBER password in plain text.
          </li>
        </ul>

        <h3>Usage Data</h3>
        <ul>
          <li>
            When you use the app, we may collect information about your interactions with the app, such as the features you use, the pages
            you visit, and the time you spend using the app. This information is used to improve the app and provide a better user
            experience.
          </li>
        </ul>

        <h2>Use of Information</h2>
        <p>We use the information we collect for various purposes, including:</p>
        <ul>
          <li>To connect you with JOBBER for job quotes (if you choose to do so).</li>
          <li>To improve the functionality and usability of the app.</li>
          <li>To personalize your experience with the app.</li>
          <li>To understand how users interact with the app.</li>
          <li>To comply with legal and regulatory requirements.</li>
        </ul>

        <h2>Sharing of Information</h2>
        <p>
          We may share your information with third-party service providers who assist us in operating the app, such as analytics providers
          or cloud storage providers. These third parties are obligated to use your information only to provide the services we have
          contracted them for and in accordance with this Privacy Policy.
        </p>

        <p>
          We may also disclose your information if we believe it's necessary to comply with a law, regulation, or legal process; to protect
          the safety of a person or property; or to prevent fraud or other harmful activities.
        </p>

        <h2>Your Choices</h2>
        <p>You have choices regarding your information. You can:</p>
        <ul>
          <li>Choose not to connect JOBBER Genie Herbe with your JOBBER account (if desired).</li>
        </ul>

        <h2>Data Retention</h2>
        <p>
          We will retain your information for as long as necessary to fulfill the purposes described in this Privacy Policy, unless a longer
          retention period is required or permitted by law.
        </p>

        <h2>Security</h2>
        <p>
          We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no
          internet transmission or electronic storage method is 100% secure. While we strive to protect your information, we cannot
          guarantee its absolute security.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this
          page.
        </p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <ul>
          <li>Email: <a href="mailto:legenieenherbe@gmail.com">legenieenherbe@gmail.com</a></li>
        </ul>
      </div>
    </>
  );
}

export default PrivacyPolicy;
