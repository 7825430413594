import React from 'react';
import { Heading } from '@jobber/components/Heading';
import Table from './Table/Table';
import ResetSidebar from '../../components/Common/ResetSidebar';
import { VisitFilterProvider } from './FilterQuery/VisitFilterQuery';
import { VisitProvider } from './contextAPI/VisitContext';

function AdvanceVisits() {
	return (
		<VisitProvider>
			<VisitFilterProvider>
				<ResetSidebar />
				<div className="row">
					<div>
						<div className="d-flex justify-content-between align-items-center">
							<Heading level={1}>Advance Visits</Heading>
						</div>
						<Table />
					</div>
				</div>
			</VisitFilterProvider>
		</VisitProvider>
	);
}

export default AdvanceVisits;
