import { Checkbox } from '@jobber/components/Checkbox';
import { Heading } from '@jobber/components/Heading';
import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { format, formatISO } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { FormField } from '@jobber/components/FormField';
import { Spinner } from 'react-bootstrap';
import { Button } from '@jobber/components/Button';
import { getFromServer, postToServer } from '../../../components/Common/requests';
import { useDispatch, useSelector } from 'react-redux';
import { setNotesSettingsR } from '../../../store/notesSlice';
import { showToast } from '@jobber/components/Toast';
import { useNotesContext } from '../contextAPI/NotesContext';

function NotesSettings() {
  const { triggerSettingsSaved } = useNotesContext();
  const dispatch = useDispatch();
  const setting = useSelector((s) => s.noteSetting);
  const [attachement, setAttachment] = useState({ requests: true, quotes: false, jobs: false, invoices: false, all: false });
  const [attachementDisable, setAttachmentDisable] = useState({ requests: false, quotes: false, jobs: false, invoices: false, all: false });
  const [notesCorrection, setNotesCorrection] = useState('');
  const [includeAI, setIncludeAI] = useState(true);
  const [includeAttachement, setIncludeAttachement] = useState(false);
  const [language, setLanguage] = useState('Spanish');
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const handleSelect = (ranges) => {
    const selection = ranges.selection;
    setDate([
      {
        ...selection,
        startDate: new Date(selection.startDate)?.toISOString(),
        endDate: new Date(selection.endDate)?.toISOString()
      }
    ]);
    console.log(new Date(selection.endDate)?.toISOString())
  };
  useEffect(() => {
    setAttachment(
      { ...setting.data.noteAttachments, all: setting.data.all } || {
        requests: true,
        quotes: false,
        jobs: false,
        invoices: false,
        all: false
      }
    );
    if (setting?.data?.all) {
      setAttachmentDisable({ requests: true, quotes: true, jobs: true, invoices: true, all: false });
    }
    setNotesCorrection(setting.data.defaultCorrectionPrompt || '');
    setIncludeAI(setting?.data?.showAINotes);
    setIncludeAttachement(setting?.data?.showNoteAttachements);
    setLanguage(setting.data.defaultTranslationPrompt || 'Spanish');
    setDate([
      {
        startDate: setting?.data?.startDate ? new Date(setting.data.startDate)?.toISOString() : new Date(),
        endDate: setting?.data?.endDate ? new Date(setting.data.endDate)?.toISOString() : new Date(),
        key: 'selection'
      }
    ]);
  }, [setting?.data]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const init = async () => {
      const userId = JSON.parse(localStorage.getItem('authUser'))?.data?._id
      const result = await getFromServer(`notes-Settings/${userId}`);
      console.log(result);
      if (result.status) {
        dispatch(setNotesSettingsR(result.data));
      }
    };
    if (Object.entries(setting?.data)?.length <= 0) {
      init();
    }
  }, []);

  const saveSettingsHandler = async () => {
    setLoading(true);
    const body = {
      defaultCorrectionPrompt: notesCorrection,
      showAINotes: includeAI,
      showNoteAttachements: includeAttachement,
      defaultTranslationPrompt: language,
      all: attachement.all,
      noteAttachments: attachement,
      startDate: date?.[0]?.startDate,
      endDate: date?.[0]?.endDate
    };
    console.log(body);
    const result = await postToServer(`notes-Settings`, body);
    console.log(result);
    if (result.status) {
      dispatch(setNotesSettingsR(result.data));
      triggerSettingsSaved();
      showToast({
        message: 'Notes setting updated successfully'
      });
    } else {
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
    setLoading(false);
  };

  const handleCheckboxChange = (key, value) => {
    if (key == 'all' && value) {
      setAttachmentDisable({ requests: true, quotes: true, jobs: true, invoices: true, all: false });
    } else if (key == 'all' && !value) {
      setAttachmentDisable({ requests: false, quotes: false, jobs: false, invoices: false, all: false });
    }
    setAttachment({ ...attachement, [key]: value });
  };

  return (
    <div>
      <div>
        <div className="mb-2">
          <label className="fw-bold">Notes Atttachements</label>
        </div>
        <Checkbox
          label={'Requests'}
          disabled={attachementDisable.requests}
          checked={attachement.requests}
          onChange={(e) => handleCheckboxChange('requests', e)}
        />
        <Checkbox
          label={'Quotes'}
          disabled={attachementDisable.quotes}
          checked={attachement.quotes}
          onChange={(e) => handleCheckboxChange('quotes', e)}
        />
        <Checkbox
          label={'Jobs'}
          disabled={attachementDisable.jobs}
          checked={attachement.jobs}
          onChange={(e) => handleCheckboxChange('jobs', e)}
        />
        <Checkbox
          label={'Invoices'}
          disabled={attachementDisable.invoices}
          checked={attachement.invoices}
          onChange={(e) => handleCheckboxChange('invoices', e)}
        />
        <Checkbox
          label={'All'}
          disabled={attachementDisable.all}
          checked={attachement.all}
          onChange={(e) => handleCheckboxChange('all', e)}
        />
      </div>
      <div className="mt-3">
        <div className="mb-2">
          <label className="fw-bold">Notes Correction Prompt</label>
        </div>
        <FormField onChange={setNotesCorrection} value={notesCorrection} placeholder={'Notes Correction Prompt'} type={'textarea'} />
      </div>
      <div className="mt-3">
        <Checkbox label={'Include AI notes?'} checked={includeAI} onChange={setIncludeAI} />
      </div>
      <div className="mt-3">
        <Checkbox label={'Show notes with attachements?'} checked={includeAttachement} onChange={setIncludeAttachement} />
      </div>
      <div className="mt-3">
        <div className="mb-2">
          <label className="fw-bold">Default Translation Language</label>
        </div>
        <select className="form-control" value={language} onChange={(e) => setLanguage(e.target.value)}>
          <option value="Spanish" selected>
            Spanish
          </option>
          <option value="French">French</option>
          <option value="English">English</option>
        </select>
      </div>
      <div className="mt-3">
        <div className="mb-2">
          <label className="fw-bold">Date Range</label>
        </div>
        <DateRange
          rangeColors={['#7DB00E']}
          onChange={handleSelect}
          moveRangeOnFirstSelection={false}
          ranges={date.map((range) => ({
            ...range,
            startDate: new Date(range.startDate),
            endDate: new Date(range.endDate)
          }))}
        />
      </div>
      <div>{loading ? <Spinner size="base" /> : <Button label="Save Settings" onClick={saveSettingsHandler} />}</div>
    </div>
  );
}

export default NotesSettings;
