import React, { createContext, useContext, useState } from 'react';

const NotesContext = createContext();

export const useNotesContext = () => useContext(NotesContext);

export const AdvanceNotesProvider = ({ children }) => {
  const [syncClient, setSyncClient] = useState(false);
  const [settingsBar, setSettingsBar] = useState(false);
  const [settingsSaved, setSettingsSaved] = useState(false);
  const [isAIDiscard, setAIDiscard] = useState(false);
  const [currentNote, setCurrentNote] = useState(false);
  const [arrowButton, setArrowButton] = useState(false);
  const [correctedNote, setCorrectedNote] = useState('');
  const [isNoteEdited, setIsNoteEdited] = useState(false);

  const triggerSyncClient = () => {
    setSyncClient(true);
    setTimeout(() => setSyncClient(false), 100);
  };

  const triggerArrowButton = () => {
    setArrowButton(true);
    setTimeout(() => setArrowButton(false), 100);
  };

  const triggerSettingsBar = () => {
    setSettingsBar(!settingsBar);
  };

  const triggerSettingsSaved = () => {
    setSettingsSaved(!settingsSaved);
  };
  const triggerAiDiscarded = (value) => {
    setAIDiscard(value);
  };
  const triggerCurrentNote = (value) => {
    setCurrentNote(value);
  };

  return (
    <NotesContext.Provider
      value={{
        settingsBar,
        syncClient,
        settingsSaved,
        isAIDiscard,
        currentNote,
        arrowButton,
        correctedNote,
        setCorrectedNote,
        triggerArrowButton,
        triggerCurrentNote,
        triggerAiDiscarded,
        triggerSettingsSaved,
        triggerSyncClient,
        triggerSettingsBar,
        isNoteEdited,
        setIsNoteEdited
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};
