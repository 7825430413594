import React, { useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { SocketContext } from '../../../../SocketProvider'
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '@jobber/components/Toast';
import { setLoginUser } from '../../../../store/userSlice';

export default function PrepProgress({ setLoading }) {
  const dispatch = useDispatch();
  const [progressValue, setProgressValue] = useState(1);
  const [currentStatus, setCurrentStatus] = useState('');
  const { socket, socketHandler } = useContext(SocketContext)
  const userStore = useSelector((store) => store.user);
  const user = userStore?.user?.data;

  useEffect(() => {
    if (!socket) {
      const newSocket = io(process.env.REACT_APP_API_URL);
      socketHandler(newSocket);
    }

    if (socket) {
      socket.on(`JobberQuoteProcessStart_${user?._id}`, (msg) => {
        setLoading(true);
        setProgressValue(msg.progress);
        setCurrentStatus(msg.message);
        showToast({
          message: msg.message,
          variation: 'info'
        });
        dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: true, progress: msg.progress } }));
      });

      socket.on(`JobberQuoteProcessProgress_${user?._id}`, (msg) => {
        setLoading(true);
        setCurrentStatus(msg.message);
        setProgressValue(msg.progress);
        dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: true, progress: msg.progress } }));
      });

      socket.on(`JobberQuoteProcessComplete_${user?._id}`, (msg) => {
        setLoading(false);
        setProgressValue(msg.progress);
        setCurrentStatus(msg.message);
        showToast({
          message: msg.message,
          variation: 'info'
        });
        dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: false, progress: msg.progress } }));
      });

      socket.on(`JobberQuoteProcessError_${user?._id}`, (msg) => {
        setLoading(false);
        setCurrentStatus(msg.message);
        setProgressValue(msg.progress);
        showToast({
          message: msg.message,
          variation: 'error'
        });
        dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: false, progress: msg.progress } }));
      });
    }

  }, [socket])

  return (
    <div>
      <p className="text-center">{currentStatus}</p>
      <ProgressBar animated variant="success" label={`${progressValue}%`} now={progressValue} />
      <p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }} className="mt-1">
        Do not perform any operation, while we are syncing
      </p>
    </div>
  )
}
