import React from "react";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { Heading } from "@jobber/components/Heading";
import { Glimmer } from "@jobber/components/Glimmer";
import { formatNumber, formatNumberWithSpaces } from "../../../components/Common/Validations";
import JobberLogo from '../../../assets/logoBlack.svg'
import { SiQuickbooks } from "react-icons/si";

function TotalLabels({ visibleColumns, totalAmount, loading, isQB = false }) {
	const qbRef = visibleColumns.QBtotalCost || visibleColumns.QBamountPaid || visibleColumns.QBamountOwed
	const jobberRef = visibleColumns.totalCost || visibleColumns.amountPaid || visibleColumns.amountOwed;
	const display = isQB ? qbRef : jobberRef;

	const cost = isQB ? visibleColumns.QBtotalCost : visibleColumns.totalCost;
	const paid = isQB ? visibleColumns.QBamountPaid : visibleColumns.amountPaid;
	const owed = isQB ? visibleColumns.QBamountOwed : visibleColumns.amountOwed;

	return (
		<div className={`${display ? 'd-flex' : 'd-none'} border rounded position-relative pt-4 p-3 gap-3 align-items-end justify-content-end mb-3`}>
			<div className='mb-1 position-absolute' style={{ top: 0, right: '50%', translate: '50% -50%' }} >
				{isQB ? (
					<SiQuickbooks className='fs-3' />
				) : (
					<img src={JobberLogo} alt="" />
				)}
			</div>
			<div className={`${cost ? 'd-block' : 'd-none'} text-center`}>
				<Heading level={5}>Total Cost</Heading>
				<div className="mt-2">
					{loading ? (
						<Glimmer shape="rectangle" size="large" timing="speed" />
					) : (
						<InlineLabel size="large">
							{isQB ? (
								<>{totalAmount?.amountCostQB ? formatNumberWithSpaces(formatNumber(totalAmount?.amountCostQB)) : 0}</>
							) : (
								<>{totalAmount?.amountCost ? formatNumberWithSpaces(formatNumber(totalAmount?.amountCost)) : 0}</>
							)}
						</InlineLabel>
					)}
				</div>
			</div>
			<div className={`${paid ? 'd-block' : 'd-none'} text-center`}>
				<Heading level={5}>Total Paid</Heading>
				<div className="mt-2">
					{loading ? (
						<Glimmer shape="rectangle" size="large" timing="speed" />
					) : (
						<InlineLabel size="large" color="green">
							{isQB ? (
								<>{totalAmount?.amountPaidQB ? formatNumberWithSpaces(formatNumber(totalAmount?.amountPaidQB)) : 0}</>
							) : (
								<>{totalAmount?.amountPaid ? formatNumberWithSpaces(formatNumber(totalAmount?.amountPaid)) : 0}</>
							)}
						</InlineLabel>
					)}
				</div>
			</div>
			<div className={`${owed ? 'd-block' : 'd-none'} text-center`}>
				<Heading level={5}>Total Owed</Heading>
				<div className="mt-2">
					{loading ? (
						<Glimmer shape="rectangle" size="large" timing="speed" />
					) : (
						<InlineLabel size="large" color="red">
							{isQB ? (
								<>{totalAmount?.totalOwedQB ? formatNumberWithSpaces(formatNumber(totalAmount?.totalOwedQB)) : 0}</>
							) : (
								<>{totalAmount?.totalOwed ? formatNumberWithSpaces(formatNumber(totalAmount?.totalOwed)) : 0}</>
							)}
						</InlineLabel>
					)}
				</div>
			</div>
		</div>
	);
}

export default TotalLabels;
