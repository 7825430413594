import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    filters: [],
    filterQuery: {},
    status:'All',
    entries: 0,
    runing: true,
    isSavedFilterApplied: false,
  },
  reducers: {
    setSavedFilters(state, action) {
      state.filters = action.payload;
    },
    setIsSavedFilterAppliedFilters(state, action) {
      console.log(state.isSavedFilterApplied, !state.isSavedFilterApplied)
      state.isSavedFilterApplied = !state.isSavedFilterApplied;
    },
    setStatusFilters(state, action) {
      state.status = action.payload;
    },
    setReduxEntries(state, action) {
      state.entries = action.payload;
      state.runing = false;
    },
    setRuning(state, action) {
      state.runing = action.payload;
    },
    setFilterQuery(state, action) {
      state.filterQuery = action.payload;
    },
  },
});

export const { setSavedFilters, setReduxEntries, setRuning, setFilterQuery,setStatusFilters, setIsSavedFilterAppliedFilters } =
  filterSlice.actions;
export default filterSlice.reducer;
