import React, { useEffect, useState } from 'react';
import { InputText } from '@jobber/components/InputText';
import { InputEmail } from '@jobber/components/InputEmail';
import { InputPassword } from '@jobber/components/InputPassword';
import { showToast } from '@jobber/components/Toast';
import { Button } from '@jobber/components/Button';
import { postToServerNoToken } from '../../components/Common/requests';
import { useNavigate } from 'react-router';
import { Heading } from '@jobber/components/Heading';
import { Link } from 'react-router-dom';
import { isValidEmail, validatePassword } from '../../components/Common/Validations';
import { Spinner } from '@jobber/components/Spinner';
import Footer from './Components/Footer';

function Signup() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: ''
  });
  const setValuesHandler = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };
  const validateConfirmPassword = (confirmPasswordValue) => {
    return confirmPasswordValue === formValues.password || 'Password do not match';
  };
  const validatePasswordChar = (passwordValue) => {
    return (
      validatePassword(passwordValue) ||
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    );
  };
  useEffect(() => {
    const authUser = localStorage.getItem('authUser');
    if (authUser) {
      navigate('/clients');
    }
  }, []);
  const submitHandler = async (e) => {
    e.preventDefault()
    setLoading(true);
    if (!formValues.firstName || !formValues.email || !formValues.password) {
      showToast({ message: 'Please fill out all fields', variation: 'error' });
    } else if (!isValidEmail(formValues.email)) {
      showToast({ message: 'Email is not valid', variation: 'error' });
    } else if (formValues.password != formValues.confirmPassword) {
      showToast({ message: 'Password do not match', variation: 'error' });
    } else if (!validatePassword(formValues.password)) {
      showToast({
        message: 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
        variation: 'error'
      });
    } else {
      const result = await postToServerNoToken(`user/signup`, formValues);
      if (result.status) {
        localStorage.removeItem('cTab');
        showToast({
          message: 'User successfully registered. Please login',
          variation: 'success'
        });
        navigate('/login');
      } else {
        showToast({
          message: result.message || result.error,
          variation: 'error'
        });
      }
    }
    setLoading(false);
  };
  return (
    <div className="row">
      <div className="text-center mb-4">
        {/* <img src={fullLogo} alt="" style={{ width: "60%" }} /> */}
        <Heading level={1}>JOBBER X THE ROYAL GATEWAY</Heading>
      </div>
      <div className="col-md-4"></div>
      <div className="col-md-4">
        <div className="d-flex justify-content-between mb-3">
          <Heading level={3}>Signup</Heading>
          <Link to={'/login'}>Login</Link>
        </div>
        <div className="card">
          <form className="card-body" onSubmit={submitHandler}>
            <InputText
              placeholder="First Name"
              name="firstName"
              value={formValues.firstName}
              onChange={(value) => setValuesHandler('firstName', value)}
              validations={{
                required: {
                  value: true,
                  message: 'Tell us your first name'
                }
              }}
            />
            <div className="mt-3"></div>
            <InputText
              placeholder="Last Name"
              name="lastName"
              value={formValues.lastName}
              onChange={(value) => setValuesHandler('lastName', value)}
              validations={{
                required: {
                  value: true,
                  message: 'Tell us your last name'
                }
              }}
            />
            <div className="mt-3"></div>

            <InputEmail
              placeholder="Email"
              name="email"
              value={formValues.email}
              onChange={(value) => setValuesHandler('email', value)}
              validations={{
                required: {
                  value: true,
                  message: 'Tell us your email'
                }
              }}
            />
            <div className="mt-3"></div>

            <InputPassword
              placeholder="Password"
              name="password"
              hasVisibility={true}
              value={formValues.password}
              onChange={(value) => setValuesHandler('password', value)}
              validations={{
                required: { value: true, message: 'Password is required' },
                validate: validatePasswordChar,
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              }}
            />
            <div className="mt-3"></div>

            <InputPassword
              placeholder="Confirm Password"
              name="confirmpassword"
              hasVisibility={true}
              value={formValues.confirmPassword}
              onChange={(value) => setValuesHandler('confirmPassword', value)}
              validations={{
                required: { value: true, message: 'Password is required' },
                validate: validateConfirmPassword,
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              }}
            />
            <div className="mt-3"></div>
            {loading ? <Spinner size="base" /> : <Button label={'Sign Up'} submit />}
          </form>
        </div>
      </div>
      <div className="col-md-4"></div>
      <Footer />
    </div>
  );
}

export default Signup;
