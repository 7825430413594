import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { getFromServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { Content } from '@jobber/components/Content';
import { Modal } from '@jobber/components/Modal';
import { Badge } from 'react-bootstrap';
import { notesActionsBadge } from '../../../components/Common/Validations';

const ActivityCalendar = ({ userId, open, onCancel }) => {
	const [activities, setActivities] = useState([]);
	const [selectedAction, setSelectedAction] = useState('all');
	const [events, setEvents] = useState([]);
	const [selectedActions, setSelectedActions] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [eventModalOpen, setEventModalOpen] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const result = await getFromServer('notes/activity');
				if (result.status) {
					setActivities(result.data);
				} else {
					showToast({
						message: result.message || result.error,
						variation: 'error'
					});
				}
			} catch (error) {
				console.error('Error fetching activity data:', error);
			}
		};

		fetchData();
	}, [userId]);

	useEffect(() => {
		const normalizeDate = (date) => {
			const normalizedDate = new Date(date);
			normalizedDate.setHours(0, 0, 0, 0);
			return normalizedDate;
		};

		const filterEvents = () => {
			const newEvents = activities.flatMap((act) => {
				return Object.keys(act.actions)
					.filter((type) => selectedAction === 'all' || type === selectedAction)
					.map((type) => ({
						title: type.charAt(0).toUpperCase() + type.slice(1),
						start: normalizeDate(new Date(act._id)),
						end: normalizeDate(new Date(act._id)),
						allDay: true,
						extendedProps: {
							actions: act.actions[type],
							actionType: type
						},
						className: `highlight-${type}`
					}));
			});

			setEvents(newEvents);
		};

		filterEvents();
	}, [activities, selectedAction]);

	const handleActionClick = (action) => {
		setSelectedAction(action);
	};

	const handleEventClick = (clickInfo) => {
		setSelectedActions(clickInfo.event.extendedProps.actions);
		setSelectedDate(clickInfo.event.startStr);
		setEventModalOpen(true);
	};

	const handleEventModalClose = () => {
		setEventModalOpen(false);
		setSelectedActions([]);
		setSelectedDate(null);
	};

	return (
		<div>
			<Modal
				title={'Activity Logs'}
				open={open}
				size={'large'}
				secondaryAction={{
					label: 'Close',
					onClick: onCancel
				}}
				onRequestClose={onCancel}
			>
				<Content>
					<div className="legend">
						<div className={`legend-item ${selectedAction === 'all' ? 'legend__active' : ''}`} onClick={() => handleActionClick('all')}>
							<span className="highlight-all"></span> All
						</div>
						<div
							className={`legend-item ${selectedAction === 'translated' ? 'legend__active' : ''}`}
							onClick={() => handleActionClick('translated')}
						>
							<span className="highlight-translated"></span> Translated
						</div>
						<div
							className={`legend-item ${selectedAction === 'updated' ? 'legend__active' : ''}`}
							onClick={() => handleActionClick('updated')}
						>
							<span className="highlight-updated"></span> Updated
						</div>
						<div
							className={`legend-item ${selectedAction === 'corrected' ? 'legend__active' : ''}`}
							onClick={() => handleActionClick('corrected')}
						>
							<span className="highlight-corrected"></span> Corrected
						</div>
					</div>
					<FullCalendar
						plugins={[dayGridPlugin, interactionPlugin]}
						initialView="dayGridMonth"
						events={events}
						height="auto"
						contentHeight="auto"
						displayEventTime={false}
						eventContent={(eventInfo) => (
							<div>
								<div className="ms-2 fw-bold">{eventInfo.event.extendedProps.actionType}</div>
							</div>
						)}
						eventClick={handleEventClick}
					/>
				</Content>
			</Modal>

			{eventModalOpen && (
				<Modal
					title={`Actions on ${selectedDate}`}
					open={eventModalOpen}
					size={'large'}
					secondaryAction={{
						label: 'Close',
						onClick: handleEventModalClose
					}}
					onRequestClose={handleEventModalClose}
				>
					<Content>
						{selectedActions.map((action, idx) => (
							<div key={idx} className="mb-2">
								{idx + 1}) <b>{action.clientName}:</b> {action.message}
							</div>
						))}
					</Content>
				</Modal>
			)}
		</div>
	);
};

export default ActivityCalendar;
