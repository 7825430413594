import React from 'react';
import Header from './Header';

function UserAgreement() {
  return (
    <>
      <Header />
      <div className="container mt-5 mb-5" style={{ marginBottom: '10rem' }}>
        <h1>The Royal Gateway User License Agreement</h1>
        <p>Effective Date: March 18, 2024</p>

        <h2>Introduction</h2>
        <p>
          This User License Agreement governs your access to and use of the JOBBER Genie Herbe mobile application ("app"),
          developed by us. By accessing or using the app, you agree to be bound by the terms of this
          Agreement. If you do not agree to all the terms of this Agreement, you may not access or use the app.
        </p>

        <h2>License Grant</h2>
        <p>
          Subject to the terms and conditions of this Agreement, we grant you a non-exclusive, non-transferable, revocable license to access
          and use the app for your personal, non-commercial purposes only. You may not modify, disassemble, reverse engineer, decompile, or
          create derivative works based on the app.
        </p>

        <h2>Your Responsibilities</h2>
        <p>You are responsible for:</p>
        <ul>
          <li>Complying with all applicable laws and regulations in your use of the app.</li>
          <li>Providing accurate and complete information when creating an account (if applicable).</li>
          <li>
            Maintaining the confidentiality of your account credentials and being responsible for all activities that occur under your
            account.
          </li>
          <li>
            Not using the app for any illegal or unauthorized purpose, including but not limited to infringing on the intellectual property
            rights of others.
          </li>
          <li>Not transmitting any viruses, malware, or other harmful code through the app.</li>
        </ul>

        <h2>Intellectual Property</h2>
        <p>
          The app and all intellectual property rights associated with it, including but not limited to copyrights, trademarks, and patents,
          are owned by us or our licensors. You agree not to remove or alter any proprietary notices on the app.
        </p>

        <h2>Disclaimer of Warranties</h2>
        <p>
          THE APP IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
          DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, AND NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE APP WILL FUNCTION UNINTERRUPTED, ERROR-FREE, OR VIRUS-FREE. YOU ASSUME
          ALL RESPONSIBILITY FOR THE CONSEQUENCES OF USING THE APP.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
          IN NO EVENT SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR
          RELATED TO YOUR USE OF THE APP, INCLUDING BUT NOT LIMITED TO PERSONAL INJURY, PROPERTY DAMAGE, LOSS OF DATA, OR LOSS OF PROFITS,
          EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>

        <h2>Term and Termination</h2>
        <p>
          This Agreement shall remain in effect until terminated by either party. We may terminate this Agreement at any time for any
          reason, with or without notice to you. You may terminate this Agreement by stopping your use of the app.
        </p>

        <h2>Entire Agreement</h2>
        <p>This Agreement constitutes the entire agreement between you and us regarding your access to and use of the app.</p>

        <h2>Governing Law</h2>
        <p>
          This Agreement shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its
          conflict of laws provisions.
        </p>

        <h2>Dispute Resolution</h2>
        <p>
          Any dispute arising out of or relating to this Agreement shall be resolved by binding arbitration in accordance with the rules of
          the American Arbitration Association (http://www.adr.org/). The arbitration shall be held in [Your City, State].
        </p>

        <h2>Severability</h2>
        <p>
          If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining
          provisions shall remain in full force and effect.
        </p>

        <h2>Amendments</h2>
        <p>
          We may amend this Agreement at any time by posting the amended terms on this page. You are responsible for checking this page
          periodically for updates. Your continued use of the app after the amended terms are posted constitutes your acceptance of the
          amended Agreement.
        </p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Agreement, please contact us at:</p>
        <ul>
          <li>Email: <a href="mailto:legenieenherbe@gmail.com">legenieenherbe@gmail.com</a></li>
        </ul>
      </div>
    </>
  );
}

export default UserAgreement;
