import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Select, Option } from '@jobber/components/Select';
import Pagination from '../../../components/Common/Pagination';
import { getFromServer, postToServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import './table.css';

// data
import { automationQuoteColumnVisibility, automationQuoteWidthData } from '../../AdvanceQuotes/Table/TableData';
import { setPreservedR, setAutomationDataR } from '../../../store/automationTable';
import FiltersIndex from './FiltersIndex';
import QuoteSocket from '../../../socket/QuoteSocket';
import { setReduxEntries } from '../../../store/quoteFilterSlice';
import ConvertToJobButton from './ConvertToJobButton';
import { useAutomationFilters } from '../FilterQuery/AutomationFilterQuery';
import QuotesTable from './QuotesTable';
import {
  getColWidthData,
  getIconStyle,
  getLocalStorageSelector,
  getTableSelector,
  handleSort,
  initialzeTable,
  makeElementStickyOnScroll,
  reinitialzeDragTable,
  toggleColumn,
  useUserQuotesSettings
} from '../../../utils/manageTable';
import DropdownFilters from './Filters/DropdownFilters';
import ShowAppliedFilters from '../../../components/Common/ShowAppliedFilters';
import useQuery from '../../../utils/useQuery';
import { SocketContext } from '../../../SocketProvider';
import { useJobContext } from '../contextAPI/JobContext';

export default function Table({ tagCloud, reload }) {
  const navigate = useNavigate();
  const { reloadUndo } = useContext(SocketContext)
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [completeData, setCompleteData] = useState({});
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [entries, setEntries] = useState(null);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const { updateFilter, buildQuery, filters, addFilterList } = useAutomationFilters();
  const location = useLocation();
  const [isAdvanceQuotes, setIsAdvanceQuotes] = useState(true);
  useEffect(() => {
    setIsAdvanceQuotes(location?.pathname.includes('recycle') ? false : true);
    setData([]);
  }, [location]);

  const query = useQuery();
  const [curentTabLink, setCurentTabLink] = useState('job-page');

  useEffect(() => {
    setCurentTabLink('job-page');
  }, []);

  useEffect(() => {
    let tab = query.get('tab');
    if (tab && tab == 'approved-quotes') setCurentTabLink('job-page');
    else if (tab && tab == 'closed-jobs') setCurentTabLink('jobs');
    else if (tab && tab == 'converted-jobs') setCurentTabLink('converted-jobs');
    if (!tab) setCurentTabLink('job-page');
  }, [location]);

  const reduxQuotesTable = useSelector((store) => store.automationTable);
  const [search, setSearch] = useState(reduxQuotesTable?.isPreserved ? reduxQuotesTable.search || '' : '');

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ASCENDING'
  });

  useEffect(() => {
    const cLoc = location.pathname.includes('recycle') ? false : true;
    if (cLoc) {
      const tempFilter = JSON.parse(localStorage.getItem('tempQuotesFilter')) || {};
      if (tempFilter?.limit) setLimit(parseInt(tempFilter?.limit?.[0]));
      if (tempFilter?.page) setCurrentPage(parseInt(tempFilter?.page?.[0]));
      if (tempFilter?.sort_key)
        setSortConfig({
          key: tempFilter?.sort_key?.[0],
          direction: tempFilter?.sort_direction?.[0]
        });
    }
  }, []);

  // column visibility
  const [visibleColumns, setVisibleColumns] = useState({
    checkbox: true,
    name: true,
    title: true,
    address: true,
    zipCode: true,
    phone: true,
    email: true,
    details: true,
    totalCost: true,
    // amountPaid: true,
    // amountOwed: true,
    date: true,
    approvedDate: true
  });

  // ++++++++++++++++++

  // for user settings
  const [dragtableState, setDragtableState] = useState({});
  useEffect(() => {
    useUserQuotesSettings(setVisibleColumns, setDragtableState, navigate, 'approvedQuotesSettings');
  }, []);

  const [saveLoader, setSaveLoader] = useState(false);
  const saveUserSettingsHandler = async () => {
    const body = {
      columnOrder: dragtableState,
      columnVisibility: visibleColumns,
      columnWidths: getColWidthData(automationQuoteWidthData)
    };
    setSaveLoader(true);
    const result = await postToServer('approved-quote-Settings', body);
    if (result.status) {
      localStorage.setItem('approvedQuotesSettings', JSON.stringify(body));
      showToast({
        message: 'Approved Quotes settings updated successfully'
      });
    } else {
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
    setSaveLoader(false);
  };
  // ++++++++++++++++++++

  useEffect(() => {
    if (Object.entries(reduxQuotesTable?.data).length > 0) {
      setCurrentPage(reduxQuotesTable?.data.currentPage);
      if (reduxQuotesTable?.isPreserved) {
        setData(reduxQuotesTable?.data.quotes);
        setLoading(false);
      }
      setTotalAmount(reduxQuotesTable?.data?.page);
      setEntries(reduxQuotesTable?.data?.totalQuotes);
      dispatch(setReduxEntries(reduxQuotesTable?.data?.totalQuotes));
      setTotalPage(reduxQuotesTable?.data?.totalPages);
      setTimeout(async () => {
        await initialzeTable(setDragtableState, getLocalStorageSelector(curentTabLink), getTableSelector(curentTabLink));
      }, 1000);
    }
  }, []);

  const [socketCalled, setSocketCalled] = useState(false);
  const [totalAmount, setTotalAmount] = useState({});
  const [isTabChanged, setIsTabChanged] = useState(false);

  const fetchTableData = async (destroy = false) => {
    const queryString = buildQuery();
    if (!queryString) return
    const tableData = await getFromServer(`${curentTabLink}?${queryString}`);
    if (tableData.status) {
      setIsTabChanged(false);
      reloadHeader();
      if (destroy) {
        try {
          $(getTableSelector(curentTabLink)).dragtable('destroy');
        } catch (error) { }
      }
      if (curentTabLink == 'job-page') {
        dispatch(setAutomationDataR(tableData.data));
        setData(tableData.data.quotes);
        setCompleteData(tableData.data);
        setTotalAmount(tableData.data?.page);
        setEntries(tableData?.data?.totalQuotes);
        setTotalPage(tableData?.data?.totalPages);
        dispatch(setReduxEntries(tableData?.data?.totalQuotes));
      } else if (curentTabLink == 'jobs') {
        setData(tableData.jobs);
        setEntries(tableData?.totalJobs);
        dispatch(setReduxEntries(tableData?.totalJobs));
        setTotalPage(tableData?.totalPages);
      } else if (curentTabLink == 'converted-jobs') {
        setData(tableData.jobs);
        setEntries(tableData?.totalJobs);
        dispatch(setReduxEntries(tableData?.totalJobs));
        setTotalPage(tableData?.totalPages);
      }
      setTimeout(async () => {
        await initialzeTable(setDragtableState, getLocalStorageSelector(curentTabLink), getTableSelector(curentTabLink));
      }, 100);
      reinitialzeDragTable('table');
    } else {
      showToast({
        message: tableData.message || tableData.error,
        variation: 'error'
      });
    }
    setLoading(false);
  };

  const fetchData = async () => {
    if (filters?.searchTerm?.[0] == '') {
      await fetchTableData();
    } else {
      updateFilter('searchTerm', '');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!reduxQuotesTable?.isPreserved) {
      setLoading(true);
      fetchData();
    } else {
      updateFilter('searchTerm', '');
    }
  }, [location]);

  const [isRecycle, setIsRecycle] = useState(false);
  const { selectedIds, setSelectedIds } = useJobContext();

  // sorting
  const onSort = (key) => {
    handleSort(key, sortConfig, setSortConfig, updateFilter);
  };

  // applying filters
  const [pageLoad, setPageLoad] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const reloadHeader = () => {
    setShowHeader(false);
    setTimeout(() => {
      setShowHeader(true);
    }, 10);
  };
  useEffect(() => {
    if (!pageLoad && reloadUndo) {
      if (!reduxQuotesTable?.isPreserved) {
        reloadHeader();
        const queryString = buildQuery();
        if (!queryString || queryString?.length <= 0) {
          setCurrentPage(1);
        }
        if (parseInt(filters?.page?.[0])) {
          setCurrentPage(parseInt(filters?.page?.[0]));
        }
        setLoading(true);
        fetchTableData(true);
      } else {
        dispatch(setPreservedR(false));
      }
    } else {
      setPageLoad(false);
    }
  }, [filters, reloadUndo]);

  useEffect(() => {
    if (!pageLoad) {
      reloadHeader();
      fetchTableData(true);
    } else {
      setPageLoad(false);
    }
  }, [socketCalled]);

  // dropdown
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownToggleRef = useRef(null);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const cleanup = makeElementStickyOnScroll('.check__sticky', 270, setIsSticky);
    return cleanup;
  }, []);

  // ++++++++++++++++++++++++

  // search debbouncing
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);

  const updateFilters = useCallback((filterName, value) => {
    updateFilter(filterName, value);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (isTabChanged) {
      setIsTabChanged(false);
    } else {
      if (!pageLoad) {
        updateFilters('searchTerm', debouncedSearchTerm);
      } else {
        setPageLoad(false);
      }
    }
  }, [debouncedSearchTerm]);

  // hover
  const [editId, setEditId] = useState(-1);

  return (
    <>
      <QuoteSocket socketCalled={() => setSocketCalled(!socketCalled)} />
      <div className="mb-5">
        <ShowAppliedFilters page={'automation'} />
      </div>
      <div>
        {/* <div className="search__sticky" style={{ zIndex: "10" }}> */}
        <div className="row mb-3">
          <div className="col-lg-3 col-md-4 d-flex gap-2 align-items-center">
            <p className="pt-3">Show</p>
            <Select
              size="small"
              onChange={(e) => {
                updateFilter('limit', e);
                setLimit(e);
                setSelectedIds([])
              }}
              value={limit}
            >
              <Option value="all">All (Takes time to load)</Option>
              <Option value="10">10</Option>
              <Option value="20">20</Option>
              <Option value="50">50</Option>
            </Select>
            <p className="pt-3">entries</p>
          </div>
          {/* <div className="col-lg-2 col-md-1"></div> */}
          <div className="col-md-9 mt-2">
            <div className="d-flex gap-2 flex-wrap justify-content-end align-items-center" style={{ paddingBottom: '1rem' }}>
              <DropdownFilters />
              <ConvertToJobButton
                sync={() => {
                  setLoading(true);
                  fetchTableData(true);
                }}
              />
              <FiltersIndex />
            </div>
          </div>
        </div>
      </div>
      <QuotesTable
        search={search}
        setSearch={setSearch}
        loading={loading}
        showHeader={showHeader}
        isSticky={isSticky}
        columnVisibility={automationQuoteColumnVisibility}
        visibleColumns={visibleColumns}
        onSort={onSort}
        getIconStyle={(key, direction) => getIconStyle(key, direction, sortConfig)}
        isAdvanceQuotes={isAdvanceQuotes}
        toggleColumn={(colName) => toggleColumn(setVisibleColumns, colName, 'table')}
        data={data}
        editId={editId}
        isRecycle={isRecycle}
        dropdownToggleRef={dropdownToggleRef}
        toggleDropdown={toggleDropdown}
        showDropdown={showDropdown}
        saveLoader={saveLoader}
        saveUserSettingsHandler={saveUserSettingsHandler}
      />
      {data?.length <= 0 && !loading ? <div className="text-center">No data found</div> : ''}
      <div className="d-md-flex justify-content-between">
        <div className="">
          <p> {entries} entries</p>
        </div>

        {totalPage <= 1 ? (
          ''
        ) : (
          <div className="">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              onPageChange={(page) => {
                setCurrentPage(page);
                updateFilter('page', page);
                window.scrollTo(0, 0);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
