import { Heading } from "@jobber/components/Heading";
import React, { useEffect, useState } from "react";
import downFill from "../../../assets/icons/downFill.svg";
import sideFill from "../../../assets/icons/sideFill.svg";
import { Checkbox } from "@jobber/components/Checkbox";
import { FormField } from "@jobber/components/FormField";
import { Text } from "@jobber/components/Text";

// import { useDrag, useDrop } from "react-dnd";
import {
  deleteFromServer,
  getFromServer,
  putToServer,
} from "../../../components/Common/requests";
import { useDispatch, useSelector } from "react-redux";
import { setFilterQuery, setIsSavedFilterAppliedFilters, setSavedFilters } from "../../../store/filterSlice";
import { setNotesFilterQuery, setIsSavedFilterAppliedFilters as notesApplied } from "../../../store/notesFilterSlice";

import { useFilter } from "../FilterQuery/FilterQuery";
import { addToLocalStorageArray, getFilteredDataValues } from "../../../components/Common/Validations";
import { showToast } from "@jobber/components/Toast";
import { FiEdit } from "react-icons/fi";
import { IoMdSave } from "react-icons/io";
import { Spinner } from "@jobber/components/Spinner";
import { ImBin } from "react-icons/im";
import { useLocation } from "react-router";
import { useNotesFilter } from "../../AdvanceNotes/FilterQuery/NotesFilterQuery";

const FilterItem = ({ filter, index, moveFilter, reload, editSavedFilterId }) => {
  const location = useLocation()
  const { addFilterList, filters } = location.pathname?.includes('advance-notes') ? useNotesFilter() : useFilter();
  const dispatch = useDispatch();
  const sFilter = useSelector((store) => store.filters);
  // const [{ isDragging }, drag] = useDrag(() => ({
  //   type: "filter",
  //   item: { index },
  //   collect: (monitor) => ({
  //     isDragging: monitor.isDragging(),
  //   }),
  // }));

  // const [, drop] = useDrop({
  //   accept: "filter",
  //   hover: (item) => {
  //     if (item.index !== index) {
  //       moveFilter(item.index, index);
  //       item.index = index;
  //     }
  //   },
  // });

  const filterHandler = () => {
    if (location.pathname?.includes('advance-notes')) {
      localStorage.removeItem('notesTagCloudInclude')
      dispatch(setNotesFilterQuery(filter?.tags));
      dispatch(notesApplied())
      if ('includeTags' in filter?.tags) {
        filter?.tags?.includeTags.forEach((text) =>
          addToLocalStorageArray('notesTagCloudInclude', text)
        )
      }
      addFilterList(filter?.tags, filter?._id);
    }
    else {
      localStorage.removeItem('tagCloudInclude')
      dispatch(setFilterQuery(filter?.tags));
      dispatch(setIsSavedFilterAppliedFilters())
      if ('includeTags' in filter?.tags) {
        filter?.tags?.includeTags.forEach((text) =>
          addToLocalStorageArray('tagCloudInclude', text)
        )
      }
      addFilterList(filter?.tags, filter?._id);
    }
    reload();
  };

  const editClickHandler = (e) => {
    e.stopPropagation();
    dispatch(setFilterQuery(filter?.tags));
    dispatch(setIsSavedFilterAppliedFilters())
    addFilterList(filter?.tags, filter?._id);
    reload(filter._id);
    setIsEditing(true);
  };

  const [text, setText] = useState(filter?.filterName);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(editSavedFilterId && editSavedFilterId == filter?._id ? true : false);

  const updateHandler = async () => {
    setLoading(true);
    const filterTypes = ["name", "address", "company", "email", "phone"];
    let filterQuery = [];

    filterTypes.forEach((filterType) => {
      const typeKey = `${filterType.toLowerCase()}FilterType`;
      const valueKey = `${filterType.toLowerCase()}FilterValue`;

      if (typeKey in filters) {
        filterQuery.push({
          label: filterType,
          constraint: filters[typeKey]?.[0],
          value: filters[valueKey]?.[0],
        });
      }
    });
    let body = {
      filterName: text,
      filters: filterQuery,
      // limit: filters.limit?.[0] || 10,
      // searchTerm: filters.searchTerm?.[0] || "",
      status: filters.filter?.[0] || "All",
      tags: {
        include: filters?.includeTags || [],
        exclude: filters?.excludeTags || [],
      },
      totalEntries: sFilter?.entries,
    };
    console.log(body, filters)
    const result = await putToServer(`filters/${filter._id}`, body);
    if (result.status) {
      console.log(result.data)
      showToast({
        message: "Filter updated successfully",
      });
      const index = sFilter.filters.findIndex(
        (item) => item._id === filter._id
      );
      if (index !== -1) {
        let newData = [...sFilter.filters];
        let newTag = getFilteredDataValues(result.data);
        newData[index] = { ...newData[index], filterName: text, tags: newTag, totalEntries: sFilter?.entries };
        dispatch(setSavedFilters(newData));
      }
    } else {
      showToast({
        message: result.message || result.error,
        variation: "error",
      });
    }
    setLoading(false);
    setIsEditing(false);
  };

  const deleteHandler = async () => {
    setLoading(true);
    const result = await deleteFromServer(`filters/${filter._id}`);
    if (result.status) {
      showToast({
        message: "Filter deleted successfully",
      });
      const newData = sFilter.filters.filter((item) => item._id !== filter._id);
      dispatch(setSavedFilters(newData));
    } else {
      showToast({
        message: result.message || result.error,
        variation: "error",
      });
    }
    setLoading(false);
  };

  return (
    <div
      // ref={(node) => drag(drop(node))}
      // style={{ opacity: isDragging ? 0.5 : 1 }}
      className="row mb-2 pointer"
      onClick={isEditing ? null : filterHandler}
    >
      <div className="col-md-8">
        {location.pathname?.includes('advance-notes') ?
          <Text>{filter?.filterName}</Text>
          : <div className="d-flex gap-1 align-items-center">
            {isEditing ? (
              <input
                type="text"
                value={text}
                className="form-control"
                onChange={(e) => setText(e.target.value)}
                autoFocus
              />
            ) : (
              <Text>{filter?.filterName}</Text>
            )}
            <div className="ms-3">
              {isEditing ? (
                loading ? (
                  <Spinner size="small" />
                ) : (
                  <IoMdSave
                    className="fs-4 pointer"
                    title="close"
                    onClick={updateHandler}
                  />
                )
              ) : (
                <FiEdit className="pointer" onClick={editClickHandler} />
              )}
            </div>
            {!isEditing &&
              (loading ? (
                <Spinner size="small" />
              ) : (
                <ImBin
                  className="pointer text-danger"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteHandler();
                  }}
                />
              ))}
          </div>}
      </div>
      {!isEditing && (
        <div className="col-md-4">
          <span className={`badge badge__primary ms-2`}>
            {filter.totalEntries}
          </span>
        </div>
      )}
    </div>
  );
};

function SavedFilters({ reload, editSavedFilterId, isClientPage }) {
  const dispatch = useDispatch();
  const sFilter = useSelector((store) => store.filters);
  const [show, setShow] = useState(true);
  const [filters, setFilters] = useState([]);

  const moveFilter = (dragIndex, hoverIndex) => {
    const dragItem = filters[dragIndex];
    const newFilters = [...filters];
    newFilters.splice(dragIndex, 1);
    newFilters.splice(hoverIndex, 0, dragItem);
    setFilters(newFilters);
    dispatch(setSavedFilters(newFilters));
    console.log("Current filter order:", newFilters);
  };

  useEffect(() => {
    const fetchResult = async () => {
      const result = await getFromServer("filters");
      if (result.status) {
        const newData = result.data.map((d) => {
          let newTag = getFilteredDataValues(d);
          return { ...d, tags: newTag };
        });
        setFilters(newData);
        dispatch(setSavedFilters(newData));
      } else {
        showToast({
          message: result.message || result.error,
          variation: "error",
        });
      }
    };
    if (sFilter?.filters?.length <= 0) {
      fetchResult();
    }
  }, []);

  const handleSaveOrder = () => {
    // Here you can send 'filters' to a backend server or save it locally
  };

  return (
    <div>
      <div className="d-flex pointer" onClick={() => setShow((prev) => !prev)}>
        {show ? (
          <img src={downFill} className="me-2" />
        ) : (
          <img src={sideFill} className="me-2" />
        )}
        <Heading level={isClientPage ? 4 : 3}>Saved Filters</Heading>
      </div>{" "}
      <div className={`${show ? "d-block" : "d-none"}`}>
        <div className="p-3">
          {sFilter.filters.map((filter, index) => (
            <FilterItem
              key={filter._id}
              filter={filter}
              index={index}
              moveFilter={moveFilter}
              reload={reload}
              editSavedFilterId={editSavedFilterId}
            />
          ))}
          {sFilter.filters?.length <= 0 ? <div>No data found</div> : ""}
        </div>
      </div>
    </div>
  );
}

export default SavedFilters;
