import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, LineChart, Line, ResponsiveContainer, Label } from 'recharts';
import {
  format,
  parseISO,
  startOfMonth,
  endOfMonth
} from 'date-fns';
import Charts from './Charts';
import { getFromServer } from '../../../components/Common/requests';
import { useEffect, useState } from 'react';
import useGetFromServer from '../../../utils/useGetFromServer';

const ChartsQuotes = () => {
  const [showGroup, setShowGroup] = useState(false)
  const [queryDate, setQueryDate] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection'
    }
  ])
  const [currentPageId, setCurrentPageId] = useState()
  // const [pages, setPages] = useState([])
  const groupDataByInterval = (data, interval) => {
    return data.reduce((acc, { date, cost, amountOwed, amountPaid, quotes }) => {
      let key;
      if (interval === 'day') {
        key = format(parseISO(date), 'dd MMM yyyy'); // Group by day
      } else if (interval === 'month') {
        key = format(parseISO(date), 'MMM yyyy'); // Group by month
      } else if (interval === 'year') {
        key = format(parseISO(date), 'yyyy'); // Group by year
      }

      const existingItem = acc.find(item => item.period === key);
      if (existingItem) {
        existingItem.totalCost = parseFloat((existingItem.totalCost + cost).toFixed(2));
        existingItem.totalAmountOwed = parseFloat((existingItem.totalAmountOwed + amountOwed).toFixed(2));
        existingItem.totalAmountPaid = parseFloat((existingItem.totalAmountPaid + amountPaid).toFixed(2));
        existingItem.totalQuotes += quotes;
      } else {
        acc.push({
          period: key,
          totalCost: parseFloat(cost.toFixed(2)),
          totalAmountOwed: parseFloat(amountOwed.toFixed(2)),
          totalAmountPaid: parseFloat(amountPaid.toFixed(2)),
          totalQuotes: quotes
        });
      }

      return acc;

    }, []);
  };

  const getTotal = (data) => {
    return data.reduce(
      (acc, item) => {
        acc.totalQuotes += item.totalQuotes;
        acc.totalCost += item.totalCost;
        acc.totalAmountOwed += item.totalAmountOwed;
        acc.totalAmountPaid += item.totalAmountPaid;
        return acc;
      },
      { totalQuotes: 0, totalCost: 0, totalAmountOwed: 0, totalAmountPaid: 0 }
    );
  }

  const renderChart = (chartType, groupedData) => {
    const totals = getTotal(groupedData)
    switch (chartType) {
      case 'PieChart':
        return (
          <PieChart width={400} height={400}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={[
                { name: 'Total Cost', value: totals.totalCost, color: '#0088FE' },
                { name: 'Total Amount Owed', value: totals.totalAmountOwed, color: '#00C49F' },
                { name: 'Total Amount Paid', value: totals.totalAmountPaid, color: '#FFBB28' },
                { name: 'Total Quotes', value: totals.totalQuotes, color: '#FF5733' }
              ]}
              cx={200}
              cy={200}
              outerRadius={150}
              fill="#8884d8"
              label
            >
              {[
                { name: 'Total Cost', value: totals.totalCost, color: '#0088FE' },
                { name: 'Total Amount Owed', value: totals.totalAmountOwed, color: '#00C49F' },
                { name: 'Total Amount Paid', value: totals.totalAmountPaid, color: '#FFBB28' },
                { name: 'Total Quotes', value: totals.totalQuotes, color: '#FF5733' }
              ].map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        );
      case 'BarChart':
        return (
          <ResponsiveContainer width={'100%'} height={400}>
            <BarChart width={400} height={400} data={groupedData} barCategoryGap={5}>
              <XAxis dataKey="period" axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip />
              <Legend />
              <Bar dataKey="totalCost" fill="#8884d8" radius={5} />
              <Bar dataKey="totalAmountOwed" fill="#82ca9d" radius={5} />
              <Bar dataKey="totalAmountPaid" fill="#ffc658" radius={5} />
              <Bar dataKey="totalQuotes" fill="#FF5733" radius={5} />
            </BarChart>
          </ResponsiveContainer>
        );
      case 'LineChart':
        return (
          <ResponsiveContainer width={'100%'} height={400}>
            <LineChart width={400} height={400} data={groupedData}>
              <XAxis dataKey="period" axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="totalCost" stroke="#8884d8" />
              <Line type="monotone" dataKey="totalAmountOwed" stroke="#82ca9d" />
              <Line type="monotone" dataKey="totalAmountPaid" stroke="#ffc658" />
              <Line type="monotone" dataKey="totalQuotes" stroke="#FF5733" />
            </LineChart>
          </ResponsiveContainer>
        );
      default:
        return null;
    }
  };

  const getParam = () => {
    const { startDate, endDate } = queryDate[0]

    format(endDate, 'yyyy-MM-dd')

    return new URLSearchParams({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      // showGroup
    });
  }

  const handlePageChange = (id) => {
    setCurrentPageId(id)
  }

  const handleGroupBool = (val) => {
    setShowGroup(val)
  }

  const getDate = (date) => {
    setQueryDate(date)
  }

  const params = getParam()
  const { data, loading } = useGetFromServer(currentPageId ? `dashboard-quotes/${currentPageId}?${params}` : `dashboard-quotes?${params}`, [currentPageId, queryDate])

  const { data: pages, loading: pagesLoading } = useGetFromServer('pages')

  return (
    <Charts
      data={data}
      groupDataByInterval={groupDataByInterval}
      renderChart={renderChart}
      title={"Advance Quotes"}
      pages={pages}
      pageChangeHandler={handlePageChange}
      getDate={getDate}
      isLoading={loading || pagesLoading}
      handleGroupBool={handleGroupBool}
    />
  )
};

export default ChartsQuotes;
