export const columnVisibility = [
  {
    name: 'Notes',
    key: 'notes',
    colName: 'colNotes'
  },
  {
    name: 'Name',
    key: 'name',
    filter: 'CLIENT_NAME',
    colName: 'colName'
  },
  {
    name: 'Title',
    key: 'title',
    filter: 'TITLE',
    colName: 'colTitle'
  },
  {
    name: 'Address',
    key: 'address',
    filter: 'ADDRESS',
    colName: 'colAddress'
  },
  {
    name: 'Zip Code',
    key: 'zipCode',
    filter: 'POSTAL_CODE',
    colName: 'colZipCode'
  },
  {
    name: 'Phone',
    key: 'phone',
    filter: 'CLIENT_PHONE',
    colName: 'colPhone'
  },
  {
    name: 'Email',
    filter: 'CLIENT_EMAIL',
    key: 'email',
    colName: 'colEmail'
  },
  {
    name: 'Approved',
    key: 'approved',
    colName: 'colApproved'
  },
  {
    name: 'Details',
    key: 'details',
    colName: 'colDetails'
  },
  {
    name: 'Total Cost',
    key: 'totalCost',
    logo: 'jobber',
    colName: 'colTotalCost',
    filter: 'TotalCost'
  },
  {
    name: 'Amount Paid',
    key: 'amountPaid',
    logo: 'jobber',
    colName: 'colAmountPaid',
    filter: 'AmountPaid'
  },
  {
    name: 'Amount Owed',
    key: 'amountOwed',
    logo: 'jobber',
    colName: 'colAmountOwed',
    filter: 'AmountOwed'
  },
  {
    name: 'QB Total Cost',
    key: 'QBtotalCost',
    logo: 'quickbook',
    filter: 'QBTotalCost',
    colName: 'colQBTotalCost'
  },
  {
    name: 'QB Amount Paid',
    key: 'QBamountPaid',
    logo: 'quickbook',
    filter: 'QBAmountPaid',
    colName: 'colQBAmountPaid'
  },
  {
    name: 'QB Amount Owed',
    key: 'QBamountOwed',
    logo: 'quickbook',
    filter: 'QBAmountOwed',
    colName: 'colQBAmountOwed'
  },
  {
    name: 'Date',
    key: 'date',
    colName: 'colDate',
    filter: 'QUOTE_CREATED_AT'
  },
  {
    name: 'Approved Date',
    key: 'approvedDate',
    colName: 'colApprovedDate',
    filter: 'APPROVED_AT'
  },
  {
    name: 'Total Amount Owed',
    key: 'totalAmountOwed',
    colName: 'colTotalAmountOwed',
    filter: 'totalAmountOwed'
  }
];

export const widthData = [
  'colName',
  'colTitle',
  'colAddress',
  'colZipCode',
  'colPhone',
  'colEmail',
  'colApproved',
  'colDetails',
  'colTotalCost',
  'colQBTotalCost',
  'colQBAmountPaid',
  'colQBAmountOwed',
  'colAmountPaid',
  'colAmountOwed',
  'colDate',
  'colApprovedDate',
  'colTotalAmountOwed'
];

export const automationQuoteColumnVisibility = [
  {
    name: 'Name',
    key: 'name',
    filter: 'CLIENT_NAME',
    colName: 'colName'
  },
  {
    name: 'Title',
    key: 'title',
    filter: 'TITLE',
    colName: 'colTitle'
  },
  {
    name: 'Address',
    key: 'address',
    filter: 'ADDRESS',
    colName: 'colAddress'
  },
  {
    name: 'Zip Code',
    key: 'zipCode',
    filter: 'POSTAL_CODE',
    colName: 'colZipCode'
  },
  {
    name: 'Phone',
    key: 'phone',
    filter: 'CLIENT_PHONE',
    colName: 'colPhone'
  },
  {
    name: 'Email',
    filter: 'CLIENT_EMAIL',
    key: 'email',
    colName: 'colEmail'
  },
  // {
  //   name: 'Approved',
  //   key: 'approved',
  //   colName: 'colApproved'
  // },
  {
    name: 'Details',
    key: 'details',
    colName: 'colDetails'
  },
  {
    name: 'Total Cost',
    key: 'totalCost',
    colName: 'colTotalCost',
    filter: 'TotalCost'
  },

  // {
  //   name: 'QB Total Cost',
  //   key: 'QBtotalCost',
  //   colName: 'colQBTotalCost'
  // },
  // {
  //   name: 'QB Amount Paid',
  //   key: 'QBamountPaid',
  //   colName: 'colQBAmountPaid'
  // },
  // {
  //   name: 'QB Amount Owed',
  //   key: 'QBamountOwed',
  //   colName: 'colQBAmountOwed'
  // },
  {
    name: 'Created Date',
    key: 'date',
    colName: 'colDate',
    filter: 'QUOTE_CREATED_AT'
  },
  {
    name: 'Approved Date',
    key: 'approvedDate',
    colName: 'colApprovedDate',
    filter: 'APPROVED_AT'
  }
];

export const automationQuoteWidthData = [
  'colName',
  'colTitle',
  'colAddress',
  'colZipCode',
  'colPhone',
  'colEmail',
  // 'colApproved',
  'colDetails',
  'colTotalCost',
  // 'colQBTotalCost',
  // 'colAmountPaid',
  // 'colAmountOwed',
  'colDate',
  'colApprovedDate'
];
