import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/style.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/layout.css";
import "@jobber/design/foundation.css";
import { Provider } from "react-redux";
import store from "./store/store";
// import { FilterProvider } from "./pages/Index/FilterQuery/FilterQuery";
import { BrowserRouter } from "react-router-dom";
import "../src/assets/css/dragtable.js"
import "../src/assets/css/dragtable.css"
import SocketProvider from "./SocketProvider.jsx";



ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
    <Provider store={store}>
      {/* <FilterProvider> */}
        <BrowserRouter basename={""}>
          <SocketProvider />
        </BrowserRouter>
      {/* </FilterProvider> */}
    </Provider>
  // {/* // </React.StrictMode> */}
);
