import { Heading } from '@jobber/components/Heading';
import React, { useEffect, useState } from 'react';
import downFill from '../../../assets/icons/downFill.svg';
import sideFill from '../../../assets/icons/sideFill.svg';
import { Checkbox } from '@jobber/components/Checkbox';
import { FormField } from '@jobber/components/FormField';
import SavedFilters from './SavedFilters';
import { Divider } from '@jobber/components/Divider';
import { Button } from '@jobber/components/Button';
import { InlineLabel } from '@jobber/components/InlineLabel';
import { postToServer, postToServerNoToken } from '../../../components/Common/requests';
import { Spinner } from '@jobber/components/Spinner';
import { useFilter } from '../FilterQuery/FilterQuery';
import { showToast } from '@jobber/components/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterQuery, setRuning, setSavedFilters } from '../../../store/filterSlice';
import { getFilteredDataValues } from '../../../components/Common/Validations';
import { useLocation } from 'react-router';
import { useNotesFilter } from '../../AdvanceNotes/FilterQuery/NotesFilterQuery';

function Filters({ reload, editSavedFilterId }) {
  const loca = useLocation();
  const isClientPage = loca.pathname.includes('clients');
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [showSave, setShowSave] = useState(false);

  const { updateFilterByList, filters, tempFilters, updateTempFilter, applyButtonFilter } = loca.pathname.includes('advance-notes')
    ? useNotesFilter()
    : useFilter();
  const entry = loca?.pathname?.includes('advance-notes')
    ? useSelector((store) => store.notesFilter)
    : useSelector((store) => store.filters);
  // const entry = useSelector((store) => store.filters);
  const entryFilterQuery = loca?.pathname?.includes('advance-notes')
    ? useSelector((store) => store.notesFilter.notesFilterQuery)
    : useSelector((store) => store.filters.filterQuery);
  // const entryFilterQuery = useSelector((store) => store.filters.filterQuery);
  console.log(entry, entryFilterQuery);

  const initialFilterType = (filterType) =>
    entryFilterQuery?.[`${filterType}FilterType`]?.length <= 0 || entryFilterQuery?.[`${filterType}FilterType`]?.[0] == undefined
      ? 'is'
      : entryFilterQuery?.[`${filterType}FilterType`]?.[0];

  const initialFilterChecked = (filterType) =>
    entryFilterQuery?.[`${filterType}FilterType`]?.length <= 0 || entryFilterQuery?.[`${filterType}FilterType`]?.[0] == undefined
      ? false
      : true;

  const initialFilterValue = (filterType) =>
    entryFilterQuery?.[`${filterType}FilterValue`]?.length <= 0 || entryFilterQuery?.[`${filterType}FilterValue`]?.[0] == undefined
      ? ''
      : entryFilterQuery?.[`${filterType}FilterValue`]?.[0];

  const [fieldFilters, setFieldFilters] = useState();

  useEffect(() => {
    setFieldFilters([
      {
        name: 'Name',
        key: 'name',
        type: initialFilterType('name'),
        value: initialFilterValue('name'),
        checked: initialFilterChecked('name')
      },
      {
        name: 'Address',
        key: 'address',
        type: initialFilterType('address'),
        value: initialFilterValue('address'),
        checked: initialFilterChecked('address')
      },
      {
        name: 'Company Name',
        key: 'company',
        type: initialFilterType('company'),
        value: initialFilterValue('company'),
        checked: initialFilterChecked('company')
      },
      {
        name: 'Email',
        key: 'email',
        type: initialFilterType('email'),
        value: initialFilterValue('email'),
        checked: initialFilterChecked('email')
      },
      {
        name: 'Phone',
        key: 'phone',
        type: initialFilterType('phone'),
        value: initialFilterValue('phone'),
        checked: initialFilterChecked('phone')
      }
    ]);
  }, [entry.isSavedFilterApplied]);

  const [apply, setApply] = useState(false);
  const [saving, setSaving] = useState(false);

  const changeHandler = (name, value, key) => {
    const newdata = [...fieldFilters];
    newdata[key] = { ...newdata[key], [name]: value };
    setFieldFilters(newdata);

    let query = {};
    newdata?.forEach((f) => {
      const filterTypeKey = `${f.key.toLowerCase()}FilterType`;
      const filterValueKey = `${f.key.toLowerCase()}FilterValue`;

      if (f.checked) {
        query[filterTypeKey] = [f.type];
        query[filterValueKey] = [f.value];
      } else {
        query[filterTypeKey] = [];
        query[filterValueKey] = [];
      }
    });
    dispatch(setFilterQuery(query));
    // updateFilterByList(query);
    updateTempFilter(query);
  };

  const applyFilterHandler = async () => {
    // if(Object.keys(filters)?.length <= 0 && Object.keys(tempFilters)?.length <= 0){
    //   showToast({
    //     message: 'Please select any filter to apply',
    //     variation: 'info'
    //   });
    // }
    setApply(true);
    // updateFilterByList(entry?.filterQuery);
    applyButtonFilter();
    setTimeout(() => {
      setApply(false);
      if (!loca?.pathname?.includes('advance-notes')) {
        setShowSave(true);
      }
    }, 2000);
    // }
  };

  const [filterName, setFilterName] = useState('');
  const [showFilterName, setShowFilterName] = useState(false);
  const saveFilterHandler = async () => {
    setSaving(true);
    const filterTypes = ['name', 'address', 'company', 'email', 'phone'];
    let filterQuery = [];

    filterTypes.forEach((filterType) => {
      const typeKey = `${filterType.toLowerCase()}FilterType`;
      const valueKey = `${filterType.toLowerCase()}FilterValue`;

      if (typeKey in filters) {
        filterQuery.push({
          label: filterType,
          constraint: filters[typeKey]?.[0],
          value: filters[valueKey]?.[0]
        });
      }
    });
    let body = {
      filterName: filterName,
      filters: filterQuery,
      limit: filters.limit?.[0] || 10,
      searchTerm: filters.searchTerm?.[0] || '',
      status: filters.filter?.[0] || 'All',
      tags: {
        include: filters?.includeTags || [],
        exclude: filters?.excludeTags || []
      },
      totalEntries: entry?.entries
    };
    const result = await postToServer('filters', body);
    if (result.status) {
      let newTag = getFilteredDataValues(result.data);
      const newData = { ...result.data, tags: newTag };
      dispatch(setSavedFilters([...entry.filters, newData]));
      showToast({
        message: 'Filter saved successfully',
        variation: 'success'
      });
    } else {
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
    setSaving(false);
    setShowSave(false);
  };

  return (
    <>
      <div className="row">
        <div className={isClientPage ? 'col-md-4' : 'col-12'}>
          <SavedFilters reload={reload} editSavedFilterId={editSavedFilterId} isClientPage={isClientPage} />
        </div>
        {!isClientPage && <div className="mt-3"></div>}
        <div className={isClientPage ? 'col-md-8' : 'col-12'}>
          {showSave && (
            <div className="mb-3">
              <InlineLabel>
                <div className="d-flex gap-3 align-items-center">
                  Do you want to save this filter?
                  {showFilterName && (
                    <input type="text" placeholder="Provide Filter name" onChange={(e) => setFilterName(e.target.value)} />
                  )}
                  {saving ? (
                    <div className="ms-2">
                      <Spinner size={'small'} />
                    </div>
                  ) : (
                    <Button
                      label={'Yes, save it.'}
                      size="small"
                      onClick={showFilterName ? saveFilterHandler : () => setShowFilterName(true)}
                    />
                  )}
                  {!saving && (
                    <Button
                      label={'No'}
                      size="small"
                      type="secondary"
                      onClick={() => {
                        setShowSave(false);
                        setShowFilterName(false);
                      }}
                    />
                  )}
                </div>
              </InlineLabel>
            </div>
          )}
          <div className="d-flex pointer" onClick={() => setShow((prev) => !prev)}>
            {show ? <img src={downFill} className="me-2" /> : <img src={sideFill} className="me-2" />}
            <Heading level={isClientPage ? 4 : 3}>Filter by fields</Heading>
          </div>
          <div className={`${show ? 'd-block' : 'd-none'}`}>
            <div className="ps-3 pe-3 pt-3">
              {fieldFilters &&
                fieldFilters?.map((f, key) => {
                  return (
                    <>
                      <div className="d-flex gap-3 mb-2">
                        <div style={{ width: '500px' }}>
                          <Checkbox label={f.name} checked={f.checked} onChange={(e) => changeHandler('checked', e, key)} />
                        </div>

                        <FormField
                          type={'select'}
                          size="small"
                          name={'type'}
                          value={f.type}
                          onChange={(e) => changeHandler('type', e, key)}
                        >
                          <option value={'is'}>is</option>
                          <option value={'isnt'}>isn't</option>
                          <option value={'contains'}>contains</option>
                          <option value={'doesnt contains'}>doesn't contains</option>
                          <option value={'starts with'}>starts with</option>
                          <option value={'ends with'}>ends with</option>
                          <option value={'is empty'}>is empty</option>
                          <option value={'is not empty'}>is not empty</option>
                        </FormField>

                        <FormField
                          placeholder={'Words...'}
                          type={'text'}
                          size="small"
                          value={f.value}
                          onChange={(e) => changeHandler('value', e, key)}
                        />
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="ms-3 mt-2">
              {apply ? <Spinner size={'base'} /> : <Button label="Apply Filters" onClick={applyFilterHandler} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Filters;
