export const toggleColumn = (setVisibleColumns, columnName, tableSelector) => {
  setVisibleColumns((prev) => ({
    ...prev,
    [columnName]: !prev[columnName]
  }));

  setTimeout(() => {
    try {
      $(tableSelector).resizableColumns('destroy').resizableColumns();
    } catch (error) {
      console.error('Error toggling column:', error);
    }
  }, 500);
};

export const initialzeTable = async (setDragtableState, localStorageName, tableSelector) => {
  const localSettings = JSON.parse(localStorage.getItem(localStorageName));
  console.log(localSettings, localStorageName, tableSelector)
  try {
    $(tableSelector).dragtable({
      dragHandle: '.drag__handle',
      persistState: function (table) {
        table.el.find('th').each(function (i) {
          if (this.id != '') {
            table.sortOrder[this.id] = i;
          }
        });
        setDragtableState(table.sortOrder);
      },
      restoreState: localSettings?.columnOrder,
      dragaccept: '.accept:not(.notAccept)'
    });
  } catch (e) {
    console.log(e)
  }
};

export const reinitialzeDragTable = (tableSelector) => {
  setTimeout(() => {
    try {
      $(tableSelector).resizableColumns('destroy').resizableColumns({
        store: window.store
      });
    } catch {}
  }, 500);
};

export const getIconStyle = (key, direction, sortConfig) => {
  return sortConfig.key === key && sortConfig.direction === direction ? 'fs-5 text-dark' : 'fs-5 text-muted opacity-25';
};

export function calculateDifferences(array1, array2) {
  const differences = array1.map((item1, index) => {
    const item2 = array2[index]; // Assuming corresponding objects are at the same index

    // Initialize an object to store the differences
    let difference = {};
    console.log(item1, item2);

    // Calculate differences if the keys match your criteria
    if ('totalCost' in item1 && 'groupTotalCost' in item2) {
      difference['amountCost'] = item2.groupTotalCost - item1.totalCost;
    }
    if ('amountPaid' in item1 && 'groupAmountPaid' in item2) {
      difference['amountPaid'] = item2.groupAmountPaid - item1.amountPaid;
    }
    if ('amountOwed' in item1 && 'groupAmountOwed' in item2) {
      difference['totalOwed'] = item2.groupAmountOwed - item1.amountOwed;
    }

    return difference;
  });

  // Reduce the array of differences into a single object, summing up the values of matching keys
  const totalDifferences = differences.reduce((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      // If the key already exists in the accumulator, add the current value to it
      // Otherwise, initialize it with the current value
      acc[key] = (acc[key] || 0) + curr[key];
    });
    return acc;
  }, {});

  return totalDifferences;
}

export const useUserQuotesSettings = (setVisibleColumns, setDragtableState, navigate, localStorageId) => {
  let localSettings = localStorage.getItem(localStorageId);
  console.log(localSettings)
  if (!localSettings) {
    localStorage.removeItem('authUser');
    navigate('/login');
  } else {
    localSettings = JSON.parse(localSettings);
    setVisibleColumns(localSettings?.columnVisibility);
    setDragtableState(localSettings?.columnOrder);
    Object.entries(localSettings?.columnWidths)?.forEach(([key, value]) => {
      localStorage.setItem(`quotes-${key}`, value);
    });
  }
};

export const getColWidthData = (widthData) => {
  let colWidths = {};
  const width = widthData;
  width.forEach((w) => {
    let temp = localStorage.getItem(`quotes-${w}`);
    if (temp === null || temp === '' || temp == 'null') {
      colWidths[w] = 5;
    } else {
      colWidths[w] = temp;
    }
  });
  return colWidths;
};

export const reloadFunc = (setShowHeader) => {
  setShowHeader(false);
  setTimeout(() => {
    setShowHeader(true);
  }, 10);
};

export function makeElementStickyOnScroll(selector, threshold, onStickyChange) {
  const handleScroll = () => {
    const stickyElement = document.querySelector(selector);
    if (stickyElement) {
      const offsetTop = stickyElement.getBoundingClientRect().top;
      onStickyChange(offsetTop <= threshold);
    }
  };

  // Attach scroll event listener
  window.addEventListener('scroll', handleScroll);

  // Return a cleanup function to remove the event listener
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}

export const handleSort = (key, sortConfig, setSortConfigCallback, updateFilterCallback) => {
  updateFilterCallback('sort_key', key);
  if (sortConfig?.key === key && sortConfig?.direction === 'ASCENDING') {
    setSortConfigCallback({ key, direction: 'DESCENDING' });
    updateFilterCallback('sort_direction', 'DESCENDING');
  } else {
    setSortConfigCallback({ key, direction: 'ASCENDING' });
    updateFilterCallback('sort_direction', 'ASCENDING');
  }
};

export const getTableSelector = (curentTabLink) => {
    if(curentTabLink == 'page') return '#quotes__table'
    else if(curentTabLink == 'job-page') return '#quotes__table'
    else if(curentTabLink == 'converted-jobs') return '#jobs__table'
    else if(curentTabLink == 'jobs') return '#jobs__table'
};
export const getLocalStorageSelector = (curentTabLink) => {
    if(curentTabLink == 'page') return 'userQuotesSettings'
    else if(curentTabLink == 'job-page') return 'approvedQuotesSettings'
    else if(curentTabLink == 'converted-jobs') return 'jobSettings'
    else if(curentTabLink == 'jobs') return 'jobSettings'
};
