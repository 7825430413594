import React, { useContext, useEffect, useState } from 'react';
import { useNotesContext } from '../contextAPI/NotesContext';
import { Button } from '@jobber/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { SocketContext } from '../../../SocketProvider';
import { ProgressBar } from 'react-bootstrap';
import { getFromServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import { useNotesFilter } from '../FilterQuery/NotesFilterQuery';
import { useLocation } from 'react-router';
import { setStartSyncR } from '../../../store/layoutSlice';

function TopButtons() {
	const dispatch = useDispatch();
	const { triggerSyncClient, triggerSettingsBar } = useNotesContext();
	// const dispatch = useDispatch();
	const { socket, socketHandler } = useContext(SocketContext);
	const { addFilterList } = useNotesFilter();
	const [comingMsg, setComingMsg] = useState(null);
	const [progressValue, setProgressValue] = useState(1);
	const [toastShown, setToastShown] = useState(false);
	const [AIError, setAIError] = useState(null);

	const userStore = useSelector((store) => store.user);
	const user = userStore?.user?.data;

	// for header button sync
	const location = useLocation();
	useEffect(() => {
		if (location?.state?.sync) {
			syncClientHandler();
			window.history.replaceState({}, '');
		}
	}, [location]);
	// for header button sync  end

	useEffect(() => {
		if (comingMsg && !loading) {
			setLoading(true);
		}
		if (comingMsg) {
			setProgressValue(comingMsg?.progress);
		}
	}, [comingMsg]);

	useEffect(() => {
		let timer = null;

		if (AIError && !toastShown) {
			showToast({
				message: AIError?.message,
				variation: 'error'
			});
			setToastShown(true);

			// Reset toastShown state after 5 seconds (5000 milliseconds)
			timer = setTimeout(() => {
				setToastShown(false);
			}, 5000);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [AIError, toastShown]);

	useEffect(() => {
		if (!socket) {
			const newSocket = io(process.env.REACT_APP_API_URL);
			socketHandler(newSocket);
		}

		if (socket) {
			socket.on(`Genie_notes_progress_${user?._id}`, (notify) => {
				setComingMsg(notify);
			});

			socket.on(`Genie_AI_note_${user?._id}`, (notify) => {
				console.log('error called', notify);
				setAIError(notify);
			});

			socket.on('error', (errorMessage) => {
				console.error(errorMessage);
			});

			socket.on('connect', (successMessage) => {
				console.log('socket connected');
			});
		}
	}, [socket]);

	const joinRoom = async () => {
		if (socket && user?._id) {
			await socket.emit('joinGenieRoom', { userId: user?._id });
		}
	};

	useEffect(() => {
		joinRoom();
	}, [user?._id, socket]);

	const [loading, setLoading] = useState(false);
	const syncClientHandler = async () => {
		triggerSyncClient();
		setLoading(true);
		dispatch(setStartSyncR(true));
		const result = await getFromServer('sync-client-notes');
		if (result.status) {
			showToast({
				message: 'Sync successfully Completed'
			});
			setProgressValue(1);
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setLoading(false);
		addFilterList({});
	};

	return (
		<div className='d-flex'>
			{loading ? (
				<div className="mb-2">
					<ProgressBar animated variant="success" label={`${progressValue}%`} now={progressValue} />
					<p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }} className="mt-1">
						Do not perform any operation, while we are syncing
					</p>
				</div>
			) : (
				<>
					<Button label={'Sync'} onClick={syncClientHandler} />
				</>
			)}

			<span className="ms-2"></span>
			<Button label={'Filter and Settings'} type={'secondary'} onClick={triggerSettingsBar} />
		</div>
	);
}

export default TopButtons;
