import { Heading } from "@jobber/components/Heading";
import React, { useEffect, useState } from "react";
import { putToServer } from "./requests";
import { showToast } from "@jobber/components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { setUserTags } from "../../store/tagsSlice";

const EditableText = ({ initialText,des, id, isEditing, cancelEditing, setSaveHandler, setLoading, save, onClick }) => {
  const dispatch = useDispatch();
  const tagSlice = useSelector((store) => store.tag);
  const [text, setText] = useState(initialText);
  const [desInput, setDes] = useState(des);
  // const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  useEffect(()=>{
    if(save == true){
      onBlurhandler()
    }
  },[save])

  // const toggleEdit = () => {
  //   setIsEditing(!isEditing);
  // };

  const onBlurhandler = async () => {
    const result = await putToServer(`tags/${id}`, { categoryName: text, description:desInput });
    if (result.status) {
      cancelEditing()
      setSaveHandler(false)
      setLoading(false)
      showToast({ message: "Category updated", variation: "success" });
      const newData = tagSlice?.userTags?.map((s) => {
        if (s._id === id) return { ...s, categoryName: text, description:desInput };
        else return s;
      });
      dispatch(setUserTags(newData));
    } else {
      showToast({
        message: result.message || result.error,
        variation: "error",
      });
    }
  };

  return (
    <div>
      {isEditing ? (
        <>
        <input
          type="text"
          value={text}
          className="form-control"
          onChange={handleChange}
          autoFocus
          />
        <input
          type="text"
          value={desInput}
          className="form-control mt-2"
          placeholder="Description"
          onChange={(e)=>setDes(e.target.value)}
          />
          </>
      ) : (
        <span onClick={onClick} className="pointer">
          <Heading level={4}>{text}</Heading>
        </span>
      )}
    </div>
  );
};

export default EditableText;
