import React from "react";
import tagsIcon from "../../../assets/icons/tags.svg";
import TagSVG from "./TagSVG";
import { IoClose } from "react-icons/io5";

function TagIcon({ name, count, source, sameTagsClickHandler, excludeTagIdList, includeTagIdList, id }) {
  return (
    <div>
      <TagSVG id={id} text={name} count={count} source={source} sameTagsClickHandler={sameTagsClickHandler} excludeTagIdList={excludeTagIdList} includeTagIdList={includeTagIdList} />
    </div>
  );
}

export default TagIcon;
