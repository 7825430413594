import { showToast } from "@jobber/components/Toast";
import React from "react";
import { Navigate, Outlet } from "react-router";

function Protect({ element }) {
  const userProfileSession = JSON.parse(localStorage.getItem("authUser"));
  if (
    userProfileSession === null ||
    (typeof userProfileSession === "object" &&
      !("access" in userProfileSession)) ||
    !("data" in userProfileSession)
  ) {
    return <Navigate to={"/login"} />;
  } else if (!userProfileSession?.data?.isConnected){
    showToast({
      message: "Please connect your jobber account to continue",
      variation: "error",
    });
    localStorage.removeItem('authUser')
    return <Navigate to={"/login"} />
  }else {
    return element;
  }
}

export default Protect;
