import React from 'react'
import YearPicker from '../YearPicker/YearPicker'
import Filters from '../Filters/Filters'
import Titles from '../Titles/Titles'

export default function Header({ getYear, getTags, getTitles }) {
  return (
    <div className='d-flex gap-3'>
      <YearPicker getYear={getYear} />
      <Filters getTags={getTags} />
      <Titles getTitles={getTitles} />
    </div>
  )
}
