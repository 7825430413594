import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import TableTabs from './TableTabs';
import { Col, Dropdown, DropdownItem, Row } from 'react-bootstrap';
import { InputText } from '@jobber/components/InputText';
import { setSearchR } from '../../../store/AdvanceQuotes/QuotesTable';
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdSave } from 'react-icons/io';
import { Glimmer } from '@jobber/components/Glimmer';
import { Heading } from '@jobber/components/Heading';
import { useLocation, useNavigate } from 'react-router';
import {
	getColWidthData,
	getIconStyle,
	handleSort,
	makeElementStickyOnScroll,
	reloadFunc,
	toggleColumn,
	useUserQuotesSettings
} from '../../../utils/manageTable';
import { Button } from '@jobber/components/Button';
import { Checkbox } from '@jobber/components/Checkbox';
import { Spinner } from '@jobber/components/Spinner';
import { showToast } from '@jobber/components/Toast';
import { deleteFromServer, postToServer } from '../../../components/Common/requests';
import TopButtons from '../../Jobs/Buttons/TopButtons';
import { formatJobberDateTime, formatYearDateTimeDay } from '../../../components/Common/Validations';
import { useAutomationFilters } from '../FilterQuery/AutomationFilterQuery';
import { useVisitFilters } from '../../AdvanceVisits/FilterQuery/VisitFilterQuery';
import RibbonsTable from '../../AdvanceVisits/Table/RibbonsTable';
import JobsRibbonsTable from './JobsRibbonsTable';
import { useJobContext } from '../contextAPI/JobContext';
import FailedTaskModal from '../../../components/FailedTaskModal';

function ReopenButton({ row, updateData, openModal, setFailedData }) {
	const [reLoading, setReLoading] = useState(false);
	const reOpenHandler = async () => {
		setReLoading(true);
		const result = await postToServer(`reopen-job/${row?.jobId}/${row?._id}`);
		if (result.status) {
			updateData(row, 'remove');
			showToast({
				message: 'Job reopended successfully'
			});
		} else {
			setFailedData(row);
			openModal(true);

			let errorMessage = 'An error occurred.';

			if (result.message && typeof result.message === 'string') {
				errorMessage = result.message;
			} else if (result.error && typeof result.error === 'string') {
				errorMessage = result.error;
			}

			showToast({
				message: errorMessage,
				variation: 'error'
			});
		}
		setReLoading(false);
	};
	return <td>{reLoading ? <Spinner /> : <Button label="Reopen Job" onClick={reOpenHandler} type="secondary" size="small" />}</td>;
}

function DeleteButton({ url, row, updateData, msg }) {
	const [reLoading, setReLoading] = useState(false);
	const deleteHandler = async () => {
		setReLoading(true);
		const result = await deleteFromServer(url);
		if (result.status) {
			showToast({
				message: msg
			});
			updateData(row, 'remove');
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setReLoading(false);
	};
	return (
		<td>
			{reLoading ? <Spinner /> : <Button icon="trash" onClick={deleteHandler} variation="destructive" type="secondary" size="small" />}
		</td>
	);
}

function ConvertedJobsTable({
	columnsStateData,
	columnsHeader,
	columnsWidth,
	sync,
	visits = false,
	search,
	setSearch,
	loading,
	data,
	dragtableState,
	setDragtableState,
	updateData,
	currentPage
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [modalOpen, setModalOpen] = useState(false)
	const [failedData, setFailedData] = useState()

	const { updateFilter } = visits ? useVisitFilters() : useAutomationFilters();

	// multi row selection
	const { selectedIds, setSelectedIds } = visits ? {} : useJobContext();
	const [isAllSelected, setIsAllSelected] = useState(false);
	const handleRowCheck = (id, isChecked) => {
		if (isChecked) {
			if (!selectedIds.includes(id)) {
				setSelectedIds([...selectedIds, id]);
			}
		} else {
			setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
		}
	};

	const handleMasterCheck = (isChecked) => {
		console.log(isChecked);
		setIsAllSelected(isChecked);
		if (isChecked) {
			const allIds = data.map((item) => item.jobId);
			setSelectedIds((prevIds) => [...prevIds, ...allIds.filter((id) => !prevIds.includes(id))]);
		} else {
			setSelectedIds([]);
		}
	};

	useEffect(() => {
		const isChecked = data?.every((d) => selectedIds?.includes(d.jobId));
		if (isChecked && data?.length > 0) {
			setIsAllSelected(true);
		} else {
			setIsAllSelected(false);
		}
	}, [selectedIds, data]);

	// end ________________ multirow selection

	// dropdown
	const [showDropdown, setShowDropdown] = useState(false);
	const dropdownToggleRef = useRef(null);

	const toggleDropdown = () => {
		setShowDropdown(!showDropdown);
	};

	// column visibility
	const [visibleColumns, setVisibleColumns] = useState(columnsStateData);
	const [showHeader, setShowHeader] = useState(true);
	useEffect(() => {
		reloadFunc(setShowHeader);
	}, [currentPage, visits]);

	// sorting
	const [sortConfig, setSortConfig] = useState({
		key: null,
		direction: 'ASCENDING'
	});
	const onSort = (key) => {
		handleSort(key, sortConfig, setSortConfig, updateFilter);
	};

	useEffect(() => {
		useUserQuotesSettings(setVisibleColumns, setDragtableState, navigate, visits ? 'visitSettings' : 'jobSettings');
	}, [navigate, location]);

	const [saveLoader, setSaveLoader] = useState(false);
	const saveUserSettingsHandler = async () => {
		const body = {
			columnOrder: dragtableState,
			columnVisibility: visibleColumns,
			columnWidths: getColWidthData(columnsWidth)
		};
		setSaveLoader(true);
		const result = await postToServer(visits ? 'visitjob-table-settings' : 'job-table-settings', body);
		if (result.status) {
			localStorage.setItem(visits ? 'visitSettings' : 'jobSettings', JSON.stringify(body));
			showToast({
				message: 'Settings updated successfully'
			});
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setSaveLoader(false);
	};

	// sticky
	const [isSticky, setIsSticky] = useState(false);
	useEffect(() => {
		const cleanup = makeElementStickyOnScroll('.check__sticky', 270, setIsSticky);
		return cleanup;
	}, []);

	return (
		<>
			<FailedTaskModal title='Failed to reopen following jobs!' failedData={[failedData]} modalOpen={modalOpen} setModalOpen={setModalOpen} />
			<div>
				<Row>
					<Col md={6}>
						<div className="d-flex gap-3 pt-3" id="searchTerm">
							<InputText
								placeholder="Search"
								size="small"
								autocomplete="off"
								name=""
								value={search}
								onChange={(e) => {
									dispatch(setSearchR(e));
									setSearch(e);
								}}
							/>
							{!visits && (
								<>
									<div className="text-nowrap" style={{ position: 'relative' }}>
										<Button label={'Manage Columns'} ref={dropdownToggleRef} onClick={toggleDropdown} />
										<div style={{ zIndex: '11', position: 'absolute' }}>
											<Dropdown show={showDropdown}>
												<Dropdown.Menu>
													<DropdownItem>Show and Hide Columns</DropdownItem>
													<div className="ms-3">
														{columnsHeader &&
															columnsHeader?.map((c) => {
																return (
																	<>
																		<Checkbox
																			label={c.name}
																			checked={visibleColumns[c.key]}
																			onChange={() => toggleColumn(setVisibleColumns, c.key, 'table')}
																		/>
																		<br />
																	</>
																);
															})}
													</div>
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</div>
									<div className="text-nowrap">
										{saveLoader ? (
											<Spinner size="base" />
										) : (
											<Button label={'Save Settings'} type="secondary" onClick={saveUserSettingsHandler} />
										)}
									</div>
								</>
							)}
						</div>
					</Col>
					<Col md={6} className="text-end pt-3">
						{!visits && (
							<TopButtons
								sync={() => {
									reloadFunc(setShowHeader);
									sync();
								}}
								currentPage={currentPage}
							/>
						)}
					</Col>
				</Row>
				{!visits ? (
					<>
						{selectedIds?.length > 0 && <p className="mt-4 fw-bold">{selectedIds?.length} Items Selected</p>}
						<table className="th--border table mt-4 closedJob" data-resizable-columns-id="jobs" id="jobs__table">
							{showHeader && (
								<thead>
									<tr className={`sticky z-2 check__sticky ${isSticky ? 'tag__category--sticky' : ''}`}>
										<th className="notAccept" id='colCheckbox' style={{ width: '1%' }}>
											<Checkbox label={''} checked={isAllSelected} onChange={(e) => handleMasterCheck(e)} />
										</th>
										{/* column headers  */}
										{columnsHeader &&
											columnsHeader?.map((col, index) => {
												return (
													<th
														key={index}
														data-resizable-column-id={col.colName}
														id={col.colName}
														className={`accept ${visibleColumns[col.key] ? '' : 'd-none'}`}
													>
														{col?.filter ? (
															<div className="d-flex justify-content-between">
																<span className="drag__handle">{col.name}</span>
																<div
																	className="icon-container"
																	onClick={(e) => {
																		e.stopPropagation();
																		onSort(col?.filter);
																	}}
																>
																	<IoMdArrowDropup className={`icon-up ${getIconStyle(col?.filter, 'ASCENDING', sortConfig)}`} />
																	<IoMdArrowDropdown className={`icon-down ${getIconStyle(col?.filter, 'DESCENDING', sortConfig)}`} />
																</div>
															</div>
														) : (
															<div className="drag__handle">{col.name}</div>
														)}
													</th>
												);
											})}
										<th></th>
										<th></th>
									</tr>
								</thead>
							)}
							<tbody>
								{loading
									? Array.from({ length: 2 }, (_, index) => (
										<tr>
											{Array.from({ length: columnsHeader?.length }, (_, index) => (
												<td>
													<Glimmer shape="rectangle" size="base" timing="speed" />
												</td>
											))}
											<td>
												<Glimmer shape="rectangle" size="base" timing="speed" />
											</td>
										</tr>
									))
									: data &&
									data?.map((row, index) => {
										let cData = visits ? row?.job : row;
										if (cData?.visitSchedule?.startDate) {
											return (
												<tr className="pointer">
													<td className='notAccept'>
														<Checkbox label={''} checked={selectedIds.includes(row.jobId)} onChange={(e) => handleRowCheck(row.jobId, e)} />
													</td>
													<td className={`${visibleColumns.clientName ? '' : 'd-none'}`}>
														<Heading level={5}>{cData?.client?.name}</Heading>
														<p style={{ color: 'var(--muted)' }}>{cData?.client?.secondaryName}</p>
													</td>
													<td className={`${visibleColumns.title ? '' : 'd-none'}`}>
														<Heading level={5}>{cData?.title}</Heading>
													</td>
													<td className={`${visibleColumns.instructions ? '' : 'd-none'}`}>{cData?.instructions}</td>
													<td className={`${visibleColumns.propert ? '' : 'd-none'}`}>
														{cData?.property?.address?.street} {cData?.property?.address?.postalCode}
													</td>
													<td className={`${visibleColumns.invoicing ? '' : 'd-none'}`}>
														{formatYearDateTimeDay(row?.visitSchedule?.startDate)}

														{/* {cData?.invoiceSchedule?.billingFrequency} <br />
														{cData?.invoiceSchedule?.scheduleSummary} */}
													</td>
													<td className={`${visibleColumns.status ? '' : 'd-none'}`}>
														{row?.jobStatus === 'archived' ? (
															<span className="badge bg-secondary-subtle text-secondary">{'Archieved'}</span>
														) : row?.jobStatus === 'requires_invoicing' ? (
															<span className="badge bg-danger-subtle text-danger">{'Requires Invoicing'}</span>
														) : (
															<span className="badge bg-success-subtle text-success">{row?.jobStatus}</span>
														)}
													</td>
													<td className={`${visibleColumns.type ? '' : 'd-none'}`}>
														{row?.jobType === 'RECURRING' ? (
															<div className="badge text-bg-success">{'Recurring'}</div>
														) : row?.jobType === 'ONE_OFF' ? (
															<div className="badge text-bg-info">{'One Off'}</div>
														) : (
															<div className="badge text-bg-primary">{row?.jobType}</div>
														)}
													</td>
													<td className={`${visibleColumns.total ? '' : 'd-none'}`}>${cData?.total}</td>
													{visits && (
														<td className={`${visibleColumns.schedule ? '' : 'd-none'}`}>
															{formatYearDateTimeDay(cData?.visitSchedule?.startDate)}
															<b>{' to '}</b>
															{formatYearDateTimeDay(cData?.visitSchedule?.endDate)}
														</td>
													)}
													<td className={`${visibleColumns.createdAt ? '' : 'd-none'}`}>
														{cData?.jobCreatedAt ? formatYearDateTimeDay(cData?.jobCreatedAt) : '-'}
													</td>
													<td className={`${visibleColumns.updatedAt ? '' : 'd-none'}`}>
														{cData?.jobUpdatedAt ? formatYearDateTimeDay(cData?.jobUpdatedAt) : '-'}
													</td>
													<td className={`${visibleColumns.closedAt ? '' : 'd-none'}`}>
														{cData?.completedAt ? formatYearDateTimeDay(cData?.completedAt) : '-'}
													</td>

													{visits ? (
														<>
															<DeleteButton
																msg={'Visit deleted successfully'}
																url={`visit/${row?._id}`}
																row={row}
																updateData={updateData}
															/>
														</>
													) : (
														<>
															{currentPage == 'jobs' && <ReopenButton row={cData} openModal={setModalOpen} updateData={updateData} setFailedData={setFailedData} />}
															<DeleteButton
																msg={'Job deleted successfully'}
																url={`job/${cData?._id}`}
																row={cData}
																updateData={updateData}
															/>
														</>
													)}
												</tr>
											);
										}
									})}
							</tbody>
						</table>
						{/* <JobsRibbonsTable data={data} updateData={updateData} currentPage={currentPage} /> */}
					</>
				) : (
					<RibbonsTable data={data} loading={loading} />
				)}
			</div>
		</>
	);
}

export default ConvertedJobsTable;
