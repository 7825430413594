import React, { createContext, useContext, useState } from 'react';
import { getCompleteDateTimeFromTime, getISODate } from '../../../components/Common/Validations';

const VisitContext = createContext();

export const useVisitContext = () => useContext(VisitContext);

export const VisitProvider = ({ children }) => {
	const [users, setUsers] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);

	return <VisitContext.Provider value={{ users, setUsers, selectedUsers, setSelectedUsers }}>{children}</VisitContext.Provider>;
};
