import React, { useEffect, useRef, useState } from "react";
import TagCloudCategory from "./TagCloudCategory";
import { Content } from "@jobber/components/Content";
import { Card } from "@jobber/components/Card";
import { Button } from "@jobber/components/Button";

function Offcanvas({
  tagCloudCategoryHandler,
  tagCloudCategory,
  tagCategoryReload,
  setTagCategoryReload,
}) {
  const dropdownRef = useRef(null);
  const [isPageLoad, setPageLoad] = useState(true);
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       tagCloudCategoryHandler(false);
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [dropdownRef]);

  useEffect(() => {
    setTagCategoryReload(false);
    setTimeout(() => {
      setTagCategoryReload(true);
    }, 1);
  }, [tagCloudCategory])

  return (
    <div
      class={`offcanvas hide__scrollbar offcanvas-end ${tagCloudCategory ? "show" : ""
        }`}
      ref={dropdownRef}
      data-backdrop="static"
      tabindex="-1"
    >
      <div class="offcanvas-body">
        <Card
          header={{
            title: "Tag Cloud Category",
            action: <Button label="X" onClick={tagCloudCategoryHandler} />,
          }}
        >
          <Content>
            {tagCategoryReload ? (
              <TagCloudCategory
                isPageLoad={isPageLoad}
                setPageLoad={setPageLoad}
                tagCloud={tagCloudCategory}
                reload={() => {
                  setTagCategoryReload(false);
                  setTimeout(() => {
                    setTagCategoryReload(true);
                  }, 1);
                }}
              />
            ) : (
              ""
            )}
          </Content>
        </Card>
      </div>
    </div>
  );
}

export default Offcanvas;
