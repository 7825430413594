import React, { createContext, useContext, useState } from 'react';
import { getCompleteDateTimeFromTime, getISODate } from '../../../components/Common/Validations';

const JobContext = createContext();

export const useJobContext = () => useContext(JobContext);

export const JobProvider = ({ children }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [oneOffJob, setOneOffJob] = useState(false);
  const [instruction, setInstruction] = useState('');

  // one off job data
  const [disabled, setDisabled] = useState({ startDate: false, endDate: false, startTime: false, endTime: false });
  const [date, setDate] = useState({ startDate: new Date(), endDate: undefined });
  const [time, setTime] = useState({ startTime: undefined, endTime: undefined });
  const [scheduleLater, setScheduleLater] = useState(false);
  const [unscheduleVisit, setUnscheduleVisit] = useState(true);
  const [emailTeam, setEmailTeam] = useState(true);
  const [invoiceReminder, setInvoiceReminder] = useState(true);
  const [oneOffRepeat, setOneOffRepeat] = useState('null');

  const handleTimeChange = (newTime, name) => {
    setTime({ ...time, [name]: newTime });
  };
  const handleDateChange = (newDate, name) => {
    setDate({ ...date, [name]: newDate });
  };
  const handleSheduleLaterChange = (e) => {
    setScheduleLater(e);
    setDisabled({ startDate: e, endDate: e, startTime: e, endTime: e });
  };

  // recurring data
  const [invoiceType, setInvoiceType] = useState('VISIT_BASED');
  const [invoiceTime, setInvoiceTime] = useState('NEVER');
  const [invoiceTimeRecurrence, setInvoiceTimeRecurrence] = useState(null);
  const [showCustomBox, setShowCustomBox] = useState(false);
  const [repeat, setRepeat] = useState('null');
  const [duration, setDuration] = useState({ days: 6, duration: 'MONTHS' });
  const [recurringDate, setRecurringDate] = useState(new Date());
  const [recurringTime, setRecurringTime] = useState({ startTime: null, endTime: null });
  const handleRecurringTimeChange = (newTime, name) => {
    setRecurringTime({ ...recurringTime, [name]: newTime });
  };
  const handleRepeat = (val) => {
    if (val == 'custom') {
      setShowCustomBox(true);
    } else {
      setShowCustomBox(false);
    }
    setRepeat(val);
  };
  const handleDurationChange = (val, name) => {
    setDuration({ ...duration, [name]: val });
  };
  const handleInvoiceTime = (val) => {
    if (val.includes('PERIODIC')) {
      let values = val?.split('__');
      setInvoiceTime('PERIODIC');
      setInvoiceTimeRecurrence(values?.[1]);
    } else {
      setInvoiceTime(val);
      setInvoiceTimeRecurrence(null);
    }
  };
  const handleCustomBoxChange = (val) => {
    if (!val) {
      setRepeat('weekly');
    }
    setShowCustomBox(val);
  };

  const saveHandler = async () => {
    let body = {};
    if (oneOffJob) {
      if (!scheduleLater) {
        if (!date.startDate) {
          return 'Start date is required';
        } else if (!time.startTime || !time.endTime) {
          return 'Time is required';
        }
      }
      let isRepeat = date.endDate ? new Date(date.endDate).toDateString() != new Date(date.startDate).toDateString() : false;
      body = {
        data: {
          scheduling: {
            visitConfirmationStatus: unscheduleVisit,
            notifyTeam: unscheduleVisit,
            recurrence: oneOffRepeat == 'null' ? null : oneOffRepeat,
            startTime: isRepeat
              ? oneOffRepeat == 'null'
                ? null
                : getCompleteDateTimeFromTime(time.startTime)
              : getCompleteDateTimeFromTime(time.startTime),
            endTime: isRepeat
              ? oneOffRepeat == 'null'
                ? null
                : getCompleteDateTimeFromTime(time.endTime)
              : getCompleteDateTimeFromTime(time.endTime)
          },
          invoicing: {
            invoicingType: invoiceReminder ? 'ON_COMPLETION' : 'NEVER',
            recurrence: null
          },
          trackingOrigin: 'WEB',
          instructions: instruction,
          timeframe: {
            startAt: getISODate(date.startDate),
            endAt: date.endDate ? getISODate(date.endDate) : null
          }
        },
        selectedIds
      };
      if (isRepeat && oneOffRepeat == 'null') {
        delete body.data.scheduling?.startTime;
        delete body.data.scheduling?.endTime;
      }
      if (!date.endDate) {
        delete body.data.timeframe?.endAt;
      }
      if (scheduleLater) {
        delete body.data.scheduling?.startTime;
        delete body.data.scheduling?.endTime;
        delete body.data.timeframe?.startAt;
        delete body.data.timeframe?.endAt;
      }
    } else {
      if (repeat != 'null') {
        if (!recurringTime.startTime || !recurringTime.endTime) {
          return 'Time is required';
        }
      }
      if (!recurringDate) {
        return 'Date is required';
      } else if (!duration.days) {
        return 'Duration value is required';
      }
      body = {
        data: {
          scheduling: {
            createVisits: repeat == 'null' ? false : true,
            notifyTeam: repeat == 'null' ? false : true,
            visitConfirmationStatus: false,
            recurrence: repeat == 'null' ? null : repeat,
            startTime: repeat == 'null' ? null : getCompleteDateTimeFromTime(recurringTime.startTime),
            endTime: repeat == 'null' ? null : getCompleteDateTimeFromTime(recurringTime.endTime)
          },
          invoicing: {
            invoicingType: invoiceType,
            invoicingSchedule: invoiceTime,
            recurrence: invoiceTimeRecurrence
          },
          trackingOrigin: 'WEB',
          instructions: instruction,
          timeframe: {
            startAt: getISODate(recurringDate),
            durationUnits: duration.duration,
            durationValue: parseInt(duration.days)
          }
        },
        selectedIds
      };
      if (repeat == 'null') {
        delete body.data.scheduling.visitConfirmationStatus;
        delete body.data.scheduling.recurrence;
        delete body.data.scheduling.startTime;
        delete body.data.scheduling.endTime;
      }
    }
    console.log(body);
    return body;
  };

  return (
    <JobContext.Provider
      value={{
        disabled,
        date,
        time,
        scheduleLater,
        unscheduleVisit,
        handleSheduleLaterChange,
        handleDateChange,
        handleTimeChange,
        setUnscheduleVisit,
        emailTeam,
        setEmailTeam,
        invoiceReminder,
        setInvoiceReminder,
        recurringDate,
        instruction,
        setInstruction,
        setRecurringDate,
        recurringTime,
        handleRecurringTimeChange,
        repeat,
        handleRepeat,
        duration,
        handleDurationChange,
        showCustomBox,
        setShowCustomBox,
        handleCustomBoxChange,
        invoiceType,
        setInvoiceType,
        invoiceTime,
        handleInvoiceTime,
        oneOffJob,
        setOneOffJob,
        saveHandler,
        oneOffRepeat,
        setOneOffRepeat,
        selectedIds,
        setSelectedIds
      }}
    >
      {children}
    </JobContext.Provider>
  );
};
