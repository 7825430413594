export function validatePassword(password) {
	const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).+$/;
	return regex.test(password);
}

export const validatePasswordChar = (passwordValue) => {
	return (
		validatePassword(passwordValue) ||
		'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
	);
};

export function isValidEmail(email) {
	const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
	return regex.test(email);
}

export const isImage = (name) => {
	const extName = name?.split('.')?.[1];
	return extName == 'png' || extName == 'jpg' || extName == 'jpeg';
};

export function checkStatus(item) {
	if (!'isArchived' in item && !'isLead' in item) return 'All';
	else if (item.isArchived === true) return 'Archive';
	else if (item.isLead === true) return 'Lead';
	else if (item.isLead === false && item.isArchived == false) return 'Active';
}

export function formatDateTimeDay(isoDateString) {
	const date = new Date(isoDateString);

	// Format: "Day, Time, Date"
	// Example: "Thursday, 1:02 AM, 28 Dec"
	return date.toLocaleString('en-US', {
		// weekday: "long",
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
		day: 'numeric',
		month: 'short'
	});
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export function formatYearDateTimeDay(isoDateString) {
	const date = new Date(isoDateString);
	// Get year, month, and date components (with zero-padding for month)
	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate().toString().padStart(2, '0');

	return `${year}, ${months[month]} ${day}`;
}

export function getISODate(dateString) {
	const date = new Date(dateString);
	const isoDate = date.toISOString().substring(0, 10);
	return isoDate;
}

export function getCompleteDateTimeFromTime(civilTime) {
	const now = new Date();
	const currentDate = now.toISOString().substring(0, 10); // YYYY-MM-DD

	const combinedDateTime = currentDate + 'T' + civilTime + 'Z';
	return combinedDateTime;
}

export function formatJobberDateTime(dateString) {
  const options = { 
    year: 'numeric', 
    month: 'short', 
    day: '2-digit',
    timeZone: 'America/Toronto'
};

const date = new Date(dateString);
return new Intl.DateTimeFormat('en-US', options).format(date);
}

// export function formatJobberDateTime(dateString) {
//   const options = { 
//       year: 'numeric', 
//       month: 'short', 
//       day: '2-digit',
//       hour: '2-digit', 
//       minute: '2-digit', 
//       hour12: false,
//       timeZone: 'America/Toronto'
//   };

//   const date = new Date(dateString);
//   return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', '');
// }

export function formatDateYearDay(isoDateString) {
	const date = new Date(isoDateString);

	// Get year, month, and date components
	const year = date.getFullYear();
	const month = date.toLocaleString('en-US', { month: 'short' });
	const day = date.getDate();

	// Construct the date string as "Year, Month Date"
	// Example: "2020, Dec 28"
	return `${month} ${day}, ${year}`;
}

// export function getCurrentDateAdjustedForTimezone() {
//   // Get the current date and time
//   const now = new Date();

//   // Adjust the date to midnight in local time
//   now.setHours(0, 0, 0, 0);

//   // Calculate the timezone offset for Pakistan Standard Time (UTC+5)
//   // Note: The offset is in minutes, so we convert it to milliseconds
//   const timezoneOffset = (5 * 60 - now.getTimezoneOffset()) * 60 * 1000;

//   // Adjust the date for the timezone
//   const adjustedDate = new Date(now.getTime() + timezoneOffset);

//   console.log(adjustedDate);
//   return adjustedDate;
// }
// export const formatTime = (input) => {
//   // Extract numbers only and limit to 4 characters (HHMM)
//   const numbers = input.replace(/\D/g, '').substring(0, 4);
//   let hours = numbers.substring(0, 2);
//   let minutes = numbers.substring(2, 4);

//   // Validate hours and minutes
//   if (parseInt(hours, 10) > 23) hours = '23';
//   if (parseInt(minutes, 10) > 59) minutes = '59';

//   // Reformat to HH:MM if both hours and minutes are present
//   return [hours, minutes].filter(Boolean).join(':');
// };

export function addUniqueIds(dataArray) {
	let fid = 0;
	return dataArray.map((item) => ({
		...item,
		id: fid++
	}));
}

export function fixQueryString(query) {
	return query.replace(/&+/g, '&');
}

const filterMappings = {
	name: { typeKey: 'nameFilterType', valueKey: 'nameFilterValue' },
	address: { typeKey: 'addressFilterType', valueKey: 'addressFilterValue' },
	company: { typeKey: 'companyFilterType', valueKey: 'companyFilterValue' },
	email: { typeKey: 'emailFilterType', valueKey: 'emailFilterValue' },
	phone: { typeKey: 'phoneFilterType', valueKey: 'phoneFilterValue' }
};

export function getFilteredDataValues(d) {
	let newTag = {};
	if (d?.limit) newTag['limit'] = [d?.limit];
	if (d?.status) newTag['filter'] = [d?.status];
	if (d?.searchTerm) newTag['searchTerm'] = [d?.searchTerm];
	if (d?.tags?.include?.length > 0) newTag['includeTags'] = d?.tags?.include || [];
	if (d?.tags?.exclude?.length > 0) newTag['excludeTags'] = d?.tags?.exclude || [];
	if (d?.filters.length > 0) {
		d?.filters?.forEach((f) => {
			const mapping = filterMappings[f?.label];
			if (mapping) {
				newTag[mapping.typeKey] = [f?.constraint] || [''];
				newTag[mapping.valueKey] = [f?.value] || [''];
			}
		});
	}
	return newTag;
}

export const formatNumber = (number) => {
	if (Number.isInteger(number)) {
		return number?.toString();
	} else {
		// If not an integer, format to two decimal places
		return number?.toFixed(2);
	}
};

export const getCompleteAddress = (address) => {
	let array = [];
	if (address?.street1) array.push(address.street1);
	if (address?.street2) array.push(address.street2);
	if (address?.city) array.push(address.city);
	if (address?.province) array.push(address.province);
	if (address?.country) array.push(address.country);
	if (address?.postalCode) array.push(address.postalCode);
	return array.join(', ');
};

export function formatNumberWithSpaces(x) {
	// Convert the number to a string
	if (x) {
		let parts = x?.toString()?.split('.');

		// Replace every three digits from the end to the start of the string with the digits followed by a space
		parts[0] = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

		// Rejoin integer and decimal parts
		return parts?.join('.');
	}
}

export const setTemporaryFilters = (filterName, obj) => {
	const currentFilters = localStorage.getItem(filterName) ? JSON.parse(localStorage.getItem(filterName)) : {};
	localStorage.setItem(filterName, JSON.stringify({ ...currentFilters, ...obj }));
};

export function addToLocalStorageArray(KeyName, text) {

  let textArray = JSON.parse(localStorage.getItem(KeyName)) || [];

  // Check if text already exists in the array
  const alreadyExists = textArray.some(item => item === text);

  // Update the array based on existence
  if (!alreadyExists) {
    textArray.push(text);
  } else {
    textArray = textArray.filter(item => item !== text); // Remove duplicate
  }

  localStorage.setItem(KeyName, JSON.stringify(textArray));
}


export function notesActionsBadge(action) {
    switch (action) {
        case 'corrected':
            return 'danger';
        case 'updated':
            return 'success';
        case 'translated':
            return 'info';
        default:
            return 'primary';
    }
}
