import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CiLocationOn } from 'react-icons/ci';
import { HiOutlineStatusOnline } from 'react-icons/hi';
import { formatYearDateTimeDay } from '../../../components/Common/Validations';
import { MdStart } from 'react-icons/md';
import VisitDetails from '../Modal/VisitDetails';
import { useVisitContext } from '../contextAPI/VisitContext';
import { Glimmer } from '@jobber/components/Glimmer';

function RibbonsTable({ data, loading }) {
	const { selectedUsers } = useVisitContext();
	const [detailModal, setDetailModal] = useState(false);
	const [tempData, setTempData] = useState({});
	const selectedLabels = selectedUsers.map((user) => {
		const regex = /\(\d+\)$/;
		return user.label.replace(regex, '').trim();
	});
	return (
		<>
			{detailModal && <VisitDetails open={detailModal} data={tempData} onCancel={() => setDetailModal(false)} />}
			<Row className="gy-2 mb-2 mt-4">
				{loading ? (
					<>
						{Array.from({ length: 4 }, (_, index) => (
							<p>
								<Glimmer shape="rectangle" size="largest" timing="speed" />
							</p>
						))}
					</>
				) : (
					data?.map((row, i) => {
						let cData = row?.jobDetails;
						return (
							<Col lg={12}>
								<Card
									className={`${row?.assignedUsers?.some((user) => selectedLabels.includes(user.name)) ? 'card__after' : 'card__initial'}`}
								>
									<CardBody>
										<div className="d-lg-flex align-items-center">
											{/* <div className="flex-shrink-0">
										<div className="avatar-title rounded-circle bg-light border-dashed border text-primary fs-18 p-2">
											{cData?.client?.name}
										</div>

										<div className="avatar-sm rounded">
											<img src={'asd'} alt="" className="member-img img-fluid d-block rounded"></img>
										</div>
									</div> */}
											<div className="ms-3">
												<div className="fw-bold">
													<h5 className="fs-16 mb-2">{cData?.client?.name}</h5>
												</div>
												<p className="text-muted mb-0">{cData?.title}</p>
											</div>
											<div className="d-flex gap-4 mt-0 text-muted mx-auto">
												<div className="d-flex gap-2 align-items-center">
													<CiLocationOn className="fs-5" />
													{cData?.property?.address?.street} {cData?.property?.address?.postalCode}
												</div>
												<div className="d-flex gap-2 align-items-center">
													<HiOutlineStatusOnline className="fs-5" />
													{row?.visitStatus === 'LATE' ? (
														<span className="badge bg-danger-subtle text-danger">{'LATE'}</span>
													) : row?.visitStatus === 'UPCOMING' ? (
														<span className="badge bg-success-subtle text-success">{row?.visitStatus}</span>
													) : row?.visitStatus === 'Today' ? (
														<span className="badge bg-success-subtle text-info">{row?.visitStatus}</span>
													) : row?.visitStatus === 'COMPLETED' ? (
														<span className="badge bg-success-subtle text-success">{row?.visitStatus}</span>
													) : (
														<span className="badge bg-secondary-subtle text-primary">{row?.visitStatus}</span>
													)}
												</div>
											</div>
											<div className="d-flex flex-wrap gap-2 align-items-center mx-auto">
												{cData?.jobType === 'RECURRING' ? (
													<div className="badge text-bg-success">{'Recurring'}</div>
												) : cData?.jobType === 'ONE_OFF' ? (
													<div className="badge text-bg-info">{'One Off'}</div>
												) : (
													<div className="badge text-bg-primary">{cData?.jobType}</div>
												)}
												<div className="text-muted d-flex gap-2 align-items-center">
													<MdStart />
													{row?.startAt ? formatYearDateTimeDay(row?.startAt) : '-'} - {row?.endAt ? formatYearDateTimeDay(row?.endAt) : '-'}
												</div>
											</div>
											<div>
												<Link
													to="#!"
													className="btn btn-outline-success me-1"
													onClick={() => {
														setTempData(row);
														setDetailModal(true);
													}}
												>
													View Details
												</Link>
												{/* <Link
											to="#!"
											onClick={(e) => {
												e.preventDefault();
												setIsBookmarkClick(!isBookmarkClick);
											}}
											className={
												isBookmarkClick
													? 'btn btn-ghost-danger btn-icon custom-toggle active'
													: 'btn btn-ghost-danger btn-icon custom-toggle'
											}
											data-bs-toggle="button"
										>
											{!isBookmarkClick ? (
												<span className="icon-on">
													<i className="ri-bookmark-line align-bottom"></i>
												</span>
											) : (
												<span className="icon-off">
													<i className="ri-bookmark-3-fill align-bottom"></i>
												</span>
											)}
										</Link> */}
											</div>
										</div>
									</CardBody>
								</Card>
							</Col>
						);
					})
				)}
			</Row>
		</>
	);
}

export default RibbonsTable;
