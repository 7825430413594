import React, { useEffect } from 'react';
import logo from "../../assets/logo.svg";

function Header() {
  useEffect(() => {
    document.body.style.margin = '0';
    document.body.style.padding = '0';
  }, []);

  return (
    <nav className="navbar navbar-light d-flex justify-content-center" style={{ backgroundColor: 'var(--first-color)', height: '5rem' }}>
      <a className="navbar-brand text-light fw-bolder fs-2" href="#">
        <img src={logo} alt="" width="30" height="30" className='mx-3' style={{marginTop:'-7px'}} />
        JOBBER X THE ROYAL GATEWAY
      </a>
    </nav>
  );
}

export default Header;
