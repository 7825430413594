import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    colOrder: {},
    colWidth: {},
    colVisibility: {},
    isJobber: false,
  },
  reducers: {
    setLoginUser(state, action) {
      state.user = action.payload;
    },
    setJobber(state, action) {
      state.isJobber = action.payload;
    },
    setUserSettings(state, action) {
      state.colOrder = action.payload.columnOrder;
      state.colWidth = action.payload.columnVisibility;
      state.colVisibility = action.payload.columnWidths;
    },
  },
});

export const { setLoginUser, setUserSettings, setJobber } = userSlice.actions;
export default userSlice.reducer;
