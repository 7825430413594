import { Button } from '@jobber/components/Button';
import { FormField } from '@jobber/components/FormField';
import React, { useEffect, useState } from 'react';
import { Tooltip } from '@jobber/components/Tooltip';
import { postToServer, putToServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useNotesContext } from '../contextAPI/NotesContext';
import { Checkbox } from '@jobber/components/Checkbox';
import { FaFileLines } from 'react-icons/fa6';
import { FaFileImage } from "react-icons/fa";
import { isImage } from '../../../components/Common/Validations';

function TranslatedNotes({ note, clientId, updateData, filterAction }) {
  const { isAIDiscard, triggerAiDiscarded, triggerCurrentNote, currentNote } = useNotesContext();
  const [attachement, setAttachment] = useState(note.linkedTo || { requests: true, quotes: true, jobs: true, invoices: true });
  const [value, setValue] = useState(note?.translatedMessage?.trim());
  useEffect(() => {
    setValue(note?.translatedMessage?.trim());
  }, [note]);
  const [loading, setLoading] = useState(false);
  const [discardLoading, setDiscardLoading] = useState(false);
  const approveHandler = async () => {
    if (!value) {
      return;
    }
    setLoading(true);
    let params = ''
    if (filterAction.length) {
      params = new URLSearchParams({
        filterAction: filterAction[0].id
      })
    }
    const result = await putToServer(`update-transalte-note/${clientId}/${note?.id}?${params}`, {
      translatedMessage: value,
      linkedTo: attachement,
      isAttachement: note?.noteAttachments?.length > 0
    });
    console.log(result);
    if (result.status) {
      const lastNote = result?.data?.client?.note?.notes[result?.data?.client?.note?.notes.length - 1];
      updateData(lastNote, 'correctedNote');
      showToast({
        message: 'Translated note Updated and added to jobber'
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log(currentNote, note?.id);
    if (isAIDiscard && isAIDiscard?.transaltedMessage && currentNote == note?.id) {
      setValue(isAIDiscard?.transaltedMessage?.trim());
    }
  }, [isAIDiscard]);
  const discardHandler = async () => {
    triggerCurrentNote(note?.id);
    setDiscardLoading(true);
    const result = await postToServer(`translate-note-ai`, { correctedMessage: value });
    if (result.status) {
      setValue(result?.data?.transaltedMessage?.trim());
      triggerAiDiscarded(result?.data);
      showToast({
        message: 'Translated note Updated'
      });
    }
    setDiscardLoading(false);
  };
  return (
    <div className="mb-3">
      <FormField onChange={setValue} rows={5} value={value} placeholder={'Translated Notes'} type={'textarea'} />
      <div className="d-flex gap-2 mt-3 mb-4">
        {note?.noteAttachments?.map((n, i) => {
          return (
            <a href={n?.url} target="_blank">
              {isImage(n?.fileName) ? (
                <div className="attachement text-muted text-center">
                  <FaFileImage className="pointer fs-4" /> <br />
                  <span className="fs-12 truncate-text d-block mt-1">{n?.fileName}</span>
                </div>
              ) : (
                <div className="attachement text-muted text-center">
                  <FaFileLines className="pointer fs-4" /> <br />
                  <span className="fs-12 truncate-text d-block mt-1">{n?.fileName}</span>
                </div>
              )}
            </a>
          );
        })}
      </div>
      <div className="d-flex mt-3 justify-content-between">
        <Row>
          <Col md={6} className="">
            <Checkbox label={'Requests'} checked={attachement.requests} onChange={(e) => setAttachment({ ...attachement, requests: e })} />
            <br />
            <Checkbox label={'Quotes'} checked={attachement.quotes} onChange={(e) => setAttachment({ ...attachement, quotes: e })} />
          </Col>
          <Col md={6} className="">
            <Checkbox label={'Jobs'} checked={attachement.jobs} onChange={(e) => setAttachment({ ...attachement, jobs: e })} />
            <br />
            <Checkbox label={'invoices'} checked={attachement.invoices} onChange={(e) => setAttachment({ ...attachement, invoices: e })} />
          </Col>
        </Row>
        <div className="mt-3 text-end">
          <Tooltip message={'Approve'}>
            {loading ? <Spinner /> : <Button label="" onClick={approveHandler} icon="checkmark" size="small" type="secondary" />}
          </Tooltip>
          <span className="ms-2"></span>
          <Tooltip message={'Retranslate'}>
            {discardLoading ? <Spinner /> : <Button label="" onClick={discardHandler} icon="redo" size="small" type="subtle" />}
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default TranslatedNotes;
