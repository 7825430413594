import { Button } from '@jobber/components/Button';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LockerModal from '../components/Common/LockerModal';
import { deleteFromServer, getFromServer, postToServer } from '../components/Common/requests';
import { Spinner } from '@jobber/components/Spinner';
import { showToast } from '@jobber/components/Toast';
import { SocketContext } from '../SocketProvider';
import io from 'socket.io-client';
import { ProgressBar } from 'react-bootstrap';
import { useJobContext } from '../pages/AdvanceJobs/contextAPI/JobContext';
import { useLocation } from 'react-router';
import FailedTaskModal from '../components/FailedTaskModal';

function LockerButton({
	socketName = false,
	border = false,
	apiUrl,
	successMsg = 'Success',
	btnText = null,
	reload = null,
	method = 'GET',
	className = ''
}) {
	const location = useLocation()
	const user = useSelector((s) => s.user);
	const { socket, socketHandler } = useContext(SocketContext);
	const { selectedIds, setSelectedIds } = location.pathname.includes('job-automation') ? useJobContext() : {};
	const [comingMsg, setComingMsg] = useState(null);
	const [progressValue, setProgressValue] = useState(1);
	const [loading, setLoading] = useState(false);
	const userData = user?.user?.data;

	useEffect(() => {
		if (comingMsg) {
			setProgressValue(comingMsg?.progress);
		}
	}, [comingMsg]);

	useEffect(() => {
		if (socketName) {
			if (!socket) {
				const newSocket = io(process.env.REACT_APP_API_URL);
				console.log(newSocket);
				socketHandler(newSocket);
			}

			if (socket) {
				socket.on(`${socketName}${userData?._id}`, (notify) => {
					setComingMsg(notify);
				});
			}
		}
	}, [socket]);

	const joinRoom = async () => {
		if (socket && userData?._id) {
			await socket.emit('joinGenieRoom', { userId: userData?._id });
		}
	};

	useEffect(() => {
		if (socketName) {
			joinRoom();
		}
	}, [userData?._id, socket]);

	const [lockModal, setLockModal] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [failedData, setFailedData] = useState([])

	const bulkHandler = async () => {
		setDeleteLoading(true);
		setLockModal(false);

		if (socketName) {
			setLoading(true);
		}

		const func = method === 'GET' ? getFromServer : deleteFromServer;
		const result = method === 'POST' ? await postToServer(apiUrl, { selectedIds }) : await func(apiUrl);
		const isErrors = (result.message && Array.isArray(result.message.errors) && result.message.errors.length > 0)
		if (result.status) {
			if (socketName) {
				setLoading(false);
			}

			if (isErrors) {
				setFailedData(result.message.errors);
				setModalOpen(true);
			} else {
				showToast({
					message: successMsg
				});
			}

			if (reload) reload();
		} else {
			if (isErrors) {
				setFailedData(result.message.errors);
				setModalOpen(true);
			} else {
				let errorMessage = 'An error occurred.';

				if (result.message && typeof result.message === 'string') {
					errorMessage = result.message;
				} else if (result.error && typeof result.error === 'string') {
					errorMessage = result.error;
				}

				showToast({
					message: errorMessage,
					variation: 'error'
				});
			}
		}

		if (location.pathname.includes('job-automation')) setSelectedIds([])
		setLoading(false);
		setDeleteLoading(false);
	};


	const [modalOpen, setModalOpen] = useState(false);
	return (
		<>
			<FailedTaskModal title='Failed to reopen following jobs!' failedData={failedData} modalOpen={modalOpen} setModalOpen={setModalOpen} />
			<div className={className}>
				{loading ? (
					<div className="mb-2">
						<ProgressBar animated variant="success" label={`${progressValue}%`} now={progressValue} />
						<p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }} className="mt-1">
							Do not perform any operation, while we are working
						</p>
					</div>
				) : (
					<>
						{deleteLoading ? (
							<Spinner />
						) : (
							<Button
								variation={method == 'DELETE' ? 'destructive' : 'work'}
								label={btnText || 'Delete'}
								type={!border ? 'primary' : 'secondary'}
								onClick={user?.user?.data?.isPasswordCreated ? () => setLockModal(true) : bulkHandler}
							/>
						)}
					</>
				)}
				<LockerModal open={lockModal} onConfirm={bulkHandler} onCancel={() => setLockModal(false)} />
			</div>
		</>
	);
}

export default LockerButton;
