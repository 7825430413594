import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Page from '../Pages/Page';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { useLocation } from 'react-router-dom';
import useQuery from '../../../utils/useQuery';
const automationTabs = [
  { id: 1, param: 'approved-quotes', name: 'Approved Quotes', link: 'job-page' },
  // { id: 2, param: 'converted-jobs', name: 'Jobs', link: 'converted-jobs' },
  { id: 3, param: 'closed-jobs', name: 'Closed Jobs', link: 'jobs' }
];

function TableTabs({ tabChangeHandler }) {
  const query = useQuery();
  const location = useLocation()
  const [tab, setTab] = useState('job-page');
  useEffect(() => {
    let tab = query.get('tab');
    if (tab && tab == 'approved-quotes') setTab('job-page');
    else if (tab && tab == 'closed-jobs') setTab('jobs');
    else if (tab && tab == 'converted-jobs') setTab('converted-jobs');
    if (!tab) setTab('job-page');
  }, [query, location])

  let [searchParams, setSearchParams] = useSearchParams();
  const [showPage, setShowPage] = useState(false);
  const [item, setItem] = useState(null);
  return (
    <>
      {showPage && (
        <Page
          showPage={showPage}
          setTab={setTab}
          setShowPage={setShowPage}
          tabChangeHandler={tabChangeHandler}
          setItem={setItem}
          item={item}
        />
      )}
      <div className="sticky tab__sticky z10 d-flex gap-2">
        <Tabs
          activeKey={tab}
          onSelect={(k) => {
            setTab(k);
            setSearchParams(`tab=${automationTabs?.find((a) => a.link == k)?.param}`);
            tabChangeHandler(k);
          }}
          className={`mb-3 recycle-custom-tab`}
        >
          {automationTabs.map((item, index) => {
            return <Tab eventKey={item.link} title={item.name}></Tab>;
          })}
        </Tabs>
      </div>
    </>
  );
}

export default TableTabs;
