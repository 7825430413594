import { Content } from "@jobber/components/Content"
import { Link } from "@jobber/components/Link"
import { Modal } from "@jobber/components/Modal"
import { Text } from "@jobber/components/Text"

export default function FailedTaskModal({ title, linkText, showText = true, modalOpen, setModalOpen, failedData }) {
  return (
    <Modal
      title={title}
      open={modalOpen}
      primaryAction={{ label: "Ok", onClick: () => setModalOpen(false) }}
      onRequestClose={() => setModalOpen(false)}
    >
      <Content>
        {showText && (
          <Text>
            We are in contact with jobber support to solve this issue, for the time being you'll have to reopen the jobs manually
          </Text>
        )}
        <div style={{
          maxHeight: '400px',
          overflow: 'auto'
        }}
          className="border border-muted p-3 d-flex flex-column gap-2">
          {failedData.map((data) => {
            return (
              <div key={data?.jobId} className='d-flex align-items-center justify-content-between'>
                <div className='d-flex flex-column gap-1'>
                  <div className='d-flex align-items-center gap-1'>
                    <h6 className='mb-0'>
                      {data?.clientName || data?.client?.name}
                    </h6>
                    <span className='fs-6 fst-italic text-muted'>- {data?.jobTitle || data?.title}</span>
                  </div>
                  <p className='mb-0'>
                    Address: {data?.propertyAddress || data?.property?.address?.street}
                  </p>
                </div>
                <Link url={data?.jobUri || data?.jobWebUri} external={true}>
                  <p style={{
                    backgroundColor: '#7db00e'
                  }} className="text-white mb-0 p-2" >
                    {linkText || 'Reopen from jobber?'}
                  </p>
                </Link>
              </div>
            )
          }
          )}
        </div>
      </Content>
    </Modal>
  )
}
