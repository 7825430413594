import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@jobber/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { SocketContext } from '../../../SocketProvider';
import { ProgressBar } from 'react-bootstrap';
import { getFromServer, postToServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import DateRangeModal from '../../../components/Common/DateRangeModal';
import { Spinner } from '@jobber/components/Spinner';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import LockerButton from '../../../utils/LockerButton';
import { useAutomationFilters } from '../../AdvanceJobs/FilterQuery/AutomationFilterQuery';
import { useLocation } from 'react-router';
import { setStartSyncR } from '../../../store/layoutSlice';

function TopButtons({ sync, currentPage }) {
	const dispatch = useDispatch();
	const { buildQuery } = useAutomationFilters();
	const { socket, socketHandler } = useContext(SocketContext);
	const [comingMsg, setComingMsg] = useState(null);
	const [progressValue, setProgressValue] = useState(1);
	const [dateModal, setDateModal] = useState(false);
	const [dateReportModal, setDateReportModal] = useState(false);

	const userStore = useSelector((store) => store.user);
	const user = userStore?.user?.data;

	// for header button sync
	const location = useLocation();
	useEffect(() => {
		if (location?.state?.sync) {
			setDateModal(true)
			window.history.replaceState({}, '')
		}
	}, [location]);
	// for header button sync  end

	useEffect(() => {
		if (!loading && comingMsg) {
			setLoading(true)
		}
		if (comingMsg) {
			setProgressValue(comingMsg?.progress);
		}
	}, [comingMsg]);

	useEffect(() => {
		if (!socket) {
			const newSocket = io(process.env.REACT_APP_API_URL);
			socketHandler(newSocket);
		}

		if (socket) {
			socket.on(`Genie_Job_progress_${user?._id}`, (notify) => {
				console.log('socket called', notify);
				setComingMsg(notify);
			});

			socket.on('error', (errorMessage) => {
				console.error(errorMessage);
			});

			socket.on('connect', (successMessage) => {
				console.log('socket connected');
			});
		}
	}, [socket]);

	const joinRoom = async () => {
		if (socket && user?._id) {
			await socket.emit('joinGenieRoom', { userId: user?._id });
		}
	};

	useEffect(() => {
		joinRoom();
	}, [user?._id, socket]);

	const [loading, setLoading] = useState(false);
	const syncClientHandler = async (s, e) => {
		setDateModal(false);
		setLoading(true);
		dispatch(setStartSyncR(true))
		const result = await postToServer(currentPage == 'jobs' ? 'sync-jobs' : 'syncjobs', { startDate: s, endDate: e });
		if (result.status) {
			setLoading(false);
			showToast({
				message: 'Sync successfully Completed'
			});
			sync();
			setProgressValue(1);
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setLoading(false);
	};

	// export handler
	const [excelLoading, setExcelLoading] = useState(false);
	const exportToCSV = (apiData, fileName) => {
		// Step 1: Convert JSON to Worksheet
		const worksheet = XLSX.utils.json_to_sheet(apiData);

		// Step 2: Create a Workbook
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

		// Step 3: Write Workbook to binary string
		const excelBuffer = XLSX.write(workbook, {
			bookType: 'xlsx',
			type: 'array'
		});

		// Step 4: Create a Blob with explicit MIME type
		const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const data = new Blob([excelBuffer], { type: mimeType });

		FileSaver.saveAs(data, fileName + '.xlsx');

		showToast({
			message: 'Excel file downloaded'
		});
	};

	const handleExport = async (startDate, endDate) => {
		setExcelLoading(true);
		setDateReportModal(false);
		const result = await postToServer(`generate-report`, { startDate, endDate });
		if (result.status) {
			const newData = result.data.jobs?.map((qa) => {
				const myObj = {};
				myObj['Client Id'] = qa?.client?.id;
				myObj['Name'] = qa?.client?.name;
				myObj['Quote Id'] = qa?.quote?.id;
				myObj['Job Id'] = qa?.jobId;
				myObj['Job number'] = qa?.jobNumber;
				myObj['Title'] = qa?.title;
				myObj['Instructions'] = qa?.instructions;
				myObj['Property'] = qa?.property?.address?.street + ' ' + qa?.property?.address?.postalCode;
				myObj['Invoicing'] = qa?.invoiceSchedule?.billingFrequency + ' ' + qa?.invoiceSchedule?.scheduleSummary;
				myObj['Status'] = qa?.jobStatus;
				myObj['Type'] = qa?.jobType;
				myObj['Total'] = qa?.total;
				myObj['Created at'] = qa?.jobCreatedAt;
				myObj['Updated at'] = qa?.jobUpdatedAt;
				return myObj;
			});

			exportToCSV(newData, 'JobsReport');
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setExcelLoading(false);
	};

	const getApiUrl = () => {
		const queryString = buildQuery();
		return `bulk-reopen-job?${queryString}`;
	};

	return (
		<div>
			{dateModal && <DateRangeModal open={dateModal} onConfirm={syncClientHandler} onCancel={() => setDateModal(false)} />}
			{dateReportModal && <DateRangeModal open={dateReportModal} onConfirm={handleExport} onCancel={() => setDateReportModal(false)} />}
			{loading ? (
				<div className="mb-2">
					<ProgressBar animated variant="success" label={`${progressValue}%`} now={progressValue} />
					<p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }} className="mt-1">
						Do not perform any operation, while we are syncing
					</p>
				</div>
			) : (
				<div className="d-flex gap-2 justify-content-end">
					{currentPage == 'converted-jobs' && (
						<>{excelLoading ? <Spinner size="small" /> : <Button label={'Report'} onClick={() => setDateReportModal(true)} />}</>
					)}
					<span className="ms-2">
						<Button label={'Sync Jobs'} onClick={() => setDateModal(true)} />
					</span>
					{currentPage == 'jobs' && (
						<LockerButton
							socketName="Genie_Reopen_Jobs_"
							border
							method="POST"
							reload={sync}
							btnText="Bulk reopen"
							apiUrl={getApiUrl()}
							successMsg={'Jobs reopened successfully'}
						/>
					)}
				</div>
			)}
		</div>
	);
}

export default TopButtons;
