import { useState, useEffect } from 'react';

const url = process.env.REACT_APP_API_URL + '/api';

const useGetFromServer = (urlPath, deps = []) => {
  const [data, setData] = useState([]);
  const [result, setResult] = useState({})
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      setLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem('authUser'));
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token?.access}`);
        myHeaders.append('Content-Type', `application/json`);
        myHeaders.append('Accept', `*/*`);
        var requestOptions = {
          method: 'GET',
          headers: myHeaders
        };
        const response = await fetch(`${url}/${urlPath}`, {
          ...requestOptions,
          signal
        });
        if (!response.status) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setResult(result);
        setData(result?.data);
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError(err);
        }
      } finally {
        if (!signal.aborted) { // Only set loading false if the fetch was not aborted
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, deps);

  return { data, result, error, loading };
};

export default useGetFromServer;
