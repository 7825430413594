import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import format from 'date-fns/format';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Button } from '@jobber/components/Button';
import { useAutomationFilters } from '../pages/AdvanceJobs/FilterQuery/AutomationFilterQuery';
import { useVisitFilters } from '../pages/AdvanceVisits/FilterQuery/VisitFilterQuery';
import { Checkbox } from '@jobber/components/Checkbox';
import { IoMdCloseCircle } from 'react-icons/io';

function JobDateRangeInput({ visits }) {
	const { updateFilterByList } = visits ? useVisitFilters() : useAutomationFilters();
	const [date, setDate] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection'
		}
	]);
	const [isOpen, setIsOpen] = useState(false);
	const [isApplied, setIsApplied] = useState(false);

	const handleSelect = (ranges) => {
		setDate([ranges.selection]);
	};

	const displayDate = () => {
		if (!date[0].startDate || !date[0].endDate) return '';
		return `${format(date[0].startDate, 'MM/dd/yyyy')} - ${format(date[0].endDate, 'MM/dd/yyyy')}`;
	};

	const applyHandler = () => {
		setIsOpen(false);
		setIsApplied(true);
		updateFilterByList({
			dateTypes: selectedDates,
			startDate: [format(date?.[0]?.startDate, 'yyyy-MM-dd')],
			endDate: [format(date?.[0]?.endDate, 'yyyy-MM-dd')]
		});
	};

	const [selectedDates, setSelectedDates] = useState(['closedAt']);
	const handleCheckboxChange = (e, status) => {
		if (e) {
			setSelectedDates((prevStatuses) => [...prevStatuses, status]);
		} else {
			setSelectedDates((prevStatuses) => prevStatuses.filter((s) => s !== status));
		}
	};

	return (
		<div className="position-relative">
			<div style={{ position: 'relative' }}>
				<input readOnly className="form-control" value={displayDate()} onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }} />
				{isApplied && (
					<div
						style={{ position: 'absolute', top: '-10px', right: '0' }}
						onClick={() => {
							setIsApplied(false);
							updateFilterByList({
								dateTypes: [],
								startDate: [],
								endDate: []
							});
						}}
					>
						<IoMdCloseCircle className="fs-5 pointer" />
					</div>
				)}
			</div>
			{isOpen && (
				<div className="datepicker" style={{ backgroundColor: 'white' }}>
					<div className="d-flex align-items-center gap-3">
						<div className="p-3 automation__filter d-flex gap-3 flex-column">
							{/* <Checkbox
								label={'Filter Created at?'}
								checked={selectedDates.includes('createdAt')}
								onChange={(e) => handleCheckboxChange(e, 'createdAt')}
							/>
							<Checkbox
								label={'Filter Updated at?'}
								checked={selectedDates.includes('updatedAt')}
								onChange={(e) => handleCheckboxChange(e, 'updatedAt')}
							/> */}
							<Checkbox
								label={'Filter Closed at?'}
								checked={selectedDates.includes('closedAt')}
								onChange={(e) => handleCheckboxChange(e, 'closedAt')}
							/>
						</div>
						<div>
							<div style={{ backgroundColor: 'white' }}>
								<DateRangePicker
									onChange={handleSelect}
									ranges={date}
									rangeColors={['#7DB00E']}
									showMonthAndYearPickers={false} // This line hides the left sidebar
									staticRanges={[]} // This line hides the predefined ranges
									inputRanges={[]} // This line hides the input ranges
									className="no-sidebar"
								/>
								<div className="p-2 text-end w-100">
									<Button label="Apply" onClick={applyHandler} />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default JobDateRangeInput;
