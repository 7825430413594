import React from 'react';
import ResetSidebar from '../../components/Common/ResetSidebar';
import { Heading } from '@jobber/components/Heading';
import ChartsQuotes from './components/ChartsQuotes';
import ChartsVisits from './components/ChartsVisits';
import ChartsJobs from './components/ChartsJobs';

function Dashboard() {
	return (
		<>
			<ResetSidebar />
			<div className="row pb-4">
				<div>
					<div className="d-flex justify-content-between align-items-center">
						<Heading level={1}>Dashboard</Heading>
					</div>
					<ChartsQuotes />
					<div className="d-flex">
						<ChartsVisits />
						<ChartsJobs />
					</div>
				</div>
			</div>
		</>
	);
}

export default Dashboard; 
