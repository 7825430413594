import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setShowSidebar } from "../../store/layoutSlice";

function ResetSidebar() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShowSidebar(true));
  }, []);
  return "";
}

export default ResetSidebar;
