import React, { useEffect, useRef } from "react";
import { Content } from "@jobber/components/Content";
import { Card } from "@jobber/components/Card";
import { Button } from "@jobber/components/Button";
import TagCloud from "./TagCloud";

function Offcanvas({ tagCloudHandler, tagCloud, tagReload, setTagReload }) {
  const dropdownRef = useRef(null);
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       tagCloudHandler(false);
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [dropdownRef]);

  useEffect(() => {
    setTagReload(false);
    setTimeout(() => {
      setTagReload(true);
    }, 1);
  }, [tagCloud])

  return (
    <div
      class={`offcanvas offcanvas-end ${tagCloud ? "show" : ""}`}
      ref={dropdownRef}
      tabindex="-1"
      id="offcanvasRight"
    >
      <div class="offcanvas-body">
        <Card
          header={{
            title: "Tag Cloud",
            action: <Button label="X" onClick={tagCloudHandler} />,
          }}
        >
          <Content>
            {tagReload ? (
              <TagCloud
                tagCloud={tagCloud}
                reload={() => {
                  setTagReload(false);
                  setTimeout(() => {
                    setTagReload(true);
                  }, 1);
                }}
              />
            ) : (
              ""
            )}
          </Content>
        </Card>
      </div>
    </div>
  );
}

export default Offcanvas;
