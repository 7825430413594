import React, { useContext, useEffect, useState } from 'react';
import { SocketContext } from '../../../SocketProvider';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@jobber/components/Button';
import io from 'socket.io-client';
import { getFromServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useFilter } from '../FilterQuery/FilterQuery';
import { addUniqueIds } from '../../../components/Common/Validations';
import { setAllTags, setUserTags } from '../../../store/tagsSlice';
import { useLocation, useNavigate } from 'react-router';
import { setStartSyncR, setStopSyncR } from '../../../store/layoutSlice';

function LoadData() {
	const dispatch = useDispatch();
	const { socket, socketHandler } = useContext(SocketContext);
	const { addFilterList } = useFilter();
	const [comingMsg, setComingMsg] = useState(null);
	const [progressValue, setProgressValue] = useState(1);
	
	const userStore = useSelector((store) => store.user);
	const user = userStore?.user?.data;
	
	// for header button sync 
	const location = useLocation()
	useEffect(()=>{
		if(location?.state?.sync){
			syncClientHandler()
			window.history.replaceState({}, '')
		}
	},[location])
	// for header button sync  end


	useEffect(() => {
		if (comingMsg && !loading) {
			setLoading(true);
		}
		if (comingMsg) {
			setProgressValue(comingMsg?.progress);
		}
	}, [comingMsg]);

	useEffect(() => {
		if (!socket) {
			const newSocket = io(process.env.REACT_APP_API_URL);
			console.log(newSocket);
			socketHandler(newSocket);
		}

		if (socket) {
			socket.on(`Genie_progress_${user?._id}`, (notify) => {
				console.log('noti', notify);
				setComingMsg(notify);
			});

			socket.on(`Genie_Tag_Start_${user?._id}`, (notify) => {
				showToast({
					message: 'Please wait while we are fetching and updating tags',
					variation: 'info'
				});
			});

			socket.on(`Genie_Tag_End_${user?._id}`, (notify) => {
				showToast({
					message: 'Tags fetched successfully',
					variation: 'info'
				});
				refetchTags();
			});

			socket.on('error', (errorMessage) => {
				console.error(errorMessage);
			});

			socket.on('connect', (successMessage) => {
				console.log('socket connected');
			});
		}
	}, [socket]);

	const joinRoom = async () => {
		if (socket && user?._id) {
			await socket.emit('joinGenieRoom', { userId: user?._id });
		}
	};

	useEffect(() => {
		joinRoom();
	}, [user?._id, socket]);

	const [loading, setLoading] = useState(false);
	const syncClientHandler = async () => {
		setLoading(true);
		dispatch(setStartSyncR(true))
		showToast({
			message: 'Please wait while we are syncing recent clients.',
			variation: 'info'
		});
		const result = await getFromServer('sync-clients');
		if (result.status) {
			// showToast({
			// 	message: "Fetching tags...",
			// 	variation: "info",
			// });
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		addFilterList({});
		setLoading(false)
	};

	const syncAllClientHandler = async () => {
		setLoading(true);
		showToast({
			message: 'Please wait while we are syncing all clients.',
			variation: 'info'
		});
		const result = await getFromServer('sync-clients?limit=all');
		if (result.status) {
			// showToast({
			// 	message: "Fetching tags...",
			// 	variation: "info",
			// });
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		addFilterList({});
	};

	const refetchTags = async () => {
		const [result2, alltags] = await Promise.all([getFromServer('tags'), getFromServer('Client-Tags')]);
		if (result2.status) {
			dispatch(setUserTags(result2.data));
		} else {
			showToast({
				message: result2.message || result2.error,
				variation: 'error'
			});
		}
		if (alltags.status) {
			const addUniqueId = addUniqueIds(alltags.data);
			dispatch(setAllTags(addUniqueId));
			showToast({
				message: 'Clients synced successfully'
			});
		} else {
			showToast({
				message: alltags.message || alltags.error,
				variation: 'error'
			});
		}
		setProgressValue(1);
		setLoading(false);
	};

	return (
		<>
			{loading ? (
				<div className="mb-2">
					<ProgressBar animated variant="success" label={`${progressValue}%`} now={progressValue} />
					<p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }} className="mt-1">
						Do not perform any operation, while we are syncing
					</p>
				</div>
			) : (
				<>
					<Button label={'Sync All Clients'} onClick={syncAllClientHandler} />
					<span className="ms-2"></span>
					<Button label={'Sync Recent Clients'} onClick={syncClientHandler} />
				</>
			)}
		</>
	);
}

export default LoadData;
