import React, { useCallback, useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import TagIcon from '../TagCloudCategory/TagIcon';
import { Spinner } from '@jobber/components/Spinner';
import EditableText from '../../../components/Common/EditableText';
import { Button } from '@jobber/components/Button';
import { InputText } from '@jobber/components/InputText';
import { getFromServer, getFromServerHardToken, postToServer, postToServerNoToken, putToServer } from '../../../components/Common/requests';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '@jobber/components/Toast';
import { addUniqueIds } from '../../../components/Common/Validations';
import tagsSlice, { setAllTags, setUserTags } from '../../../store/tagsSlice';
import { Glimmer } from '@jobber/components/Glimmer';
import { Heading } from '@jobber/components/Heading';
import { useFilter } from '../FilterQuery/FilterQuery';
import { Checkbox } from '@jobber/components/Checkbox';
import { Tooltip } from '@jobber/components/Tooltip';
import { MdInfoOutline } from 'react-icons/md';
import CustomTooltip from '../TagCloudCategory/CustomTooltip';
import { useLocation } from 'react-router';

const Tag = ({ tag, source, sameTagsClickHandler, excludeTagIdList, includeTagIdList }) => {
  return (
    <div>
      <TagIcon
        id={tag._id}
        name={tag.label}
        count={tag.count}
        source={source}
        sameTagsClickHandler={sameTagsClickHandler}
        includeTagIdList={includeTagIdList}
        excludeTagIdList={excludeTagIdList}
      />
    </div>
  );
};

const Category = ({ catTag, addTag, tagList }) => {
  return (
    <div className="d-flex gap-1 align-items-center mt-2">
      <Checkbox label={catTag?.categoryName} checked={tagList.some((obj) => obj._id === catTag._id)} onChange={() => addTag(catTag)} />
      {catTag?.description && (
        <CustomTooltip text={catTag?.description}>
          <MdInfoOutline className="fs-6 pointer" />
        </CustomTooltip>
      )}
    </div>
  );
};

const TagCloud = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const tagSlice = useSelector((store) => store.tag);
  const [tagList, setTaglist] = useState([]);
  const [isPageLoad, setPageLoad] = useState(true);

  useEffect(() => {
    const tempFilter = JSON.parse(localStorage.getItem('tempNotesFilter'));
    const init = async () => {
      const result = await getFromServer('tags');
      if (result.status) {
        const addUniqueId = addUniqueIds(result.data);
        dispatch(setUserTags(addUniqueId));
        if (isPageLoad) {
          if (tempFilter?.includeTags || tempFilter?.excludeTags) {
            handleAppliedTagsFilter(result.data, addUniqueId, tempFilter);
          }

          const filteredTags = addUniqueId.filter(
            (obj) =>
              // Include if there's at least one tag in includeTags
              obj.tags.some((tag) => tempFilter?.includeTags?.includes(tag.label)) &&
              // And make sure none of the tags are in excludeTags
              !obj.tags.some((tag) => tempFilter?.excludeTags?.includes(tag.label))
          );
          setTaglist(filteredTags);

          setPageLoad(false);
        }
      }
    };
    if (location.pathname.includes('advance-notes') && tagSlice?.userTags?.length <= 0) {
      init();
    } else {
      const filteredTags = tagSlice?.userTags.filter(
        (obj) =>
          // Include if there's at least one tag in includeTags
          obj.tags.some((tag) => tempFilter?.includeTags?.includes(tag.label)) &&
          // And make sure none of the tags are in excludeTags
          !obj.tags.some((tag) => tempFilter?.excludeTags?.includes(tag.label))
      );
      setTaglist(filteredTags);
    }
  }, []);

  const handleAddTags = (cat) => {
    setTaglist((prev) => {
      const index = prev.findIndex((item) => item._id === cat._id);
      if (index !== -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      } else {
        return [...prev, cat];
      }
    });
  };

  const entry = useSelector((store) => store.notesFilter);
  const [sameTagIdList, setSameTagIdList] = useState({
    includeTags: [],
    excludeTags: []
  });
  const sameTagsClickHandler = (tagText, key) => {
    let tagIdList = [];
    tagList?.forEach((cat) => {
      cat?.tags.forEach((t) => {
        if (t.label === tagText) {
          tagIdList.push(t._id);
        }
      });
    });
    setSameTagIdList({ ...sameTagIdList, [key]: tagIdList });
  };
  const handleAppliedTagsFilter = (userTags, allTags, query) => {
    let includeTagIdList = [];
    let exlcudeTagIdList = [];
    userTags?.forEach((cat) => {
      cat?.tags.forEach((t) => {
        if (query?.includeTags?.includes(t.label)) {
          includeTagIdList.push(t._id);
        }
        if (query?.excludeTags?.includes(t.label)) {
          exlcudeTagIdList.push(t._id);
        }
      });
    });
    allTags?.forEach((t) => {
      if (query?.includeTags?.includes(t.label)) {
        includeTagIdList.push(t._id);
      }
      if (query?.excludeTags?.includes(t.label)) {
        exlcudeTagIdList.push(t._id);
      }
    });

    console.log(includeTagIdList, exlcudeTagIdList);
    setSameTagIdList({
      includeTags: includeTagIdList,
      excludeTags: exlcudeTagIdList
    });
  };

  useEffect(() => {
    if (location.pathname.includes('advance-notes')) {
      handleAppliedTagsFilter(tagSlice?.userTags, tagSlice.allTags, entry.notesFilterQuery);
    }
  }, [entry?.isSavedFilterApplied]);

  return (
    <div>
      <div className="mb-3">
        <Heading level={4}>Catgories</Heading>
      </div>
      {!tagSlice?.loading ? (
        tagSlice?.userTags?.map((t) => {
          return <Category key={t?.categoryName} tagList={tagList} catTag={t} addTag={handleAddTags} />;
        })
      ) : (
        <>
          <Glimmer shape={'rectangle'} size={'large'} timing={'base'} />
          <div className="mt-3"></div>
          <Glimmer shape={'rectangle'} size={'base'} timing={'base'} />
        </>
      )}

      <div className="mt-4">
        <div>
          <Heading level={4}>Selected Tags</Heading>
          <div className="row mt-3 mb-2">
            {tagList.map((cat) => {
              return cat?.tags.map((tag) => (
                <div className="col-auto d-flex mb-2" style={{ marginRight: '-25px' }} key={tag._id}>
                  <Tag
                    tag={tag}
                    source={'All Tags'}
                    sameTagsClickHandler={sameTagsClickHandler}
                    includeTagIdList={sameTagIdList?.includeTags}
                    excludeTagIdList={sameTagIdList?.excludeTags}
                  />
                </div>
              ));
            })}

            {tagSlice?.loading && (
              <div className="p-2">
                <Glimmer shape={'rectangle'} size={'large'} timing={'base'} />
                <div className="mt-3"></div>
                <Glimmer shape={'rectangle'} size={'base'} timing={'base'} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagCloud;
