import React from 'react'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className='p-3 position-fixed bottom-0 start-0 end-0 d-flex gap-2'>
      <Link className='text-muted' to={'/privacy-policy'}>Privacy Policy</Link>
      <Link className='text-muted' to={'/user-license-agreement'}>User Agreement</Link>
    </div>
  )
}
