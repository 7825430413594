import React, { useEffect, useState } from 'react';
import { Combobox } from '@jobber/components/Combobox';
import { IoMdCloseCircle } from 'react-icons/io';
import DateRangeInput from '../../../utils/DateRangeInput';
import { getFromServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import { useVisitFilters } from '../../AdvanceVisits/FilterQuery/VisitFilterQuery';
import { setTemporaryFilters } from '../../../components/Common/Validations';
import { useSelector } from 'react-redux';
import useReduxHooks from '../../../utils/useReduxHooks';
import { FormField } from '@jobber/components/FormField';
import { useVisitContext } from '../contextAPI/VisitContext';
import { Chip } from '@jobber/components/Chip';

function VisitFilters({ visits = true }) {
	const { users, setUsers } = useVisitContext();
	const { updateFilter, filters, buildQuery } = useVisitFilters();
	const { fetchAllTags } = useReduxHooks();
	const tagSlice = useSelector((store) => store.tag);
	const [pageLoad, setPageLoad] = useState(true);
	const [completeVisits, setCompleteVisits] = useState(false);
	const [title, setTitle] = useState([]);
	const [rstatic, setStatic] = useState(true);
	const [lineItems, setLineItems] = useState([]);
	const [selectedTitle, setSelectedTitle] = useState([])
	const [selectedLineItems, setSelectedLineItems] = useState([])
	const [selectedUsers, setSelectedUsers] = useState([]);
	// const [users, setUsers] = useState([]);
	const [tags, setTags] = useState([]);
	const [data, setData] = useState(null);

	useEffect(() => {
		if (pageLoad) {
			setPageLoad(false);
		} else {
			const joinedTags = lineItems
				?.map((t) => {
					const regex = /\(\d+\)$/;
					return t.label.replace(regex, '').trim();
				})
				?.join(',');
			updateFilter('lineItemName', joinedTags);
		}
	}, [lineItems]);

	useEffect(() => {
		if (pageLoad) {
			setPageLoad(false);
		} else {
			updateFilter('isComplete', `${completeVisits}`);
		}
	}, [completeVisits]);

	useEffect(() => {
		if (pageLoad) {
			setPageLoad(false);
		} else {
			const joinedTags = tags?.map((t) => t.label)?.join(',');
			updateFilter('includeTags', joinedTags);
		}
	}, [tags]);

	useEffect(() => {
		if (pageLoad) {
			setPageLoad(false);
		} else {
			const joinedTags = title
				.map((title) => {
					const regex = /\(\d+\)$/;
					return title.label.replace(regex, '').trim();
				})
				?.join(',');
			updateFilter('title', joinedTags);
		}
	}, [title]);

	useEffect(() => {
		if (pageLoad || rstatic) {
			setPageLoad(false);
			setStatic(false)
		} else {
			const joinedTags = users
				?.map((t) => {
					const regex = /\(\d+\)$/;
					return t.label.replace(regex, '').trim();
				})
				?.join(',');
			updateFilter('assignedUserName', joinedTags);
		}
	}, [users]);

	function updateStates(result) {
		function updateState(items, dataKey, idKey, setState) {
			if (!items.length) return

			if (result?.data?.[dataKey].length === 0) {
				setState(prev => [{
					...prev[0],
					label: `${prev?.[0]?.label.replace(/\s*\(\d+\)/, '(0)')}`,
				}]);
			}

			const selectedItem = result?.data?.[dataKey]?.find(
				option => option?.[idKey] === items[0]?.label.replace(/\s*\(\d+\)/, '')
			);

			if (!selectedItem) return;

			setState(prev => [{
				...prev[0],
				label: `${selectedItem?.[idKey]} (${selectedItem?.total})`,
			}]);
		}
		updateState(title, 'jobTitles', 'title', setSelectedTitle);
		updateState(lineItems, 'lineItems', 'name', setSelectedLineItems);
		updateState(users, 'assignedUsers', 'name', setSelectedUsers);
	}

	const init = async (initialize = true) => {
		const queryString = buildQuery();
		const [result, allUsers] = await Promise.all([getFromServer(`visit-line-items?${queryString}`), getFromServer(`Assigned-Users`)]);
		if (result.status && allUsers.status) {
			// Create a map from the filter data for quick lookup
			const filterMap = new Map();
			result.data.assignedUsers.forEach(user => {
				filterMap.set(user.userId, user.total);
			});

			// Merge the data
			const mergedData = allUsers.data.assignedUsers.map(user => ({
				...user,
				total: filterMap.get(user.userId) || 0
			}));
			setData({ ...result.data, assignedUsers: mergedData });

			updateStates(result);

			if (initialize) {
				const filters = localStorage.getItem('tF') ? JSON.parse(localStorage.getItem('tF')) : null;
				if (filters) {
					// setTitle(visits ? filters.visitTitle || [] : filters.autoJobTitle || []);
					// setLineItems(visits ? filters.visitLineItem || [] : filters.autoJobLineItem || []);
					// setTags(visits ? filters.visitTags || [] : filters.autoJobTags || []);
					// setUsers(visits ? filters.visitUser || [] : filters.autoJobUser || []);
				}
			} else {
				const newUser = users?.map((user) => {
					const assignedUser = mergedData.find((u) => u.userId == user.id);
					if (assignedUser) {
						return { ...user, label: `${assignedUser?.name} (${assignedUser?.total})` };
					}
					return user;
				});
				if (users?.length > 0) {
					setStatic(true);
					setUsers(newUser);
				}
			}
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
	};

	useEffect(() => {
		if (tagSlice?.allTags?.length <= 0) {
			fetchAllTags();
		}
		setCompleteVisits(filters?.isComplete?.[0] || '');
	}, []);

	useEffect(() => {
		console.log('first', rstatic, pageLoad)
		if (pageLoad || rstatic) {
			setPageLoad(false);
			setStatic(false);
		} else {
			init(false);
		}
	}, [filters]);

	useEffect(() => {
		init();
	}, []);

	return (
		<>
			{visits && (
				<div className="position-relative">
					<FormField size="small" placeholder={'a'} type={'select'} value={completeVisits} onChange={setCompleteVisits}>
						<option value={'all'}>All</option>
						<option value={'true'}>Completed</option>
						<option value={'false'}>Not Completed</option>
					</FormField>
				</div>
			)}
			<div style={{ position: 'relative' }}>
				<Combobox
					// multiSelect
					onSelect={(selection) => {
						setTemporaryFilters('tF', visits ? { visitTitle: selection } : { autoJobTitle: selection });
						setTitle(selection);
						setSelectedTitle(selection);
					}}
					label="Title"
					selected={selectedTitle}
				>
					{data?.jobTitles?.map(
						(t, i) => t?.title && t?.title.trim() !== '' && <Combobox.Option key={i} id={t.id} label={`${t.title} (${t?.total})`} />
					)}
				</Combobox>
				{title?.length > 0 ? (
					<div
						style={{ position: 'absolute', top: '-10px', right: '0' }}
						onClick={() => {
							setTemporaryFilters('tF', visits ? { visitTitle: [] } : { autoJobTitle: [] });
							setTitle([]);
							setSelectedTitle([]);
						}}
					>
						<IoMdCloseCircle className="fs-5 pointer" />
					</div>
				) : (
					''
				)}
			</div>
			<div style={{ position: 'relative' }}>
				<Combobox
					// multiSelect
					onSelect={(selection) => {
						setTemporaryFilters('tF', visits ? { visitLineItem: selection } : { autoJobLineItem: selection });
						setLineItems(selection);
						setSelectedLineItems(selection);
					}}
					label="Line Items"
					selected={selectedLineItems}
				>
					{data?.lineItems?.map((t, i) => (
						<Combobox.Option id={t?.id} label={`${t?.name} (${t?.total})`} />
					))}
				</Combobox>
				{lineItems?.length > 0 ? (
					<div
						style={{ position: 'absolute', top: '-10px', right: '0' }}
						onClick={() => {
							setTemporaryFilters('tF', visits ? { visitLineItem: [] } : { autoJobLineItem: [] });
							setLineItems([]);
							setSelectedLineItems([]);
						}}
					>
						<IoMdCloseCircle className="fs-5 pointer" />
					</div>
				) : (
					''
				)}
			</div>
			<div style={{ position: 'relative' }}>
				<Combobox
					// multiSelect
					onSelect={(selection) => {
						setTemporaryFilters('tF', visits ? { visitTags: selection } : { autoJobTags: selection });
						setTags(selection);
					}}
					label="Tags"
					selected={tags}
				>
					{tagSlice?.allTags?.map((t, i) => (
						<Combobox.Option id={t?._id} label={`${t?.label}`} />
					))}
				</Combobox>
				{tags?.length > 0 ? (
					<div
						style={{ position: 'absolute', top: '-10px', right: '0' }}
						onClick={() => {
							setTemporaryFilters('tF', visits ? { visitTags: [] } : { autoJobTags: [] });
							setTags([]);
						}}
					>
						<IoMdCloseCircle className="fs-5 pointer" />
					</div>
				) : (
					''
				)}
			</div>
			{visits && (
				<div style={{ position: 'relative' }}>
					<Combobox
						multiSelect
						onSelect={(selection) => {
							setTemporaryFilters('tF', visits ? { visitUser: selection } : { autoJobUser: selection });
							setUsers(selection);
							setSelectedUsers(selection);
						}}
						label="Users"
						selected={selectedUsers}
					>
						<Combobox.Activator>
							<Chip label="Assigned Team" variation="subtle" />
						</Combobox.Activator>
						{data?.assignedUsers
							?.map((u) => ({ id: u.userId, name: u.name, total: u?.total }))
							.map((t, i) => (
								<Combobox.Option prefix="Hi" id={t?.id} label={`${t?.name} (${t?.total})`} />
							))}
					</Combobox>
					{users?.length > 0 ? (
						<div
							style={{ position: 'absolute', top: '-10px', right: '0' }}
							onClick={() => {
								setTemporaryFilters('tF', visits ? { visitUser: [] } : { autoJobUser: [] });
								setUsers([]);
								setSelectedUsers([]);
							}}
						>
							<IoMdCloseCircle className="fs-5 pointer" />
						</div>
					) : (
						''
					)}
				</div>
			)}
			<DateRangeInput visits={visits} />
		</>
	);
}

export default VisitFilters;
