import { createSlice } from "@reduxjs/toolkit";

const notesFilterSlice = createSlice({
  name: "filter",
  initialState: {
    notesFilters: [],
    notesFilterQuery: {},
    status:'All',
    entries: 0,
    runing: true,
    isSavedFilterApplied: false,
  },
  reducers: {
    setNotesSavedFilters(state, action) {
      state.notesFilters = action.payload;
    },
    setIsSavedFilterAppliedFilters(state, action) {
      state.isSavedFilterApplied = !state.isSavedFilterApplied;
    },
    setStatusFilters(state, action) {
      state.status = action.payload;
    },
    setReduxEntries(state, action) {
      state.entries = action.payload;
      state.runing = false;
    },
    setRuning(state, action) {
      state.runing = action.payload;
    },
    setNotesFilterQuery(state, action) {
      state.notesFilterQuery = action.payload;
    },
  },
});

export const { setNotesSavedFilters, setReduxEntries, setRuning, setNotesFilterQuery,setStatusFilters, setIsSavedFilterAppliedFilters } =
  notesFilterSlice.actions;
export default notesFilterSlice.reducer;
