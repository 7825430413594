import React from 'react';
import { useJobContext } from '../contextAPI/JobContext';

function CustomBox() {
  const { handleCustomBoxChange } = useJobContext();
  return (
    <div className="gen">
      <div class="genrs_dialog_holder">
        <div class="genrs_dialog" style={{ marginTop: '181.125px', minHeight: '272.75px' }}>
          <div class="genrs_dialog_content" style={{ width: 'auto' }}>
            <h1>
              Repeat <a href="#" onClick={()=>handleCustomBoxChange(false)} title="Cancel" alt="Cancel"></a>
            </h1>
            <p class="frequency-select-wrapper">
              <label for="genrs_frequency">Frequency:</label>
              <select data-wrapper-class="ui-recurring-select" id="genrs_frequency" class="genrs_frequency" name="genrs_frequency">
                <option value="Daily">Daily</option> <option value="Weekly">Weekly</option> <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
            </p>
            <div class="daily_options freq_option_section">
              <p>
                Every
                <input
                  type="text"
                  data-wrapper-class="ui-recurring-select"
                  name="genrs_daily_interval"
                  class="genrs_daily_interval genrs_interval"
                  value="1"
                  size="2"
                />
                day(s)
              </p>
            </div>
            <div class="weekly_options freq_option_section" style={{ display: 'block' }}>
              <p>
                Every
                <input
                  type="text"
                  data-wrapper-class="ui-recurring-select"
                  name="genrs_weekly_interval"
                  class="genrs_weekly_interval genrs_interval"
                  value="1"
                  size="2"
                />
                week(s) on:
              </p>
              <div class="day_holder">
                <a href="#" data-value="0">
                  S
                </a>
                <a href="#" data-value="1" class="selected">
                  M
                </a>
                <a href="#" data-value="2">
                  T
                </a>
                <a href="#" data-value="3" class="selected">
                  W
                </a>
                <a href="#" data-value="4">
                  T
                </a>
                <a href="#" data-value="5">
                  F
                </a>
                <a href="#" data-value="6" class="selected">
                  S
                </a>
              </div>
              {/* <span style="clear:both; visibility:hidden; height:1px;">.</span> */}
            </div>
            <div class="monthly_options freq_option_section">
              <p>
                Every
                <input
                  type="text"
                  data-wrapper-class="ui-recurring-select"
                  name="genrs_monthly_interval"
                  class="genrs_monthly_interval genrs_interval"
                  value="1"
                  size="2"
                />
                month(s):
              </p>
              <p class="monthly_rule_type">
                <span>
                  <label for="monthly_rule_type_day">Day of month</label>
                  <input type="radio" class="monthly_rule_type_day" name="monthly_rule_type" id="monthly_rule_type_day" value="true" />
                </span>
                <span>
                  <label for="monthly_rule_type_week">Day of week</label>
                  <input type="radio" class="monthly_rule_type_week" name="monthly_rule_type" id="monthly_rule_type_week" value="true" />
                </span>
              </p>
              <p class="genrs_calendar_day"></p> <p class="genrs_calendar_week"></p>
            </div>
            <div class="yearly_options freq_option_section">
              <p>
                Every
                <input
                  type="text"
                  data-wrapper-class="ui-recurring-select"
                  name="genrs_yearly_interval"
                  class="genrs_yearly_interval genrs_interval"
                  value="1"
                  size="2"
                />
                year(s)
              </p>
            </div>
            <p class="genrs_summary" style={{ width: '178.4px' }}>
              <span>Weekly on Mondays, Wednesdays, and Saturdays</span>
            </p>
            <div class="controls">
              <input type="button" data-wrapper-class="ui-recurring-select" class="genrs_save" value="OK" />
              <input type="button" data-wrapper-class="ui-recurring-select" class="genrs_cancel" value="Cancel" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomBox;
