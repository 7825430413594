import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputText } from '@jobber/components/InputText';
import { Select, Option } from '@jobber/components/Select';
import Pagination from '../../components/Common/Pagination';
import textTags from '../../assets/icons/textTags.svg';
import { useFilter } from './FilterQuery/FilterQuery';

import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { getFromServer, getFromServerHardToken, postToServer } from '../../components/Common/requests';
import { Glimmer } from '@jobber/components/Glimmer';
import { checkStatus, formatDateTimeDay, getCompleteAddress } from '../../components/Common/Validations';
import { Heading } from '@jobber/components/Heading';
import { Menu } from '@jobber/components/Menu';
import { Button } from '@jobber/components/Button';
import { Checkbox } from '@jobber/components/Checkbox';
import { Dropdown, DropdownItem } from 'react-bootstrap';
import { showToast } from '@jobber/components/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterQuery, setIsSavedFilterAppliedFilters, setReduxEntries, setSavedFilters } from '../../store/filterSlice';
import TableTagSVG from './TableTagSVG';
import { useNavigate } from 'react-router';
import { Spinner } from '@jobber/components/Spinner';
import { Divider } from '@jobber/components/Divider';
import { setBulkTag } from '../../store/bulkTagSlice';

import { Combobox } from '@jobber/components/Combobox';

const columnVisibility = [
  {
    name: 'Name',
    key: 'name'
  },
  {
    name: 'Address',
    key: 'address'
  },
  {
    name: 'Email',
    key: 'email'
  },
  {
    name: 'Phone',
    key: 'phone'
  },
  {
    name: 'Company Name',
    key: 'companyName'
  },
  {
    name: 'Properties',
    key: 'properties'
  },
  {
    name: 'Status',
    key: 'status'
  },
  {
    name: 'Last Activity',
    key: 'activity'
  }
];

export default function Table({ tagCloud, reload }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const settings = useSelector((store)=>store.user)
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [entries, setEntries] = useState(null);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const { updateFilter, buildQuery, reloadFilter, setReloadFilter, filters, addFilterList, savedFilterId, handleSetSavedFilterId } =
    useFilter();
  useEffect(() => {
    setReloadFilter(!reloadFilter);
  }, []);
  const reduxFilters = useSelector((store) => store.filters);

  // status
  const [selected, setSelected] = useState([]);
  const statusHandler = () => {
    if (reduxFilters?.filterQuery?.filter) {
      if (reduxFilters?.filterQuery?.filter?.[0] == 'LeadAndActive') {
        setSelected([
          {
            id: 'LeadAndActive',
            label: 'Leads And Active'
          }
        ]);
      } else {
        setSelected([
          {
            id: reduxFilters?.filterQuery?.filter?.[0],
            label: reduxFilters?.filterQuery?.filter?.[0]
          }
        ]);
      }
    } else {
      setSelected([]);
    }
  };
  useEffect(() => {
    statusHandler();
  }, [reduxFilters?.isSavedFilterApplied]);
  // column visibility
  const [visibleColumns, setVisibleColumns] = useState({});

  const toggleColumn = (columnName, e) => {
    console.log(e);
    setVisibleColumns((prev) => ({
      ...prev,
      [columnName]: !prev[columnName]
    }));
    setTimeout(() => {
      try {
        $('table').resizableColumns('destroy').resizableColumns();
      } catch { }
      // $('#client__table').dragtable();
    }, 500);
  };

  // ++++++++++++++++++

  // for user settings
  const [dragtableState, setDragtableState] = useState({});
  useEffect(() => {
    const localSettings = JSON.parse(localStorage.getItem('userSettings'));
    // const tempFilter = JSON.parse(localStorage.getItem("tempFilter")) || {};
    // if (tempFilter) {
    //   dispatch(setFilterQuery(tempFilter));
    //   dispatch(setIsSavedFilterAppliedFilters());
    // }
    if (!localSettings) {
      localStorage.removeItem('authUser');
      navigate('/login');
    }
    setVisibleColumns(localSettings?.columnVisibility);
    setDragtableState(localSettings?.columnOrder);
    Object.entries(localSettings?.columnWidths)?.map(([key, value]) => {
      localStorage.setItem(`client-${key}`, value);
    });
  }, []);

  const [saveLoader, setSaveLoader] = useState(false);
  const saveUserSettingsHandler = async () => {
    let colWidths = {};
    const width = ['colCompany', 'colProperties', 'colAddress', 'colEmail', 'colStatus', 'colPhone', 'colActivity', 'colName'];
    width.forEach((w) => {
      colWidths[w] = localStorage.getItem(`client-${w}`);
    });
    const body = {
      columnOrder: dragtableState,
      columnVisibility: visibleColumns,
      columnWidths: colWidths
    };
    setSaveLoader(true);
    const result = await postToServer('table-settings', body);
    if (result.status) {
      localStorage.setItem('userSettings', JSON.stringify(body));
      showToast({
        message: 'User settings updated successfully'
      });
    } else {
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
    setSaveLoader(false);
  };
  // ++++++++++++++++++++

  // dragtable / column order
  const initialzeTable = async () => {
    const localSettings = JSON.parse(localStorage.getItem('userSettings'));
    $('#client__table').dragtable({
      dragHandle: '.drag__handle',
      persistState: function (table) {
        table.el.find('th').each(function (i) {
          if (this.id != '') {
            table.sortOrder[this.id] = i;
          }
        });
        console.log(table.sortOrder);
        setDragtableState(table.sortOrder);
      },
      restoreState: localSettings?.columnOrder,
      dragaccept: '.accept'
    });
  };

  // +++++++++++++++++++++++++

  const [currentPageCursor, setCurrentPageCursor] = useState(null);
  const [nextPageCursor, setNextPageCursor] = useState(null);
  const [previousCursors, setPreviousCursors] = useState([]);

  const fetchData = async (destroy = false) => {
    setLoading(true);
    const queryString = buildQuery();
    console.log(queryString);
    const sFI = savedFilterId;

    const result = await getFromServer(`clients?${queryString}${sFI ? `&filterId=${sFI}` : ''}`);

    if (result.status) {
      if (destroy) {
        try {
          $('#client__table').dragtable('destroy');
        } catch { }
      }
      setData(result.data);
      handleSetSavedFilterId(null);

      // setting saved filetrs count updated
      if (sFI) {
        const index = reduxFilters?.filters.findIndex((item) => item._id === sFI);
        const newData = [...reduxFilters?.filters];
        if (index !== -1) {
          newData[index] = {
            ...newData[index],
            totalEntries: result?.totalItems
          };
        }
        dispatch(setSavedFilters(newData));
      }

      setEntries(result?.totalItems);
      dispatch(setReduxEntries(result?.totalItems));
      // setTotal(result?.totalCount);
      setTotalPage(result?.totalPages);
      setTimeout(async () => {
        await initialzeTable();
      }, 100);

      setTimeout(() => {
        try {
          $('table').resizableColumns('destroy').resizableColumns({
            store: window.store
          });
        } catch { }
      }, 500);
    } else {
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
    setLoading(false);
  };

  // pagiantion
  const goToNextPage = (page) => {
    if (currentPageCursor !== null) {
      // Don't push null for the initial page
      setPreviousCursors((prev) => [...prev, currentPageCursor]);
    }
    setCurrentPageCursor(nextPageCursor);
    setCurrentPage(page);
    updateFilter('cursor', nextPageCursor);
  };

  const goToPreviousPage = (page) => {
    const newCursor = previousCursors.pop();
    setPreviousCursors([...previousCursors]);
    setCurrentPageCursor(newCursor);
    setCurrentPage(page);
    updateFilter('cursor', newCursor);
  };
  // ++++++++++++++++++++++

  useEffect(() => {
    if (data?.length <= 0) {
      fetchData();
    }
  }, []);

  // sorting
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ASCENDING'
  });
  const onSort = (key) => {
    updateFilter('sortKey', key);
    if (sortConfig?.key === key && sortConfig?.direction === 'ASCENDING') {
      setSortConfig({ key, direction: 'DESCENDING' });
      updateFilter('sortDirection', 'DESCENDING');
    } else {
      setSortConfig({ key, direction: 'ASCENDING' });
      updateFilter('sortDirection', 'ASCENDING');
    }
  };

  const getIconStyle = (key, direction) => {
    return sortConfig.key === key && sortConfig.direction === direction ? 'fs-5 text-dark' : 'fs-5 text-muted opacity-25';
  };

  // applying filters
  const [pageLoad, setPageLoad] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  useEffect(() => {
    if (!pageLoad) {
      const queryString = buildQuery();
      if (!queryString || queryString?.length <= 0) {
        setCurrentPage(1);
      }
      if (parseInt(filters?.page?.[0])) {
        setCurrentPage(parseInt(filters?.page?.[0]));
      }
      setShowHeader(false);
      setTimeout(() => {
        console.log(filters, filters?.page?.[0]);
        setShowHeader(true);
        fetchData(true);
      }, 10);
    } else {
      setPageLoad(false);
    }
  }, [filters]);

  // dropdown
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownToggleRef = useRef(null);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleClickOutside = (event) => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // +++++++++++++++++++++++++

  const urlHandler = (url) => {
    window.open(url, '_blank');
  };

  const [search, setSearch] = useState('');
  useEffect(() => {
    const tempFilter = JSON.parse(localStorage.getItem('tempFilter'));
    if (tempFilter?.page) setCurrentPage(tempFilter?.page?.[0]);
    if (tempFilter?.searchTerm) setSearch(tempFilter?.searchTerm?.[0]);
    if (tempFilter?.limit) setLimit(parseInt(tempFilter?.limit?.[0]));
  }, []);
  const clearFilters = () => {
    dispatch(setFilterQuery({}));
    localStorage.removeItem('tagCloudInclude');
    localStorage.removeItem('tagCloudExclude');
    addFilterList({});
    setLimit(10);
    setSearch('');
    setSelected([]);
    reload();
  };

  // sticky
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll2 = () => {
    const stickyElement = document.querySelector('.check__sticky');
    if (stickyElement) {
      const offsetTop = stickyElement.getBoundingClientRect().top;
      setIsSticky(offsetTop <= 183);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll2);
    return () => {
      window.removeEventListener('scroll', handleScroll2);
    };
  }, []);

  // ++++++++++++++++++++++++

  // checked rows

  const [checkedRows, setCheckedRows] = useState([]);

  // Function to handle individual row checkbox toggle
  const handleRowCheck = (row) => {
    setCheckedRows((prevCheckedRows) => {
      const isRowChecked = prevCheckedRows.some((checkedRow) => checkedRow.id === row.id);
      if (isRowChecked) {
        // Remove the row from the checked rows
        const returnData = prevCheckedRows.filter((checkedRow) => checkedRow.id !== row.id);
        dispatch(setBulkTag(returnData));
        return returnData;
      } else {
        const returnData = [...prevCheckedRows, row];
        dispatch(setBulkTag(returnData));
        return returnData;
      }
    });
  };

  // Function to handle master checkbox toggle
  const handleMasterCheck = (checked) => {
    if (checked) {
      setCheckedRows(data);
      dispatch(setBulkTag(data));
    } else {
      setCheckedRows([]);
      dispatch(setBulkTag([]));
    }
  };

  // Check if all rows are checked
  const isAllChecked = data.length > 0 && checkedRows.length === data.length;

  // +++++++++++++++++++++++++++++++++++++++++++

  // search debbouncing
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);

  const updateFilters = useCallback((filterName, value) => {
    updateFilter(filterName, value);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (!pageLoad) {
      updateFilters('searchTerm', debouncedSearchTerm);
    } else {
      setPageLoad(false);
    }
  }, [debouncedSearchTerm]);

  return (
    <div>
      <div className="search__sticky">
        <div className="row mb-3">
          <div className="col-lg-3 col-md-4 d-flex gap-2 align-items-center">
            <p className="pt-3">Show</p>
            <Select
              size="small"
              onChange={(e) => {
                updateFilter('limit', e);
                setLimit(e);
              }}
              value={limit}
            >
              {/* <Option value="all">All (Takes time to load)</Option> */}
              <Option value="10">10</Option>
              <Option value="20">20</Option>
              <Option value="50">50</Option>
            </Select>
            <p className="pt-3">entries</p>
          </div>
          <div className="col-lg-4 col-md-3"></div>
          <div className="col-md-5 d-flex gap-2 align-items-center">
            <div>
              <Combobox
                onSelect={(e) => {
                  console.log(e);
                  setSelected(e);
                  updateFilter('filter', e?.[0]?.id);
                }}
                selected={selected}
                label="Status"
              >
                <Combobox.Option id="All" label="All" />
                <Combobox.Option id="LeadAndActive" label="Leads and Active" />
                <Combobox.Option id="Lead" label="Leads" />
                <Combobox.Option id="Active" label="Active" />
                <Combobox.Option id="Archived" label="Archieved" />
              </Combobox>
            </div>
            <InputText
              placeholder="Search"
              name="firstName"
              size="small"
              value={search}
              onChange={(e) => {
                setSearch(e);
              }}
            />
          </div>
        </div>
        <div className="mb-3 d-flex gap-3" style={{ paddingBottom: '1rem' }}>
          <Button label={'Manage Columns'} ref={dropdownToggleRef} onClick={toggleDropdown} />
          <Button label={'Clear Filters'} type="secondary" onClick={clearFilters} />
          {saveLoader ? <Spinner size="base" /> : <Button label={'Save Settings'} type="secondary" onClick={saveUserSettingsHandler} />}
          {/* Dropdown menu */}
        </div>
      </div>
      <div>
        <div className="dropdown__sticky">
          <Dropdown show={showDropdown}>
            <Dropdown.Menu>
              <DropdownItem>Show and Hide Columns</DropdownItem>
              <div className="ms-3">
                {columnVisibility &&
                  columnVisibility?.map((c) => {
                    return (
                      <>
                        <Checkbox label={c.name} checked={visibleColumns[c.key]} onChange={() => toggleColumn(c.key)} />
                        <br />
                      </>
                    );
                  })}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <table data-resizable-columns-id="client" id="client__table" className={`th--border stable table table-hover`}>
          {showHeader && (
            <thead>
              <tr className={`check__sticky ${isSticky ? 'tag__category--sticky' : ''}`}>
                {/* <th class="checkbox-column ps-3"
                data-resizable-column-id="colCheckbox"
                id="colCheckbox"
                >
                  <Checkbox
                    onChange={(e) => handleMasterCheck(e)} 
                    checked={isAllChecked}
                    indeterminate={checkedRows.length > 0 && !isAllChecked}
                  />
                </th> */}
                <th data-resizable-column-id="colName" id="colName" className={`accept ${visibleColumns.name ? '' : 'd-none'}`}>
                  <div className="d-flex justify-content-between">
                    <span className="drag__handle">Name</span>
                    <div
                      className="icon-container"
                      onClick={(e) => {
                        e.stopPropagation();
                        onSort('PRIMARY_NAME');
                      }}
                    >
                      <IoMdArrowDropup className={`icon-up ${getIconStyle('PRIMARY_NAME', 'ASCENDING')}`} />
                      <IoMdArrowDropdown className={`icon-down ${getIconStyle('PRIMARY_NAME', 'DESCENDING')}`} />
                    </div>
                  </div>
                </th>
                <th data-resizable-column-id="colAddress" id="colAddress" className={`accept ${visibleColumns.address ? '' : 'd-none'}`}>
                  <div className="drag__handle">Address</div>
                </th>
                <th data-resizable-column-id="colEmail" id="colEmail" className={`accept ${visibleColumns.email ? '' : 'd-none'}`}>
                  <div className="drag__handle">Email</div>
                </th>
                <th data-resizable-column-id="colPhone" id="colPhone" className={`accept ${visibleColumns.phone ? '' : 'd-none'}`}>
                  <div className="drag__handle">Phone no.</div>
                </th>
                <th
                  data-resizable-column-id="colCompany"
                  id="colCompany"
                  className={`accept drag__handle ${visibleColumns.companyName ? '' : 'd-none'}`}
                >
                  <div className="drag__handle">Company Name</div>
                </th>
                <th
                  data-resizable-column-id="colProperties"
                  id="colProperties"
                  className={`accept ${visibleColumns.properties ? '' : 'd-none'}`}
                >
                  <div className="drag__handle">Properties</div>
                </th>
                <th data-resizable-column-id="colStatus" id="colStatus" className={`accept ${visibleColumns.status ? '' : 'd-none'}`}>
                  <div className="drag__handle">Status</div>
                </th>
                <th
                  data-resizable-column-id="colActivity"
                  id="colActivity"
                  onClick={() => onSort('UPDATED_AT')}
                  className={`accept ${visibleColumns.activity ? '' : 'd-none'}`}
                >
                  <div className="d-flex justify-content-end">
                    <span className="drag__handle">Last activity</span>
                    <div className="icon-container">
                      <IoMdArrowDropup className={`icon-up ${getIconStyle('UPDATED_AT', 'ASCENDING')}`} />
                      <IoMdArrowDropdown className={`icon-down ${getIconStyle('UPDATED_AT', 'DESCENDING')}`} />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            {loading
              ? Array.from({ length: 2 }, (_, index) => (
                <tr>
                  {Object.entries(visibleColumns)?.map(([key, value], index) => (
                    <React.Fragment key={index}>
                      {value && (
                        <td>
                          <Glimmer shape="rectangle" size="base" timing="speed" />
                        </td>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
              ))
              : data &&
              data?.map((s, k) => {
                return (
                  <tr className="pointer">
                    {/* <td>
                        <Checkbox 
                          checked={checkedRows.some(checkedRow => checkedRow.id === s.id)} 
                          onChange={() => handleRowCheck(s)} 
                        />
                      </td> */}

                    <td id="pt_label" className={`${visibleColumns.name ? '' : 'd-none'}`}>
                      <a href={s.jobberWebUri} target="_blank" className="style__none">
                        <Heading level={5}>{s?.name}</Heading>
                        <p style={{ color: 'var(--muted)' }}>{s?.secondaryName}</p>
                        {s?.tags?.length > 0 ? (
                          <div
                            className="mt-3"
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '4px'
                            }}
                          >
                            {s?.tags.map((t) => (
                              <TableTagSVG text={t.label} />
                            ))}
                          </div>
                        ) : (
                          ''
                        )}
                      </a>
                    </td>
                    <td className={`${visibleColumns.address ? '' : 'd-none'}`}>
                      <a href={s.jobberWebUri} target="_blank" className="style__none ">
                        {s?.clientProperties?.map((c, index, array) => {
                          return (
                            <>
                              {/* {c?.street1 && `${c?.street1}, `}
                                {c?.street2 && `${c?.street2}, `}
                                {c?.city && `${c?.city}, `}
                                {c?.province && `${c?.province}, `}
                                {c?.country && `${c?.country}, `}
                                {c?.postalCode && c?.postalCode} */}
                              {getCompleteAddress(c)}
                              {index !== array.length - 1 && (
                                <div className="my-2">
                                  <Divider direction={'horizontal'} />
                                </div>
                              )}
                            </>
                          );
                        })}
                        {s?.clientProperties?.length <= 0 ? <div>0 Properties</div> : ''}
                      </a>
                    </td>
                    <td className={`${visibleColumns.email ? '' : 'd-none'}`}>
                      <a href={s.jobberWebUri} target="_blank" className="style__none">
                        {s?.email?.map((e) => e?.address)?.join(' / ')}
                      </a>
                    </td>
                    <td className={`${visibleColumns.phone ? '' : 'd-none'}`}>
                      <a href={s.jobberWebUri} target="_blank" className="style__none">
                        {s?.phones?.map((p) => p?.friendly)?.join(' / ')}
                      </a>
                    </td>
                    <td className={`${visibleColumns.companyName ? '' : 'd-none'}`}>
                      <a href={s.jobberWebUri} target="_blank" className="style__none">
                        {s?.companyName || ''}
                      </a>
                    </td>
                    <td className={`${visibleColumns.properties ? '' : 'd-none'}`}>
                      <a href={s.jobberWebUri} target="_blank" className="style__none">
                        {s?.clientProperties?.length || 0}
                      </a>
                    </td>
                    <td className={`${visibleColumns.status ? '' : 'd-none'}`}>
                      <a href={s.jobberWebUri} target="_blank" className="style__none">
                        {checkStatus(s)}
                      </a>
                    </td>
                    <td className={`${visibleColumns.activity ? 'text-end' : 'd-none text-end'}`}>
                      <a href={s.jobberWebUri} target="_blank" className="style__none">
                        {formatDateTimeDay(s?.updatedAt)}
                      </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {data?.length <= 0 && !loading ? <div className="text-center">No data found</div> : ''}
      <div className="d-md-flex justify-content-between">
        <div className="">
          <p> {entries} entries</p>
        </div>

        {totalPage == 1 ? (
          ''
        ) : (
          <div className="">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              onPageChange={(page) => {
                setCurrentPage(page);
                updateFilter('page', page);
                window.scrollTo(0, 0)
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
