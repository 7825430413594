import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import { SocketContext } from "../SocketProvider";
import { showToast } from "@jobber/components/Toast";
import { Navigate, useNavigate } from "react-router";

function InitialSocket() {
  const navigate = useNavigate()
  const { socket, socketHandler } = useContext(SocketContext);
  const [comingMsg, setComingMsg] = useState(null);
  const [qbComingMsg, setQBComingMsg] = useState(null);

  const userStore = useSelector((store) => store.user);
  const user = userStore?.user?.data;

  useEffect(() => {
    if (comingMsg) {
      showToast({
        message: comingMsg.message,
        variation: "error",
      });
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_API_URL + `/auth/${user._id}`;
      }, 1000);
      // localStorage.removeItem("authUser");
      // return <Navigate to={"/login"} />;
      // navigate("/login")
    }
  }, [comingMsg]);

  useEffect(() => {
    if (qbComingMsg) {
      showToast({
        message: qbComingMsg.message,
        variation: "error",
      });
      navigate("/settings?tab=quickbooks")
    }
  }, [qbComingMsg]);

  useEffect(() => {
    if (!socket) {
      const newSocket = io(process.env.REACT_APP_API_URL);
      socketHandler(newSocket);
    }

    if (socket) {
      socket.on(`Genie_token_${user?._id}`, (notify) => {
        setComingMsg(notify);
      });

      socket.on(`Quickbooks_${user?._id}`, (notify) => {
        setQBComingMsg(notify);
      });

      socket.on("error", (errorMessage) => {
        console.error(errorMessage);
      });

      socket.on("connect", (successMessage) => {
        console.log("socket connected");
      });
    }
  }, [socket]);

  const joinRoom = async () => {
    if (socket && user?._id) {
      await socket.emit("joinGenieRoom", { userId: user?._id });
    }
  };

  useEffect(() => {
    joinRoom();
  }, [user?._id, socket]);
  return "";
}

export default InitialSocket;
