import { Heading } from "@jobber/components/Heading";
import React, { useEffect, useState } from "react";
import downFill from "../../../../assets/icons/downFill.svg";
import sideFill from "../../../../assets/icons/sideFill.svg";
import { Checkbox } from "@jobber/components/Checkbox";
import { FormField } from "@jobber/components/FormField";
// import SavedFilters from "./SavedFilters";
import { Divider } from "@jobber/components/Divider";
import { Button } from "@jobber/components/Button";
import { InlineLabel } from "@jobber/components/InlineLabel";
import {
  postToServer,
  postToServerNoToken,
} from "../../../../components/Common/requests";
import { Spinner } from "@jobber/components/Spinner";
import { showToast } from "@jobber/components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredDataValues } from "../../../../components/Common/Validations";
import { useAutomationFilters } from "../../FilterQuery/AutomationFilterQuery";
import { setAutomationFilterQuery, setAutomationSavedFilters } from "../../../../store/automationFilterSlice";
// import { setAutomationFilterQuery, setQuoteSavedFilters } from "../../../../store/quoteFilterSlice";

function Filters({ reload, editSavedFilterId, setShowFilter }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [showSave, setShowSave] = useState(false);

  const { updateFilterByList, filters, updateTempFilter, applyButtonFilter } =
  useAutomationFilters();
  const entry = useSelector((store) => store.automationFilter);
  console.log(entry?.automationFilterQuery);


  const initialFilterType = (filterType) =>
    entry?.automationFilterQuery?.[`${filterType}FilterType`]?.length <= 0 ||
    entry?.automationFilterQuery?.[`${filterType}FilterType`]?.[0] == undefined
      ? "is"
      : entry?.automationFilterQuery?.[`${filterType}FilterType`]?.[0];

  const initialNumberFilterType = (filterType) =>
    entry?.automationFilterQuery?.[`${filterType}FilterType`]?.length <= 0 ||
    entry?.automationFilterQuery?.[`${filterType}FilterType`]?.[0] == undefined
      ? "="
      : entry?.automationFilterQuery?.[`${filterType}FilterType`]?.[0];

  const initialFilterChecked = (filterType) =>
    entry?.automationFilterQuery?.[`${filterType}FilterType`]?.length <= 0 ||
    entry?.automationFilterQuery?.[`${filterType}FilterType`]?.[0] == undefined
      ? false
      : true;

  const initialFilterValue = (filterType) =>
    entry?.automationFilterQuery?.[`${filterType}FilterValue`]?.length <= 0 ||
    entry?.automationFilterQuery?.[`${filterType}FilterValue`]?.[0] == undefined
      ? ""
      : entry?.automationFilterQuery?.[`${filterType}FilterValue`]?.[0];

  const [fieldFilters, setFieldFilters] = useState();

  useEffect(() => {
    setFieldFilters([
      {
        name: "First Name",
        key: "name",
        type: initialFilterType("name"),
        value: initialFilterValue("name"),
        checked: initialFilterChecked("name"),
        isNumber: false,
      },
      {
        name: "Last Name",
        key: "lastName",
        type: initialFilterType("lastName"),
        value: initialFilterValue("lastName"),
        checked: initialFilterChecked("lastName"),
        isNumber: false,
      },
      {
        name: "Title",
        key: "title",
        type: initialFilterType("title"),
        value: initialFilterValue("title"),
        checked: initialFilterChecked("title"),
        isNumber: false,
      },
      {
        name: "Address",
        key: "address",
        type: initialFilterType("address"),
        value: initialFilterValue("address"),
        checked: initialFilterChecked("address"),
        isNumber: false,
      },
      {
        name: "Postal Code",
        key: "postalCode",
        type: initialFilterType("postalCode"),
        value: initialFilterValue("postalCode"),
        checked: initialFilterChecked("postalCode"),
        isNumber: false,
      },
      {
        name: "Email",
        key: "email",
        type: initialFilterType("email"),
        value: initialFilterValue("email"),
        checked: initialFilterChecked("email"),
        isNumber: false,
      },
      {
        name: "Phone",
        key: "phone",
        type: initialFilterType("phone"),
        value: initialFilterValue("phone"),
        checked: initialFilterChecked("phone"),
        isNumber: false,
      },
      {
        name: "Total Cost",
        key: "totalCost",
        type: initialNumberFilterType("totalCost"),
        value: initialFilterValue("totalCost"),
        checked: initialFilterChecked("totalCost"),
        isNumber: true,
      },
      {
        name: "Amount Paid",
        key: "amountPaid",
        type: initialNumberFilterType("amountPaid"),
        value: initialFilterValue("amountPaid"),
        checked: initialFilterChecked("amountPaid"),
        isNumber: true,
      },
      {
        name: "Amount Owed",
        key: "amountOwed",
        type: initialNumberFilterType("amountOwed"),
        value: initialFilterValue("amountOwed"),
        checked: initialFilterChecked("amountOwed"),
        isNumber: true,
      },
    ]);
  }, [entry?.isSavedFilterApplied]);

  const [apply, setApply] = useState(false);
  const [saving, setSaving] = useState(false);

  const changeHandler = (name, value, key) => {
    const newdata = [...fieldFilters];
    newdata[key] = { ...newdata[key], [name]: value };
    setFieldFilters(newdata);

    let query = {};
    newdata?.forEach((f) => {
      const filterTypeKey = `${f.key}FilterType`;
      const filterValueKey = `${f.key}FilterValue`;

      if (f.checked) {
        query[filterTypeKey] = [f.type];
        query[filterValueKey] = [f.value];
      } else {
        query[filterTypeKey] = [];
        query[filterValueKey] = [];
      }
    });
    dispatch(setAutomationFilterQuery(query));
    updateTempFilter(query);
  };

  const applyFilterHandler = async () => {
    setApply(true);
    applyButtonFilter();
    setTimeout(() => {
      setApply(false);
      setShowFilter(false)
      // setShowSave(true);
    }, 1500);
  };

  const [filterName, setFilterName] = useState("");
  const [showFilterName, setShowFilterName] = useState(false);
  const saveFilterHandler = async () => {
    setSaving(true);
    const filterTypes = ["name", "address", "company", "email", "phone"];
    let filterQuery = [];

    filterTypes.forEach((filterType) => {
      const typeKey = `${filterType.toLowerCase()}FilterType`;
      const valueKey = `${filterType.toLowerCase()}FilterValue`;

      if (typeKey in filters) {
        filterQuery.push({
          label: filterType,
          constraint: filters[typeKey]?.[0],
          value: filters[valueKey]?.[0],
        });
      }
    });
    let body = {
      filterName: filterName,
      filters: filterQuery,
      limit: filters.limit?.[0] || 10,
      searchTerm: filters.searchTerm?.[0] || "",
      status: filters.filter?.[0] || "All",
      tags: {
        include: filters?.includeTags || [],
        exclude: filters?.excludeTags || [],
      },
      totalEntries: entry?.entries,
    };
    const result = await postToServer("filters", body);
    if (result.status) {
      let newTag = getFilteredDataValues(result.data);
      const newData = { ...result.data, tags: newTag };
      dispatch(setAutomationSavedFilters([...entry.filters, newData]));
      showToast({
        message: "Filter saved successfully",
        variation: "success",
      });
    } else {
      showToast({
        message: result.message || result.error,
        variation: "error",
      });
    }
    setSaving(false);
    setShowSave(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          {/* <SavedFilters reload={reload} editSavedFilterId={editSavedFilterId} /> */}
        </div>
        {/* <Divider direction="vertical" /> */}
        <div className="col-12">
          {showSave && (
            <div className="mb-3">
              <InlineLabel>
                <div className="d-flex gap-3 align-items-center">
                  Do you want to save this filter?
                  {showFilterName && (
                    <input
                      type="text"
                      placeholder="Provide Filter name"
                      onChange={(e) => setFilterName(e.target.value)}
                    />
                  )}
                  {saving ? (
                    <div className="ms-2">
                      <Spinner size={"small"} />
                    </div>
                  ) : (
                    <Button
                      label={"Yes, save it."}
                      size="small"
                      onClick={
                        showFilterName
                          ? saveFilterHandler
                          : () => setShowFilterName(true)
                      }
                    />
                  )}
                  {!saving && (
                    <Button
                      label={"No"}
                      size="small"
                      type="secondary"
                      onClick={() => {
                        setShowSave(false);
                        setShowFilterName(false);
                      }}
                    />
                  )}
                </div>
              </InlineLabel>
            </div>
          )}
          <div
            className="d-flex pointer"
            onClick={() => setShow((prev) => !prev)}
          >
            {show ? (
              <img src={downFill} className="me-2" />
            ) : (
              <img src={sideFill} className="me-2" />
            )}
            <Heading level={4}>Filter by fields</Heading>
          </div>
          <div className={`content ${show ? "slide-in" : "slide-out"}`}>
            <div className="ps-3 pe-3 pt-3">
              {fieldFilters &&
                fieldFilters?.map((f, key) => {
                  return (
                    <>
                      <div className="d-flex gap-3 mb-2">
                        <div style={{ width: "500px" }}>
                          <Checkbox
                            label={f.name}
                            checked={f.checked}
                            onChange={(e) => changeHandler("checked", e, key)}
                          />
                        </div>

                        {f?.isNumber ? (
                          <FormField
                            type={"select"}
                            size="small"
                            name={"type"}
                            value={f.type}
                            onChange={(e) => changeHandler("type", e, key)}
                          >
                            <option value={"="}>{"="}</option>
                            <option value={"<"}>{"<"}</option>
                            <option value={"<="}>{"<="}</option>
                            <option value={">"}>{">"}</option>
                            <option value={">="}>{">="}</option>
                          </FormField>
                        ) : (
                          <div
                            className="child__style__select"
                            style={{ width: "100%" }}
                          >
                            <FormField
                              type={"select"}
                              size="small"
                              name={"type"}
                              value={f.type}
                              onChange={(e) => changeHandler("type", e, key)}
                            >
                              <option value={"is"}>is</option>
                              <option value={"isnt"}>isn't</option>
                              <option value={"contains"}>contains</option>
                              <option value={"doesnt contains"}>
                                doesn't contains
                              </option>
                              <option value={"starts with"}>starts with</option>
                              <option value={"ends with"}>ends with</option>
                              <option value={"is empty"}>is empty</option>
                              <option value={"is not empty"}>
                                is not empty
                              </option>
                            </FormField>
                          </div>
                        )}

                        <FormField
                          placeholder={f?.isNumber ? "Numbers..." : "Words..."}
                          type={"text"}
                          size="small"
                          value={f.value}
                          onChange={(e) => changeHandler("value", e, key)}
                        />
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="ms-3 mt-2">
              {apply ? (
                <Spinner size={"base"} />
              ) : (
                <Button label="Apply Filters" onClick={applyFilterHandler} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Filters;
