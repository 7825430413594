import React, { useEffect, useState } from 'react';
import { Button } from '@jobber/components/Button';
import downFill from '../../../assets/icons/downFill.svg';
import sideFill from '../../../assets/icons/sideFill.svg';
import { Col, Row, Spinner } from 'react-bootstrap';
import Notes from './Notes';
import CorrectedNotes from './CorrectedNotes';
import TranslatedNotes from './TranslatedNotes';
import { getCompleteAddress } from '../../../components/Common/Validations';
import { Divider } from '@jobber/components/Divider';
import { Heading } from '@jobber/components/Heading';
import { FaCircleArrowLeft } from 'react-icons/fa6';
import { Tooltip } from '@jobber/components/Tooltip';
import { useNotesContext } from '../contextAPI/NotesContext';
import { putToServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';

function ArrowButton({ note, clientId, updateData, filterAction }) {
  const { correctedNote, isNoteEdited, setIsNoteEdited } = useNotesContext();
  const [loading, setLoading] = useState(false);

  const arrowButtonClickHandler = async () => {
    setLoading(true);
    let params = ''
    if (filterAction.length) {
      params = new URLSearchParams({
        filterAction: filterAction[0].id
      })
    }
    const result = await putToServer(`update-jobber-note/${clientId}/${note?.id}/corrected?${params}`, {
      message: isNoteEdited ? correctedNote : note?.correctedMessage,
      linkedTo: note.linkedTo || { requests: true, quotes: true, jobs: true, invoices: true }
    });
    if (result.status) {
      updateData(result.data, 'note');
      setIsNoteEdited(false)
      showToast({
        message: 'Jobber note updated by corrected note'
      });
    }
    else {
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <Tooltip message={"Update Default Note by Corrected Note"}>
          <FaCircleArrowLeft className="fs-5 pointer text-green" onClick={arrowButtonClickHandler} />
        </Tooltip>
      )}
    </div>
  );
}

function TableRow({ row, updateData, filterAction }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <tr className="pointer" onClick={() => setShow(!show)}>
        <td>{show ? <img src={downFill} className="" /> : <img src={sideFill} className="" />}</td>
        <td>
          <Heading level={5}>{row?.name}</Heading>
          <p style={{ color: 'var(--muted)' }}>{row?.secondaryName}</p>
        </td>
        <td>
          {row?.clientProperties?.map((c, index, array) => {
            return (
              <>
                {getCompleteAddress(c)}
                {index !== array.length - 1 && (
                  <div className="my-2">
                    <Divider direction={'horizontal'} />
                  </div>
                )}
              </>
            );
          })}
          {row?.clientProperties?.length <= 0 ? <div>0 Properties</div> : ''}
        </td>
        <td>{row?.note?.notes?.length}</td>
        <td>
          <Button label="Expand Row" type="secondary" size="small" />
        </td>
      </tr>
      {show && (
        <tr>
          <td colSpan={5}>
            <table className="notetable table table-borderless">
              <thead>
                <tr>
                  <th className="fw-bold">Notes</th>
                  <th style={{ width: '10px' }}></th>
                  <th className="fw-bold">AI Corrected Notes</th>
                  <th className="fw-bold">AI Translated Notes</th>
                </tr>
              </thead>
              <tbody>
                {row?.note?.notes?.map((n, i) => {
                  return (
                    <tr>
                      <td>
                        <Notes note={n} clientId={row?.id} updateData={updateData} filterAction={filterAction} />
                      </td>
                      <td style={{ paddingTop: '4rem' }}>
                        <ArrowButton note={n} clientId={row?.id} updateData={updateData} filterAction={filterAction} />
                      </td>
                      <td>
                        <CorrectedNotes note={n} clientId={row?.id} updateData={updateData} />
                      </td>
                      <td>
                        <TranslatedNotes note={n} clientId={row?.id} updateData={updateData} filterAction={filterAction} />
                      </td>
                    </tr>
                  );
                })}
                {row?.note?.notes?.length <= 0 ? (
                  <tr>
                    <td>No data found</td>
                    <td>No data found</td>
                    <td>No data found</td>
                  </tr>
                ) : (
                  ''
                )}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}

export default TableRow;
