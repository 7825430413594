import { useEffect, useState } from 'react';
import { Modal } from '@jobber/components/Modal';
import { Content } from '@jobber/components/Content';
import { Combobox } from '@jobber/components/Combobox';
import { Chip } from '@jobber/components/Chip';
import { Icon } from '@jobber/components/Icon';
import { useSelector } from 'react-redux';
import BulkTagSVG from './BulkTagSVG';
import { Row } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import { Checkbox } from '@jobber/components/Checkbox';
import { RadioGroup, RadioOption } from '@jobber/components/RadioGroup';
import { getCompleteAddress } from '../../../components/Common/Validations';

function BulkTagModal({
	open,
	onCancel,
	onConfirm,
	checkbox = false,
	headline,
	removeTags,
	multipleAddresses,
	include = [],
	exclude = []
}) {
	const [checked, setChecked] = useState(multipleAddresses?.map((c) => c.clientId) || []);

	const handleCheckboxChange = (id) => {
		setChecked((prevChecked) => (prevChecked.includes(id) ? prevChecked.filter((checkedId) => checkedId !== id) : [...prevChecked, id]));
	};

	const tagSlice = useSelector((store) => store.tag);

	const [includeSelected, setIncludeSelected] = useState(include || []);
	const [excludeSelected, setExcludeSelected] = useState(exclude || []);

	const includeTagRemoveHandler = (index) => {
		setIncludeSelected(includeSelected.filter((value, i) => index !== i));
	};
	const excludeTagRemoveHandler = (index) => {
		setExcludeSelected(excludeSelected.filter((value, i) => index !== i));
	};

	const [closeJob, setCloseJob] = useState(false);
	const [completeVisit, setCompleteVisit] = useState('complete');

	const handleBody = () => {
		let body = {
			tagsToAdd: includeSelected?.map((i) => i.label),
			tagsToDelete: excludeSelected?.map((i) => i.label)
		};
		if (closeJob) {
			body['closeJob'] = completeVisit;
		}
		if (multipleAddresses?.length > 0) {
			body['confirmedClientIds'] = checked;
		}
		onConfirm(body);
		console.log(body)
		setIncludeSelected([]);
		setExcludeSelected([]);
	};

	return (
		<>
			<Modal
				title={headline}
				open={open}
				primaryAction={{ label: 'Save', onClick: handleBody }}
				secondaryAction={{
					label: 'Cancel',
					onClick: onCancel
				}}
				onRequestClose={onCancel}
			>
				<Content>
					<Row>
						<div className="d-flex gap-3 align-items-center">
							<Combobox
								multiSelect
								onSelect={(selection) => {
									console.log(selection);
									setIncludeSelected(selection);
								}}
								selected={includeSelected}
							>
								<Combobox.Activator>
									<Chip heading={'Add Tags'} variation={'subtle'}>
										<Chip.Suffix>
											<Icon name={'add'} size={'large'} />
										</Chip.Suffix>
									</Chip>
								</Combobox.Activator>
								{tagSlice?.allTags ? tagSlice.allTags.map((t) => <Combobox.Option id={t.label} label={t.label} />) : null}
								<Combobox.Action
									visible={({ searchValue }) => Boolean(searchValue)}
									label={({ searchValue }) => `Add ${searchValue} as tag`}
									onClick={(_, { searchValue }) => {
										const alreadyIncluded = includeSelected.some((item) => item.id === searchValue);
										if (!alreadyIncluded) {
											setIncludeSelected((prevIncludeSelected) => [...prevIncludeSelected, { id: searchValue, label: searchValue }]);
										}
									}}
								/>
							</Combobox>
							<div className={`${includeSelected?.length == 1 ? '' : 'row'} mt-3 mb-2`}>
								{includeSelected &&
									includeSelected?.map((t, index) => {
										return (
											<div className="col-auto d-flex mb-2" style={{ marginRight: '-25px', position: 'relative' }} key={t._id}>
												<BulkTagSVG text={t.label} count={1} />
												<div
													style={{ position: 'absolute' }}
													className="bg-light h-100 border rounded"
													onClick={() => includeTagRemoveHandler(index)}
												>
													<IoClose className="fs-6 pointer" />
												</div>
											</div>
										);
									})}
							</div>
						</div>
					</Row>
					<Row>
						<div className="d-flex gap-3 align-items-center">
							<Combobox
								multiSelect
								onSelect={(selection) => {
									console.log(selection);
									setExcludeSelected(selection);
								}}
								selected={excludeSelected}
							>
								<Combobox.Activator>
									<Chip heading={'Remove Tags'} variation={'subtle'}>
										<Chip.Suffix>
											<Icon name={'add'} size={'large'} />
										</Chip.Suffix>
									</Chip>
								</Combobox.Activator>
								{removeTags ? removeTags.map((t) => <Combobox.Option id={t._id} label={t.label} />) : null}
							</Combobox>
							<div className={`${excludeSelected?.length == 1 ? '' : 'row'} mt-3 mb-2`}>
								{excludeSelected &&
									excludeSelected?.map((t, index) => {
										return (
											<div className="col-auto d-flex mb-2" style={{ marginRight: '-25px', position: 'relative' }} key={t._id}>
												<BulkTagSVG text={t.label} count={1} />
												<div
													style={{ position: 'absolute' }}
													className="bg-light h-100 border rounded"
													onClick={() => excludeTagRemoveHandler(index)}
												>
													<IoClose className="fs-6 pointer" />
												</div>
											</div>
										);
									})}
							</div>
						</div>
					</Row>
					{checkbox && (
						<Row className="d-flex flex-column gap-3 ms-1 mt-4">
							<Checkbox label={'Close job after completion'} checked={closeJob} onChange={setCloseJob} />
							{closeJob && (
								<RadioGroup onChange={(value) => setCompleteVisit(value)} value={completeVisit} ariaLabel="Companies">
									<RadioOption value="complete" label="Complete past visits" />
									<RadioOption value="delete">
										<>
											Delete past visits
											<i className='text-danger ms-1'>(Note: You'll not be able to reopen this job again)</i>
										</>
									</RadioOption>
								</RadioGroup>
							)}
						</Row>
					)}
					{/* {!checkbox && multipleAddresses?.length > 0 && (
						<div>
							<h6 className="fw-bold mt-4">Multiple address found for following clients: Please select from following to apply tags editing</h6>
							<div className="mt-3">
								{multipleAddresses?.map((a) => {
									return (
										<div>
											<div className="d-flex gap-2 align-items-center">
												<Checkbox label={''} checked={checked.includes(a.id)} onChange={() => handleCheckboxChange(a.id)} />{' '}
												<span className="fw-bold">{a?.name}</span>
											</div>
											<ul>
												{a?.clientProperties?.map((c) => (
													<li>{getCompleteAddress(c)}</li>
												))}
											</ul>
										</div>
									);
								})}
							</div>
						</div>
					)} */}
					{!checkbox && multipleAddresses?.length > 0 && (
						<div>
							<h6 className="fw-bold mt-4">
								Multiple address or specific job statuses found for the following visits: Please review the details below
							</h6>
							<div className="mt-3">
								{multipleAddresses.map((visit, idx) => (
									<div key={visit.visitId} >
										<div className="d-flex gap-2 align-items-center">
											<Checkbox
												label={''}
												checked={checked.includes(visit.clientId)}
												onChange={() => handleCheckboxChange(visit.clientId)}
											/>{' '}
											Client:<span className="fw-bold">{visit?.name}</span>
										</div>
										{/* <div>
											<strong>Visit No:</strong> {idx + 1}
										</div> */}
										{visit.hasMultipleAddresses && (
											<div>
												<ul>
													{visit.clientProperties?.map((property, index) => (
														<li key={index}>{getCompleteAddress(property)}</li>
													))}
												</ul>
											</div>
										)}
										{visit.hasOpenJob && (
											<div className="my-2">
												<span className="me-2 ms-4">Job Status:</span>
												{visit.jobStatus === 'active' ? (
													<div className="badge text-bg-success">{'Active'}</div>
												) : visit.jobStatus === 'upcoming' ? (
													<div className="badge text-bg-info">{'Upcoming'}</div>
												) : visit.jobStatus === 'today' ? (
													<div className="badge text-bg-primary">{'Today'}</div>
												) : (
													<div className="badge text-bg-warning">{visit.jobStatus}</div>
												)}
											</div>
										)}
									</div>
								))}
							</div>
						</div>
					)}
				</Content>
			</Modal>
		</>
	);
}

export default BulkTagModal;
