import { createSlice } from "@reduxjs/toolkit";

const TabsSlice = createSlice({
  name: "TabsSlice",
  initialState: {
    tabs: [],
    recycleTabs: [],
  },
  reducers: {
    setTabs(state, action) {
      state.tabs = action.payload;
    },
    setRecycleTabsR(state, action) {
      state.recycleTabs = action.payload;
    }
  },
});

export const { setTabs, setRecycleTabsR } = TabsSlice.actions;
export default TabsSlice.reducer;
