import { InputPassword } from "@jobber/components/InputPassword";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Button } from "@jobber/components/Button";
import { postToServer } from "../../../components/Common/requests";
import { showToast } from "@jobber/components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { setLoginUser } from "../../../store/userSlice";

function Locker() {
  const user = useSelector((s) => s.user);
  const dispatch = useDispatch();
  console.log(user);
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const submitHandler = async () => {
    if (password !== confirmPassword) {
      showToast({
        message: "Password do not match",
        variation: "error",
      });
      return;
    }

    setLoading(true);
    const result = await postToServer(`setup-lock`, {
      oldPassword,
      password,
      confirmPassword,
    });
    if (result.status) {
      showToast({
        message: "Password set successfully",
      });
      if (!user?.user?.data?.isPasswordCreated) {
        dispatch(
          setLoginUser({
            ...user.user,
            data: { ...user?.user?.data, isPasswordCreated: true },
          })
        );
      }
    } else {
      showToast({
        message: result.message || result.error,
        variation: "error",
      });
    }
    setLoading(false);
  };
  return (
    <div>
      <h6>Please set your locker password</h6>
      <div className="row mt-3">
        <div className="col-lg-6 col-md-8">
          {user?.user?.data?.isPasswordCreated && (
            <InputPassword
              placeholder="Old Password"
              hasVisibility={true}
              value={oldPassword}
              onChange={setOldPassword}
            />
          )}
          <div className="mt-3">
            <InputPassword
              placeholder="Password"
              name="password"
              hasVisibility={true}
              value={password}
              onChange={setPassword}
              validations={{
                required: { value: true, message: "Password is required" },
              }}
            />
          </div>
          <div className="mt-3">
            <InputPassword
              placeholder="Confirm Password"
              hasVisibility={true}
              value={confirmPassword}
              onChange={setConfirmPassword}
              validations={{
                required: {
                  value: true,
                  message: "Confirm Password is required",
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-3">
        {loading ? (
          <Spinner size="base" />
        ) : (
          <Button label={"Set Password"} onClick={submitHandler} />
        )}
      </div>
    </div>
  );
}

export default Locker;
