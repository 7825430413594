import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, LineChart, Line, ResponsiveContainer } from 'recharts';
import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns';
import Charts from './Charts';
import React, { useEffect, useState } from 'react'
import { getFromServer } from '../../../components/Common/requests';
import useGetFromServer from '../../../utils/useGetFromServer';

const ChartsJobs = () => {
  const [queryDate, setQueryDate] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection'
    }
  ]);
  const groupDataByInterval = (data, interval) => {
    return data.reduce((acc, { date, jobClosed }) => {
      let key;
      if (interval === 'day') {
        key = format(parseISO(date), 'dd MMM yyyy'); // Group by day
      } else if (interval === 'month') {
        key = format(parseISO(date), 'MMM yyyy'); // Group by month
      } else if (interval === 'year') {
        key = format(parseISO(date), 'yyyy'); // Group by year
      } else {
        throw new Error('Unsupported interval'); // Handle unsupported intervals
      }

      const existingItem = acc.find(item => item.period === key);
      if (existingItem) {
        existingItem.jobClosed += jobClosed;
      } else {
        acc.push({
          period: key,
          jobClosed: jobClosed
        });
      }

      return acc;
    }, []);
  };

  const renderChart = (chartType, groupedData) => {
    switch (chartType) {
      case 'PieChart':
        return (
          <PieChart width={400} height={400}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={groupedData.map((item) => ({ name: item.period, value: item.jobClosed }))}
              cx={200}
              cy={200}
              outerRadius={150}
              fill="#58b8ff"
              label
            >
              {groupedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        );
      case 'BarChart':
        return (
          <ResponsiveContainer width={'100%'} height={400}>
            <BarChart width={400} height={400} data={groupedData} barCategoryGap={5}>
              <XAxis dataKey="period" axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip />
              <Legend />
              <Bar dataKey="jobClosed" fill="#58b8ff" radius={5} />
            </BarChart>
          </ResponsiveContainer>
        );
      case 'LineChart':
        return (
          <ResponsiveContainer width={'100%'} height={400}>
            <LineChart width={400} height={400} data={groupedData}>
              <XAxis dataKey="period" axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="jobClosed" stroke="#58b8ff" />
            </LineChart>
          </ResponsiveContainer>
        );
      default:
        return null;
    }
  };

  const getDate = (date) => {
    setQueryDate(date)
  }

  const getParam = () => {
    const { startDate, endDate } = queryDate[0]

    format(endDate, 'yyyy-MM-dd')
    return new URLSearchParams({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    });
  }

  const params = getParam()
  const { data, loading } = useGetFromServer(`dashboard-closed-jobs?${params}`, [queryDate])

  return (
    <Charts data={data}
      groupDataByInterval={groupDataByInterval}
      renderChart={renderChart}
      title={'Jobs Closed'}
      groupBy={'day'}
      getDate={getDate}
      isLoading={loading}
    />
  );
};

export default ChartsJobs;
