import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSidebar } from '../../store/layoutSlice';
// import { RxHamburgerMenu } from "react-icons/rx";
import { Heading } from '@jobber/components/Heading';
import { IoIosLogOut, IoMdArrowRoundForward, IoMdArrowRoundBack } from 'react-icons/io';
import ActivityLog from '../ActivityLog/ActivityLog';
import Sync from '../Sync/Sync';

function Header() {
	const dispatch = useDispatch();
	const layout = useSelector((store) => store.layout);
	const menuClickHandler = () => {
		dispatch(setShowSidebar(!layout.showSidebar));
	};
	const logoutuser = () => {
		localStorage.clear();
		window.location.href = '/login';
	};
	return (
		<>
			<header className="header body-pd" id="header">
				<div className="header_toggle d-flex gap-2" onClick={menuClickHandler}>
					{layout.showSidebar ? <IoMdArrowRoundBack /> : <IoMdArrowRoundForward />}
					<Heading level={3}>{'The Royal Gateway'}</Heading>
				</div>
				<div className="header_img pointer">
					<div className="d-flex gap-3">
						<Sync />
						<ActivityLog />
						<IoIosLogOut className="fs-2" onClick={logoutuser} />
					</div>
				</div>
			</header>
		</>
	);
}

export default Header;
