import React, { useState } from 'react'
import { Modal } from '@jobber/components/Modal'
import { Content } from '@jobber/components/Content'
import { Checkbox } from '@jobber/components/Checkbox';
import useGetFromServer from '../../../../utils/useGetFromServer';
import { Button } from '@jobber/components/Button';
import { Autocomplete } from "@jobber/components/Autocomplete";
import { showToast } from '@jobber/components/Toast';
import { InputNumber } from "@jobber/components/InputNumber";

export default function ActionModal({ modalOpen, handleModalClose, handleSubmit }) {
  const [price, setPrice] = useState(0)

  const [inputs, setInputs] = useState([{
    name: '',
    description: ''
  }])

  function handleAddInput() {
    setInputs([...inputs, { name: '', description: '' }])
  }

  function handleRemoveInput(index) {
    const delInput = [...inputs]
    delInput.splice(index, 1)
    setInputs(delInput)
  }

  function handleInputChange(val, index) {
    const newVal = [...inputs]
    newVal[index] = val
    setInputs(newVal)
  }

  const [lineText, setLineText] = useState([{
    name: '',
    description: ''
  }])
  function handleAddLineText() {
    setLineText([...lineText, { name: '', description: '' }])
  }

  function handleRemoveLineText(index) {
    const delText = [...lineText]
    delText.splice(index, 1)
    setLineText(delText)
  }

  function handleLineTextChange(val, index) {
    const newVal = [...lineText]
    newVal[index] = val
    setLineText(newVal)
  }

  const { data } = useGetFromServer('lineItem')

  const [values, setValues] = useState({
    quoteDuplicate: false,
    priceIncrease: false,
    newLineItems: false,
  });

  function handleChange(name, value) {
    if (name === 'quoteDuplicate' && value) {
      setValues({
        quoteDuplicate: true,
        priceIncrease: false,
        newLineItems: false,
      });
    }
    setValues(prevStatus => ({
      ...prevStatus,
      [name]: value,
    }));
  }

  function primaryAction() {
    if (Object.values(values).every(value => value === false)) {
      showToast({ message: "Please select an action", variation: 'info' })
      return
    }

    if (values.priceIncrease && (price <= 0 || isNaN(price))) {
      showToast({ message: "Please enter a valid price", variation: 'error' });
      return;
    }

    if (values.newLineItems) {
      const hasInvalidInputs = inputs.some(input => !input.label || !input.description);
      const hasInvalidTextItems = lineText.some(text => !text.label || !text.description);
      // Check if at least one valid line item or text item is present
      const hasAtLeastOneValidItem = inputs.some(input => input.label && input.description) ||
        lineText.some(text => text.label && text.description);

      if (hasInvalidInputs || hasInvalidTextItems) {
        showToast({ message: "Please fill out all line items and text items", variation: 'error' });
        return;
      }

      if (!hasAtLeastOneValidItem) {
        showToast({ message: "Please add at least one line item or text item", variation: 'error' });
        return;
      }
    }

    if (values.priceIncrease && values.newLineItems) {
      handleSubmit(
        values,
        price,
        inputs.map(({ label, description }) => ({
          name: label,
          description: description
        })),
        lineText.map(({ label, description }) => ({
          name: label,
          description: description
        }))
      );
    } else if (values.quoteDuplicate) {
      handleSubmit(values);
    } else if (values.priceIncrease) {
      handleSubmit(values, price);
    } else if (values.newLineItems) {
      handleSubmit(
        values,
        0,
        inputs.map(({ label, description }) => ({
          name: label,
          description: description
        })),
        lineText.map(({ label, description }) => ({
          name: label,
          description: description
        }))
      );
    }

    setPrice(0);
    setInputs([{ name: '', description: '' }]);
    setLineText([{ name: '', description: '' }]);
    setValues({
      quoteDuplicate: false,
      priceIncrease: false,
      newLineItems: false,
    })
    handleModalClose(false)
  }

  return (
    <Modal
      title={"Select the required action"}
      open={modalOpen}
      onRequestClose={handleModalClose}
      primaryAction={{ label: "Submit", onClick: primaryAction }}
    >
      <Content>
        <div className='d-flex flex-column gap-3'>
          <ActionCheckbox value={values.quoteDuplicate} label={'Duplicate Quotes Exactly'} name={'quoteDuplicate'} disabled={values.newLineItems || values.priceIncrease} handleChange={handleChange} />
          <ActionCheckbox value={values.priceIncrease} label={'Increase Price'} name={'priceIncrease'} disabled={values.quoteDuplicate} handleChange={handleChange}>
            <InputNumber
              value={price}
              onChange={(val) => { setPrice(val) }}
              min={0}
              invalid={price === 0}
            />
          </ActionCheckbox>
          <ActionCheckbox value={values.newLineItems} label={'Add new lineitems'} name={'newLineItems'} disabled={values.quoteDuplicate} handleChange={handleChange}>
            <div className='d-flex flex-column gap-2'>
              {inputs.map((input, index) => (
                <div key={index} className='d-flex gap-2'>
                  <Autocomplete
                    initialOptions={data}
                    placeholder={"Name"}
                    value={input}
                    onChange={(newValue) => handleInputChange(newValue, index)}
                    getOptions={getOptions}
                    allowFreeForm={false}
                    size='small'
                    invalid={input?.description === ''}
                  />
                  <Button onClick={() => handleRemoveInput(index)} icon='trash' variation='destructive' />
                </div>
              ))}
              {lineText.map((text, index) => (
                <div key={index} className='d-flex gap-2'>
                  <Autocomplete
                    initialOptions={data}
                    placeholder={"Text"}
                    value={text}
                    onChange={(newValue) => handleLineTextChange(newValue, index)}
                    getOptions={getOptions}
                    allowFreeForm={false}
                    size='small'
                    invalid={text?.description === ''}
                  />
                  <Button onClick={() => handleRemoveLineText(index)} type='secondary' icon='trash' variation='destructive' />
                </div>
              ))}
              <div className='d-flex gap-2'>
                <Button fullWidth onClick={handleAddInput} icon='plus' label='Add Lineitem' />
                <Button fullWidth onClick={handleAddLineText} variation='subtle' icon='plus' label='Add Text' />
              </div>
            </div>
          </ActionCheckbox>
        </div>
      </Content>
    </Modal>
  )


  function getOptions(text) {
    if (text === "") {
      return data;
    }
    const filterRegex = new RegExp(text, "i");

    return data.filter((option) => option.label.match(filterRegex));
  }
}

function ActionCheckbox({ value, label, name, children, disabled = false, handleChange }) {
  return (
    <div>
      <Checkbox
        disabled={disabled}
        label={label}
        name={name}
        checked={value}
        onChange={(e) => handleChange(name, e)}
      />
      {value && children &&
        <div className='p-2'>
          {children}
        </div>
      }
    </div>
  )
}
