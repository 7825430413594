import { Button } from '@jobber/components/Button';
import { FormField } from '@jobber/components/FormField';
import React, { useEffect, useState } from 'react';
import { Tooltip } from '@jobber/components/Tooltip';
import { postToServer, putToServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import { Spinner } from 'react-bootstrap';
import { useNotesContext } from '../contextAPI/NotesContext';
import { FaRedo } from "react-icons/fa";


function CorrectedNotes({ note, clientId }) {
  const { isAIDiscard, triggerAiDiscarded, triggerCurrentNote, currentNote, setCorrectedNote, setIsNoteEdited } = useNotesContext();
  const [value, setValue] = useState(note?.correctedMessage?.trim());
  useEffect(() => {
    setValue(note?.correctedMessage?.trim());
    setCorrectedNote(note?.correctedMessage?.trim());
  }, [note]);

  const [loading, setLoading] = useState(false);
  const [discardLoading, setDiscardLoading] = useState(false);
  const approveHandler = async () => {
    setLoading(true);
    const result = await putToServer(`update-corrected-note/${clientId}/${note?.id}`, { correctedMessage: value });
    if (result.status) {
      showToast({
        message: 'Corrected note Updated'
      });
    } else {
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log(isAIDiscard);
    if (isAIDiscard && isAIDiscard?.correctedMessage && currentNote == note?.id) {
      setValue(isAIDiscard?.correctedMessage?.trim());
    }
  }, [isAIDiscard]);
  const discardHandler = async () => {
    triggerCurrentNote(note?.id);
    setDiscardLoading(true);
    const result = await postToServer(`correct-note-ai`, { message: value });
    if (result.status) {
      setValue(result?.data?.correctedMessage?.trim());
      triggerAiDiscarded(result?.data);
      setCorrectedNote(result?.data?.correctedMessage);
      setIsNoteEdited(true)
      showToast({
        message: 'Corrected note Updated'
      });
    } else {
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
    setDiscardLoading(false);
  };
  return (
    <div className="mb-3">
      <FormField
        onChange={(e) => {
          setValue(e?.trim());
          setCorrectedNote(e);
          setIsNoteEdited(true)
        }}
        rows={5}
        value={value}
        placeholder={'Corrected Notes'}
        type={'textarea'}
      />
      <div className="mt-3 text-end">
        {/* <Tooltip message={'Approve'}>
          {loading ? <Spinner /> : <Button label="" onClick={approveHandler} icon="checkmark" size="small" type="secondary" />}
        </Tooltip> */}
        <span className="ms-2"></span>
        <Tooltip message={'Recorrect'}>
          {discardLoading ? <Spinner /> : <Button label="" onClick={discardHandler} icon="redo" size="small" type="subtle" />}
        </Tooltip>
      </div>
    </div>
  );
}

export default CorrectedNotes;
