import { createSlice } from "@reduxjs/toolkit";

const automationFilterSlice = createSlice({
  name: "automationfilter",
  initialState: {
    automationFilters: [],
    automationFilterQuery: {},
    status:'All',
    entries: 0,
    runing: true,
    isSavedFilterApplied: false,
  },
  reducers: {
    setAutomationSavedFilters(state, action) {
      state.automationFilters = action.payload;
    },
    setIsSavedFilterAppliedFilters(state, action) {
      state.isSavedFilterApplied = !state.isSavedFilterApplied;
    },
    setStatusFilters(state, action) {
      state.status = action.payload;
    },
    setReduxEntries(state, action) {
      state.entries = action.payload;
      state.runing = false;
    },
    setRuning(state, action) {
      state.runing = action.payload;
    },
    setAutomationFilterQuery(state, action) {
      state.automationFilterQuery = action.payload;
    },
  },
});

export const { setAutomationSavedFilters, setReduxEntries, setRuning, setAutomationFilterQuery,setStatusFilters, setIsSavedFilterAppliedFilters } =
  automationFilterSlice.actions;
export default automationFilterSlice.reducer;
