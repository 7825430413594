import React, { useEffect, useState } from "react";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import Table from "./Table";
import Filters from "./Filters/Filters";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import TagCloudCategory from "./TagCloudCategory/TagCloudCategory";
import TagCloud from "./TagCloud/TagCloud";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Glimmer } from "@jobber/components/Glimmer";
import Offcanvas from "./TagCloudCategory/Offcanvas";
import OffcanvasCloud from "./TagCloud/Offcanvas";
import { useSelector } from "react-redux";
import BulkTagModal from "./BulkTagMangement/BulkTagModal";
import LoadData from "./LoadData/LoadData";
import { FilterProvider } from "./FilterQuery/FilterQuery";
import ShowAppliedFilters from "../../components/Common/ShowAppliedFilters";

function Index() {
  const bulkTags = useSelector((store) => store.bulkTag);
  const [showFilter, setShowFilter] = useState(false);
  const [filterReload, setFilterReload] = useState(true);
  const [tagCloudCategory, setTagCloudCategory] = useState(false);
  const [tagCategoryReload, setTagCategoryReload] = useState(true);
  const [tagCloud, setTagCloud] = useState(false);
  const [tagReload, setTagReload] = useState(true);
  const [bulkTagMangementModal, setBulkTagMangementModal] = useState(false);
  const [bulkTagReload, setBulkTagReload] = useState(true);

  useEffect(() => {
    document.body.classList.add("body-pd");
  }, []);
  const [editSavedFilterId, setSavedFilterId] = useState(undefined);
  const reload = (id) => {
    if (id) {
      setSavedFilterId(id);
    } else {
      setSavedFilterId(undefined);
    }
    setTagCategoryReload(false);
    setTagReload(false);
    setFilterReload(false);
    setTimeout(() => {
      setTagCategoryReload(true);
      setFilterReload(true);
      setTagReload(true);
    }, 1);
  };

  const tagCloudHandler = (bool = null) => {
    if (tagCloudCategory) setTagCloudCategory(false);
    setTagCloud((prev) => {
      if (bool == null) return !prev;
      else bool;
    });
  };
  const tagCloudCategoryHandler = (bool = null) => {
    setTagCloudCategory((prev) => {
      if (bool == null) return !prev;
      else bool;
    });
    if (tagCloud) setTagCloud(false);
  };

  return (
    <FilterProvider>
      <DndProvider backend={HTML5Backend}>
        <div className="row">
          <div
          // className={`${
          //   tagCloudCategory || tagCloud ? "col-md-8" : "col-12"
          // }`}
          >
            <div className="d-flex justify-content-between align-items-center">
              <Heading level={1}>Clients</Heading>
              <div>
                <LoadData />
                <span className="ms-2"></span>
                <Button
                  label={"Filter"}
                  onClick={() => setShowFilter((prev) => !prev)}
                />
                <span className="ms-2"></span>
                {bulkTags?.bulkTag?.length > 0 ? (
                  <>
                    <Button
                      label={"Bulk tag Management"}
                      onClick={() => setBulkTagMangementModal(true)}
                    />
                    <span className="ms-2"></span>
                  </>
              ) : (
                  ""
                )}

                <Button
                  label={"Tag Cloud"}
                  type="secondary"
                  onClick={() => tagCloudHandler(null)}
                />
                <span className="ms-2"></span>
                <Button
                  label={"Tag Cloud Category"}
                  type="secondary"
                  onClick={() => tagCloudCategoryHandler(null)}
                />
              </div>
            </div>
            <div>
            <ShowAppliedFilters page={'clients'} />
            </div>
            <div className={`content ${showFilter ? "slide-in" : "slide-out"}`}>
              <div className="mt-5"></div>
              <Card
                header={{
                  title: "Filters",
                  action: (
                    <Button label="X" onClick={() => setShowFilter(false)} />
                  ),
                }}
              >
                <Content>
                  {filterReload && (
                    <Filters
                      reload={reload}
                      editSavedFilterId={editSavedFilterId}
                    />
                  )}
                </Content>
              </Card>
            </div>
            <div className="mb-5"></div>
            <Table tagCloud={tagCloudCategory} reload={reload} />
          </div>
          {/* <div className={`${tagCloudCategory ? "col-md-4" : "col-12 d-none"}`}> */}

          {/* </div> */}
        </div>

        <OffcanvasCloud
          tagCloudHandler={tagCloudHandler}
          tagReload={tagReload}
          tagCloud={tagCloud}
          setTagReload={setTagReload}
        />
        <Offcanvas
          tagCloudCategoryHandler={tagCloudCategoryHandler}
          tagCategoryReload={tagCategoryReload}
          tagCloudCategory={tagCloudCategory}
          setTagCategoryReload={setTagCategoryReload}
        />

        {bulkTagMangementModal && (
          <BulkTagModal
            modalOpen={bulkTagMangementModal}
            setModalOpen={setBulkTagMangementModal}
          />
        )}
      </DndProvider>
    </FilterProvider>
  );
}

export default Index;
