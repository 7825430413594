import { Button } from '@jobber/components/Button';
import { FormField } from '@jobber/components/FormField';
import React, { useEffect, useState } from 'react';
import { Tooltip } from '@jobber/components/Tooltip';
import { deleteFromServer, putToServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import { Badge, Col, Row, Spinner } from 'react-bootstrap';
import AILogo from '../../../assets/ai.png';
import { Checkbox } from '@jobber/components/Checkbox';
import { FaFileLines } from 'react-icons/fa6';
import { FaFileImage } from 'react-icons/fa';
import { isImage, notesActionsBadge } from '../../../components/Common/Validations';

function Notes({ note, clientId, updateData, filterAction }) {
	const [attachement, setAttachment] = useState(note.linkedTo || { requests: true, quotes: true, jobs: true, invoices: true });
	const [value, setValue] = useState(note?.message?.trim());
	useEffect(() => {
		setValue(note?.message?.trim());
	}, [note]);
	const [loading, setLoading] = useState(false);
	const approveHandler = async () => {
		setLoading(true);
		let params = ''
		if (filterAction.length) {
			params = new URLSearchParams({
				filterAction: filterAction[0].id
			})
		}
		const result = await putToServer(`update-jobber-note/${clientId}/${note?.id}/updated?${params}`, { message: value, linkedTo: attachement });
		if (result.status) {
			updateData(result.data, 'note');
			showToast({
				message: 'Jobber note Updated'
			});
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setLoading(false);
	};
	const [deleteLoading, setDeleteLoading] = useState(false);
	const deleteHandler = async () => {
		setDeleteLoading(true);
		let params = ''
		if (filterAction.length) {
			params = new URLSearchParams({
				filterAction: filterAction[0].id
			})
		}
		const result = await deleteFromServer(`delete-note/${clientId}/${note?.id}?${params}`);
		if (result.status) {
			updateData(result.data, 'note');
			showToast({
				message: 'Note deleted successfully'
			});
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setDeleteLoading(false);
	};

	return (
		<div className="mb-3">
			{note?.isAigenerated ? (
				<div style={{ position: 'relative' }}>
					<FormField onChange={setValue} rows={5} value={value} placeholder={'Notes'} type={'textarea'} />
					<div style={{ position: 'absolute', right: '0', bottom: '1px' }}>
						<img src={AILogo} alt="" style={{ width: '2rem' }} />
					</div>
				</div>
			) : (
				<FormField onChange={setValue} rows={5} value={value} placeholder={'Notes'} type={'textarea'} />
			)}
			<div className="d-flex gap-2 mt-3">
				{note?.noteAttachments?.map((n, i) => {
					return (
						<a href={n?.url} target="_blank">
							{isImage(n?.fileName) ? (
								<div className="attachement text-muted text-center">
									<FaFileImage className="pointer fs-4" /> <br />
									<span className="fs-12 truncate-text d-block mt-1">{n?.fileName}</span>
								</div>
							) : (
								<div className="attachement text-muted text-center">
									<FaFileLines className="pointer fs-4" /> <br />
									<span className="fs-12 truncate-text d-block mt-1">{n?.fileName}</span>
								</div>
							)}
						</a>
					);
				})}
			</div>
			<div className="d-flex gap-1 mb-2">
				{note?.actions?.map((n) => {
					return <Badge bg={notesActionsBadge(n?.type)}>{n?.type}</Badge>;
				})}
			</div>
			<div className="d-flex mt-3 justify-content-between">
				<Row>
					<Col md={6} className="">
						<Checkbox label={'Requests'} checked={attachement.requests} onChange={(e) => setAttachment({ ...attachement, requests: e })} />
						<br />
						<Checkbox label={'Quotes'} checked={attachement.quotes} onChange={(e) => setAttachment({ ...attachement, quotes: e })} />
					</Col>
					<Col md={6} className="">
						<Checkbox label={'Jobs'} checked={attachement.jobs} onChange={(e) => setAttachment({ ...attachement, jobs: e })} />
						<br />
						<Checkbox label={'invoices'} checked={attachement.invoices} onChange={(e) => setAttachment({ ...attachement, invoices: e })} />
					</Col>
				</Row>
				<div className="mt-3 text-end">
					<Tooltip message={'Approve'}>
						{loading ? <Spinner /> : <Button label="" onClick={approveHandler} icon="checkmark" size="small" type="secondary" />}
					</Tooltip>
					<span className="ms-2"></span>
					<Tooltip message={'Delete Note'}>
						{deleteLoading ? <Spinner /> : <Button label="" onClick={deleteHandler} icon="trash" size="small" type="destructive" />}
					</Tooltip>
				</div>
			</div>
		</div>
	);
}

export default Notes;
