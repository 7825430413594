import { createSlice } from "@reduxjs/toolkit";

const notesSlice = createSlice({
  name: "notesSetting",
  initialState: {
    data: {},
  },
  reducers: {
    setNotesSettingsR(state, action) {
      state.data = action.payload;
    }
  },
});

export const { setNotesSettingsR } = notesSlice.actions;
export default notesSlice.reducer;
