import React from 'react';
import './Tooltip.css';

const CustomTooltip = ({ children, text }) => {
  return (
    <div className="c__tooltip">
      {children}
      <span className="tooltiptext">{text}</span>
    </div>
  );
};

export default CustomTooltip;
