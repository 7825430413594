import { createSlice } from "@reduxjs/toolkit";

const bulkTagSlice = createSlice({
  name: "bulkTag",
  initialState: {
    bulkTag: [],
  },
  reducers: {
    setBulkTag(state, action) {
      state.bulkTag = action.payload;
    }
  },
});

export const { setBulkTag } = bulkTagSlice.actions;
export default bulkTagSlice.reducer;
