import { useDispatch } from 'react-redux';
import { getFromServer } from '../components/Common/requests';
import { addUniqueIds } from '../components/Common/Validations';
import { setAllTags } from '../store/tagsSlice';
import { showToast } from '@jobber/components/Toast';

function useReduxHooks() {
  const dispatch = useDispatch();

  const fetchAllTags = async () => {
    const result = await getFromServer('Client-Tags');
    if (result.status) {
      const addUniqueId = addUniqueIds(result.data);
      dispatch(setAllTags(addUniqueId));
    } else {
      showToast({
        message: result.message || result.error,
        variation: 'error'
      });
    }
  };

  return { fetchAllTags };
}

export default useReduxHooks;
