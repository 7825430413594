import React, { useEffect, useState } from 'react';
import { Combobox } from '@jobber/components/Combobox';
import { IoMdCloseCircle } from 'react-icons/io';
import { format } from 'date-fns';
import { getFromServer } from '../../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import { useAutomationFilters } from '../../FilterQuery/AutomationFilterQuery';
import { useVisitFilters } from '../../../AdvanceVisits/FilterQuery/VisitFilterQuery';
import { setTemporaryFilters } from '../../../../components/Common/Validations';
import { useSelector } from 'react-redux';
import useReduxHooks from '../../../../utils/useReduxHooks';
import { Checkbox } from '@jobber/components/Checkbox';
import { FormField } from '@jobber/components/FormField';
import JobDateRangeInput from '../../../../utils/JobDateRangeInput';

function JobFilters({ visits = false }) {
	const { updateFilter, addFilterList } = useAutomationFilters();
	const { fetchAllTags } = useReduxHooks();
	const tagSlice = useSelector((store) => store.tag);
	const [pageLoad, setPageLoad] = useState(true);
	const [isApplied, setIsApplied] = useState(false);
	const [title, setTitle] = useState([]);
	const [lineItems, setLineItems] = useState([]);
	const [tags, setTags] = useState([]);
	const [data, setData] = useState(null);

	useEffect(() => {
		if (pageLoad && isApplied) {
			setPageLoad(false);
		} else {
			const joinedTags = lineItems?.map((t) => t?.label)?.join(',');
			updateFilter('lineItemName', joinedTags);
		}
	}, [lineItems]);

	useEffect(() => {
		if (pageLoad && isApplied) {
			setPageLoad(false);
		} else {
			const joinedTags = tags?.map((t) => t.label)?.join(',');
			updateFilter('includeTags', joinedTags);
		}
	}, [tags]);

	useEffect(() => {
		if (pageLoad && isApplied) {
			setPageLoad(false);
		} else {
			const joinedTags = title?.map((t) => t?.label)?.join(',');
			updateFilter('title', joinedTags);
		}
	}, [title]);

	const init = async () => {
		const result = await getFromServer(visits ? 'visit-line-items' : 'job-line-items');
		if (result.status) {
			setData(result.data);
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
	};

	useEffect(() => {
		init();
		if (tagSlice?.allTags?.length <= 0) {
			fetchAllTags();
		}
	}, []);

	// useEffect(() => {
	// 	console.log('first', isApplied)
	// 	if (!isApplied) {
	// 		const filters = localStorage.getItem('tF') ? JSON.parse(localStorage.getItem('tF')) : null;
	// 		if (filters) {
	// 			setTitle(filters.autoJobTitle || []);
	// 			setLineItems(filters.autoJobLineItem || []);
	// 			setTags(filters.autoJobTags || []);
	// 			// addFilterList({
	// 			// 	lineItemName: [filters.autoJobLineItem?.map((t) => t?.label)?.join(',')],
	// 			// 	includeTags: [filters.autoJobTags?.map((t) => t?.label)?.join(',')],
	// 			// 	title: [filters.autoJobTitle?.map((t) => t?.label)?.join(',')]
	// 			// });
	// 		}
	// 		setIsApplied(true);
	// 	}
	// }, []);

	return (
		<>
			<div style={{ position: 'relative' }}>
				<Combobox
					// multiSelect
					onSelect={(selection) => {
						setTemporaryFilters('tF', visits ? { visitTitle: selection } : { autoJobTitle: selection });
						setTitle(selection);
					}}
					label="Title"
					selected={title}
				>
					{data?.jobTitles?.map((t, i) => t?.title && t?.title.trim() !== '' && <Combobox.Option key={i} id={t.id} label={t.title} />)}
				</Combobox>
				{title?.length > 0 ? (
					<div
						style={{ position: 'absolute', top: '-10px', right: '0' }}
						onClick={() => {
							setTemporaryFilters('tF', visits ? { visitTitle: [] } : { autoJobTitle: [] });
							setTitle([]);
						}}
					>
						<IoMdCloseCircle className="fs-5 pointer" />
					</div>
				) : (
					''
				)}
			</div>
			<div style={{ position: 'relative' }}>
				<Combobox
					// multiSelect
					onSelect={(selection) => {
						setTemporaryFilters('tF', visits ? { visitLineItem: selection } : { autoJobLineItem: selection });
						setLineItems(selection);
					}}
					label="Line Items"
					selected={lineItems}
				>
					{data?.lineItems?.map((t, i) => (
						<Combobox.Option id={t?.id} label={t?.name} />
					))}
				</Combobox>
				{lineItems?.length > 0 ? (
					<div
						style={{ position: 'absolute', top: '-10px', right: '0' }}
						onClick={() => {
							setTemporaryFilters('tF', visits ? { visitLineItem: [] } : { autoJobLineItem: [] });
							setLineItems([]);
						}}
					>
						<IoMdCloseCircle className="fs-5 pointer" />
					</div>
				) : (
					''
				)}
			</div>
			<div style={{ position: 'relative' }}>
				<Combobox
					// multiSelect
					onSelect={(selection) => {
						setTemporaryFilters('tF', visits ? { visitTags: selection } : { autoJobTags: selection });
						setTags(selection);
					}}
					label="Tags"
					selected={tags}
				>
					{tagSlice?.allTags?.map((t, i) => (
						<Combobox.Option id={t?._id} label={`${t?.label}`} />
					))}
				</Combobox>
				{tags?.length > 0 ? (
					<div
						style={{ position: 'absolute', top: '-10px', right: '0' }}
						onClick={() => {
							setTemporaryFilters('tF', visits ? { visitTags: [] } : { autoJobTags: [] });
							setTags([]);
						}}
					>
						<IoMdCloseCircle className="fs-5 pointer" />
					</div>
				) : (
					''
				)}
			</div>
			<JobDateRangeInput visits={visits} />
		</>
	);
}

export default JobFilters;
