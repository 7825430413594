import { createSlice } from "@reduxjs/toolkit";

const tagsSlice = createSlice({
  name: "tags",
  initialState: {
    allTags: [],
    lineItems: [],
    titles: [],
    userTags: [],
    loading: true,
    currentEditingTag: null
  },
  reducers: {
    setAllTags(state, action) {
      state.allTags = action.payload;
      state.loading = false
    },
    setLineItems(state, action) {
      state.lineItems = action.payload;
    },
    setTitles(state, action) {
      state.titles = action.payload;
    },
    setUserTags(state, action) {
      state.userTags = action.payload;
      state.loading = false
    },
    setCurrentEditingTag(state, action) {
      state.currentEditingTag = action.payload;
    },
  },
});

export const { setAllTags, setUserTags, setCurrentEditingTag, setLineItems, setTitles } = tagsSlice.actions;
export default tagsSlice.reducer;
