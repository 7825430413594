import { createSlice } from "@reduxjs/toolkit";

const QuotesTable = createSlice({
  name: "QuotesTable",
  initialState: {
    data: {},
    search: '',
    isPreserved: false,
  },
  reducers: {
    setQuotesDataR(state, action) {
      state.data = action.payload;
    },
    setSearchR(state, action) {
      state.search = action.payload;
    },
    setPreservedR(state, action) {
      state.isPreserved = action.payload;
    },

  },
});

export const { setQuotesDataR, setSearchR, setPreservedR } = QuotesTable.actions;
export default QuotesTable.reducer;
