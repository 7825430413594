import React, { useContext, useEffect, useState } from 'react';
import { SocketContext } from '../../../SocketProvider';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@jobber/components/Button';
import io from 'socket.io-client';
import { postToServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import ProgressBar from 'react-bootstrap/ProgressBar';
import DateRangeModal from '../../../components/Common/DateRangeModal';
import { useLocation } from 'react-router';
import { setStartSyncR } from '../../../store/layoutSlice';

function LoadData({ reload }) {
	const dispatch = useDispatch();
	const { socket, socketHandler } = useContext(SocketContext);
	const [comingMsg, setComingMsg] = useState(null);
	const [progressValue, setProgressValue] = useState(1);
	const [dateModal, setDateModal] = useState(false);

	const userStore = useSelector((store) => store.user);
	const user = userStore?.user?.data;

	// for header button sync
	const location = useLocation();
	useEffect(() => {
		if (location?.state?.sync) {
			setDateModal(true);
			window.history.replaceState({}, '');
		}
	}, [location]);
	// for header button sync  end

	useEffect(() => {
		if (comingMsg) {
			setProgressValue(comingMsg?.progress);
		}
	}, [comingMsg]);

	useEffect(() => {
		if (!socket) {
			const newSocket = io(process.env.REACT_APP_API_URL);
			console.log(newSocket);
			socketHandler(newSocket);
		}

		if (socket) {
			socket.on(`Genie_Visit_progress_${user?._id}`, (notify) => {
				setComingMsg(notify);
			});
		}
	}, [socket]);

	const joinRoom = async () => {
		if (socket && user?._id) {
			await socket.emit('joinGenieRoom', { userId: user?._id });
		}
	};

	useEffect(() => {
		joinRoom();
	}, [user?._id, socket]);

	const [loading, setLoading] = useState(false);

	const syncVisitHandler = async (startDate, endDate) => {
		setDateModal(false);
		setLoading(true);
		dispatch(setStartSyncR(true));
		showToast({
			message: 'Please wait while we are syncing all visits.',
			variation: 'info'
		});
		const result = await postToServer('sync-visits', { startDate, endDate });
		if (result.status) {
			showToast({
				message: 'Sync completed'
			});
			reload();
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setLoading(false);
		setProgressValue(1);
	};

	return (
		<div className="">
			<DateRangeModal open={dateModal} onConfirm={syncVisitHandler} onCancel={() => setDateModal(false)} />
			{loading ? (
				<div className="mb-2">
					<ProgressBar animated variant="success" label={`${progressValue}%`} now={progressValue} />
					<p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }} className="mt-1">
						Do not perform any operation, while we are syncing
					</p>
				</div>
			) : (
				<>
					<Button label={'Sync'} onClick={() => setDateModal(true)} />
				</>
			)}
		</div>
	);
}

export default LoadData;
