import React, { useEffect, useState } from 'react';
import { addDays, format } from 'date-fns';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Button } from '@jobber/components/Button';
import { useAutomationFilters } from '../pages/AdvanceJobs/FilterQuery/AutomationFilterQuery';
import { useVisitFilters } from '../pages/AdvanceVisits/FilterQuery/VisitFilterQuery';
import { Calendar } from 'react-date-range';

function DateInput({ visits }) {
	const { updateFilterByList } = visits ? useVisitFilters() : useAutomationFilters();
	const [date, setDate] = useState(new Date());
	const [isOpen, setIsOpen] = useState(false);
	const [pageLoad, setPageLoad] = useState(true);
	const [displayDate, setDisplayDate] = useState(format(date, 'EEE, MMM d'));

	const handleSelect = (selectedDate) => {
		setDate(selectedDate);
	};

	useEffect(() => {
		setDisplayDate(format(date, 'EEE, MMM d'));
		if (pageLoad) {
			setPageLoad(false);
		} else {
			applyHandler()
		}
	}, [date]);

	const applyHandler = () => {
		setIsOpen(false);
		updateFilterByList({ date: [format(date, 'yyyy-MM-dd')] });
	};

	const moveDate = (days) => {
		setDate((prevDate) => addDays(prevDate, days));
	};

	return (
		<div className="position-relative d-flex align-items-center" style={{ width: '13rem' }}>
			<input readOnly className="form-control w-75" value={displayDate} onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }} />
			<span className="btn btn-sm btn-light ps-2 pe-0 ms-2" onClick={() => moveDate(-1)}>
				<FaArrowLeft style={{ cursor: 'pointer', marginRight: '10px' }} />
			</span>
			<span className="btn btn-sm btn-light pe-2 ps-0 ms-1" onClick={() => moveDate(1)}>
				<FaArrowRight style={{ cursor: 'pointer', marginLeft: '10px' }} />
			</span>
			<div className="datepicker">
				{isOpen && (
					<div style={{ backgroundColor: 'white' }}>
						<Calendar date={date} onChange={handleSelect} color="#7DB00E" />
						{/* <div className="p-2 text-end w-100">
							<Button label="Apply" onClick={applyHandler} />
						</div> */}
					</div>
				)}
			</div>
		</div>
	);
}

export default DateInput;
