import { Button } from '@jobber/components/Button';
import React, { useEffect, useState } from 'react';
import BulkTagModal from '../../../Index/BulkTagMangement/BulkTagModal';
import { getFromServer, postToServer } from '../../../../components/Common/requests';
import { Spinner } from '@jobber/components/Spinner';
import { showToast } from '@jobber/components/Toast';
import { useVisitFilters } from '../../FilterQuery/VisitFilterQuery';

function CompleteVisit({ reload }) {
	const { buildQuery } = useVisitFilters();
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [removeTags, setRemoveTags] = useState([]);

	const init = async () => {
		const queryString = buildQuery();
		const result = await getFromServer(`get-client-tags?${queryString}`);
		if (result.status) {
			setRemoveTags(result.tags);
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
	};
	useEffect(() => {
		if (showModal) {
			init();
		}
	}, [showModal]);

	const submitHandler = async (body) => {
		const queryString = buildQuery();
		setLoading(true);
		setShowModal(false);
		const result = await postToServer(`complete-visits?${queryString}`, body);
		if (result.status) {
			showToast({
				message: 'Visits completed successfully'
			});
			reload();
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setLoading(false);
	};
	return (
		<div>
			<BulkTagModal removeTags={removeTags} checkbox headline='Complete Visits' open={showModal} onCancel={() => setShowModal(false)} onConfirm={submitHandler} />
			{loading ? <Spinner /> : <Button label={'Complete Visits'} onClick={() => setShowModal(true)} />}
		</div>
	);
}

export default CompleteVisit;
