import React, { useState, useEffect } from 'react';
import { MdOutlineDragIndicator } from "react-icons/md";

const ColorPicker = ({ color, colorChangehandler }) => {
  const [showClass, setShowClass] = useState(false);
  const [currenColor, setCurrentColor] = useState(color || '');
  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  useEffect(() => {
    const handleScroll = (event) => {
      // Prevent the page from scrolling when scrolling within the component
      event.stopPropagation();

      if (window.scrollY > 100) {
        // Adjust as needed
        setShowClass(true);
      } else {
        setShowClass(false);
      }
    };

    // Add scroll event listener to the container element
    const container = document.getElementById('container');
    container.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  const [position, setPosition] = useState({ x: 250, y: window.innerHeight - 70 }); // Initial position

  // Handle mouse move to update the position of the floating button
  const handleMouseMove = (event) => {
    setPosition({ x: event.clientX - 15, y: event.clientY - 20 });
  };

  // Handle mouse down to start dragging the button
  const handleMouseDown = (event) => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.body.style.userSelect = 'none'; // Disable text selection
  };

  // Handle mouse up to stop dragging the button
  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    document.body.style.userSelect = ''; // Enable text selection
  };

  return (
    <div
      id="container"
      className="floating-button"
      style={{ left: position.x, top: position.y }}
    >
      <MdOutlineDragIndicator
        color='white'
        size={25}
        onMouseDown={handleMouseDown}
        style={{
          cursor: 'grab'
        }}
      />
      <div className={`d-flex align-items-center gap-2 pointer`}>
        <input
          type="color"
          value={color}
          className="color-picker me-2"
          onChange={(e) => colorChangehandler(e.target.value)}
        />
        <span
          className={`bg-circle ${currenColor == '#FF0000' ? 'active__circle' : ''}`}
          onClick={() => {
            colorChangehandler('#FF0000');
            setCurrentColor('#FF0000');
          }}
          style={{ backgroundColor: '#FF0000' }}
        ></span>
        <span
          className={`bg-circle ${currenColor == '#0273B0' ? 'active__circle' : ''}`}
          onClick={() => {
            colorChangehandler('#0273B0');
            setCurrentColor('#0273B0');
          }}
          style={{ backgroundColor: '#0273B0' }}
        ></span>
        <span
          className={`bg-circle ${currenColor == '#83ff00' ? 'active__circle' : ''}`}
          onClick={() => {
            colorChangehandler('#83ff00');
            setCurrentColor('#83ff00');
          }}
          style={{ backgroundColor: '#83ff00' }}
        ></span>
        <span
          className={`bg-circle ${currenColor == '#FFFF00' ? 'active__circle' : ''}`}
          onClick={() => {
            colorChangehandler('#FFFF00');
            setCurrentColor('#FFFF00');
          }}
          style={{ backgroundColor: '#FFFF00' }}
        ></span>
      </div>
    </div>
  );
};

export default ColorPicker;
