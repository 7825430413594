import React, { useState, useEffect, useRef } from "react";

function BulkTagSVG({ text, count }) {
	text = text;
	const padding = 10; // Padding around the text
	const minTagWidth = 30; // Minimum width for each tag
	const [textWidth, setTextWidth] = useState(0);
	const [countWidth, setCountWidth] = useState(0);
	const [originalWidth, setOriginalWidth] = useState(0); // Initial total width of the SVG
	const [svgWidth, setSvgWidth] = useState(118); // Initial total width of the SVG
	const [longTextWidth, setLongTextWidth] = useState(0); // Initial total width of the SVG
	const [refText, setRefText] = useState(0);

	const textRef = useRef();

	// Function to calculate text width (simple approximation)
	const calculateTextWidth = (text) => {
		const characterWidth = 8; // Average width of a character, adjust as needed
		return text?.length * characterWidth;
	};

	useEffect(() => {
		// Calculate widths for text and count
		const newTextWidth = calculateTextWidth(text) + 2 * padding;
		const newCountWidth = calculateTextWidth(String(count)) + 2 * padding;

		setTextWidth(Math.max(newTextWidth, minTagWidth));
		setCountWidth(Math.max(newCountWidth, minTagWidth));

		// Calculate total SVG width
		const totalSVGWidth = newTextWidth + newCountWidth + 34; // 34 is for non-rectangular part
		setSvgWidth(totalSVGWidth);

		const textWidth = textRef.current ? textRef.current.getBBox().width : 0;
		setRefText(textRef.current.getBBox().width);

		// Calculate total SVG width
		const totalWidth = textWidth + 2 * padding + 34;
		setLongTextWidth(Math.max(totalWidth, 80));
    setOriginalWidth(textRef.current.getBBox().width)
	}, [text, count, padding]);

	// click effect
	const [color1, setColor1] = useState("#D9D9D9");
	const [color3, setColor3] = useState("black");


	return (
		<svg
			className="pointer"
			height="26"
			viewBox={`0 0 ${originalWidth + 50} 30`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M15.0659 0L29.9999 14.934L14.934 30L3.53548 18.6015C1.58286 16.6489 1.58285 13.4831 3.53547 11.5304L15.0659 0Z" fill={color1} />
			<rect width={longTextWidth - 34} height="30" transform={`translate(15 0)`} fill={color1} />
			<text ref={textRef} x={(longTextWidth - 34) / 2 + 15} y="20" textAnchor="middle" fill={color3}>
				{text}
			</text>
		</svg>
	);
}

export default BulkTagSVG;
