import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import React, { useState } from "react";
import Filters from "./Filters/Filters";

function FiltersIndex() {
  const [showFilter, setShowFilter] = useState(false);
  const [filterReload, setFilterReload] = useState(true);
  const [editSavedFilterId, setSavedFilterId] = useState(undefined);

  const reload = (id) => {
    if (id) {
      setSavedFilterId(id);
    } else {
      setSavedFilterId(undefined);
    }
    setFilterReload(false);
    setTimeout(() => {
      setFilterReload(true);
    }, 1);
  };
  return (
    <>
      <Button label={"Filter"} onClick={() => setShowFilter((prev) => !prev)} />

      <div className={`content remain__on__top ${showFilter ? "slide-in" : "slide-out"}`} style={{top:'-3rem'}}>
        <div className="mt-5"></div>
        <Card
          header={{
            title: "Filters",
            action: <Button label="X" onClick={() => setShowFilter(false)} />,
          }}
        >
          <Content>
            {filterReload && (
              <Filters reload={reload} editSavedFilterId={editSavedFilterId} setShowFilter={setShowFilter}/>
            )}
          </Content>
        </Card>
      </div>
    </>
  );
}

export default FiltersIndex;
