import React from 'react';
import { Chip } from '@jobber/components/Chip';
import { useQuoteFilter } from '../../pages/AdvanceQuotes/FilterQuery/QuoteFilterQuery';
import { useFilter } from '../../pages/Index/FilterQuery/FilterQuery';
import { useNotesFilter } from '../../pages/AdvanceNotes/FilterQuery/NotesFilterQuery';
import { useJobsFilter } from '../../pages/Jobs/FilterQuery/JobsFilterQuery';
import { useAutomationFilters } from '../../pages/AdvanceJobs/FilterQuery/AutomationFilterQuery';
import { useVisitFilters } from '../../pages/AdvanceVisits/FilterQuery/VisitFilterQuery';

function ShowAppliedFilters({ page }) {
	const { filters } =
		page == 'quotes'
			? useQuoteFilter()
			: page == 'notes'
			? useNotesFilter()
			: page == 'jobs'
			? useJobsFilter()
			: page == 'automation'
			? useAutomationFilters()
			: page == 'visits'
			? useVisitFilters()
			: useFilter();

	const formatHeading = (key) => {
		const spacedText = key.replace(/([A-Z])/g, ' $1').trim();
		return spacedText.charAt(0).toUpperCase() + spacedText.slice(1);
	};

	const renderChip = (key, label) => {
		const heading = formatHeading(key);
		return <Chip  heading={heading} label={label} />;
	};

	return (
		<div className="mt-3 applied__filters d-flex gap-3 flex-wrap me-1 justify-content-start align-items-center">
			{Object.entries(filters).map(([key, value], index, array) => {
				// Skip rendering for "Value" keys, as they are handled with their corresponding "Type"
				if (key.endsWith('Value')) return null;
				if (key.endsWith('limit')) return null;
				if (key.endsWith('Key')) return null;
				if (key.endsWith('key')) return null;
				if (key.endsWith('Direction')) return null;
				if (key.endsWith('direction')) return null;
				if (key.endsWith('page')) return null;
				if (key.endsWith('Page')) return null;

				const baseKey = key.replace('FilterType', '');
				const valueKey = baseKey + 'FilterValue';
				const filterType = value[0];
				const filterValue = array.find(([k]) => k === valueKey)?.[1]?.[0];

				if (baseKey.endsWith('Complete') && (filterType == 'all' || filterType == undefined || filterType == 'undefined')) return null;

				// Render for filters with both Type and Value
				if (filterType && filterValue) {
					return renderChip(baseKey, `(${filterType}) ${filterValue}`);
				}

				// Render for filters with value but no specific type-value pairing
				if (value?.[0]?.length > 0) {
					return renderChip(baseKey, value.join(', '));
				}

				return null;
			})}
		</div>
	);
}

export default ShowAppliedFilters;
