import { Heading } from "@jobber/components/Heading";
import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Locker from "./components/Locker";
import QuickBooks from "./components/QuickBooks";
import { useLocation } from 'react-router-dom';

function Settings() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get('tab');
  const [tab, setTab] = useState(param || 'locker');
  useEffect(() => {
    document.body.classList.add("body-pd");
  }, []);

  return (
    <div>
      <div className="d-flex mb-4 justify-content-between align-items-center">
        <Heading level={1}>Settings</Heading>
      </div>
      <Tabs
        id="settings"
        activeKey={tab}
        onSelect={(k) => {
          setTab(k);
        }}
        className="mb-3 custom-tab"
      >
        <Tab eventKey={'locker'} title={<Heading level={4}>Locker</Heading>}>
          <Locker />
        </Tab>
        <Tab eventKey={'quickbooks'} title={<Heading level={4}>Quickbooks</Heading>}>
          <QuickBooks />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Settings;
