import { Button } from '@jobber/components/Button';
import Modal from 'react-bootstrap/Modal';
import ReactButton from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useState } from 'react';
import { Heading } from '@jobber/components/Heading';
import { Col, Row } from 'react-bootstrap';
import Schedule from './Schedule';
import { useJobContext } from '../contextAPI/JobContext';
import Team from './Team';
import Invoice from './Invoice';
import { postToServer } from '../../../components/Common/requests';
import { useQuoteFilter } from '../FilterQuery/AutomationFilterQuery';
import { showToast } from '@jobber/components/Toast';
import { InputText } from '@jobber/components/InputText';

function ConvertToJobsModal({ show, handleStop, handleStart, handleClose }) {
  const { oneOffJob, setOneOffJob, saveHandler, instruction, setInstruction, setSelectedIds } = useJobContext();
  const { buildQuery } = useQuoteFilter();
  const [error, setError] = useState('');
  const saveButtonHandler = async () => {
    const body = await saveHandler();
    if (typeof body === 'string') {
      setError(body);
    } else {
      setError('');
      const queryString = buildQuery();
      handleStart();
      const result = await postToServer(`convertToJob?${queryString}`, body);
      setSelectedIds([])
      if (result.status) {
        handleStop();
        showToast({
          message: 'Quotes converted to jobs successfully'
        });
      } else {
        showToast({
          message: result.message || result.error,
          variation: 'error'
        });
      }
    }
  };
  return (
    <Modal show={show} fullscreen={true} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Heading level={2}>Convert to Job</Heading>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <div className="text-center">
            <h6 className="text-danger">{error}</h6>
          </div>
        )}

        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="mb-4">
              <h4>Instructions</h4>
              <InputText placeholder="Instructions" name="firstName" size="small" value={instruction} onChange={(e) => setInstruction(e)} />
            </div>
          </div>
          <div className="col-lg-8 col-md-6"></div>
        </div>

        <h4>Type</h4>
        <ButtonGroup className="mt-2">
          <ReactButton
            style={{
              backgroundColor: oneOffJob ? 'var(--green)' : 'transparent',
              borderColor: '#32821C',
              color: oneOffJob ? 'white' : '#424E56'
            }}
            onClick={() => setOneOffJob(true)}
          >
            One-off Job
          </ReactButton>
          <ReactButton
            style={{
              backgroundColor: oneOffJob ? 'transparent' : 'var(--green)',
              borderColor: '#32821C',
              color: oneOffJob ? '#424E56' : 'white'
            }}
            onClick={() => setOneOffJob(false)}
          >
            Recurring Job
          </ReactButton>
        </ButtonGroup>
        <Row className="d-flex align-items-stretch mt-3">
          <Col lg={6}>
            <Schedule oneOffJob={oneOffJob} />
          </Col>
          <Col lg={6}>
            {/* <Team oneOffJob={oneOffJob} /> */}
            <Invoice oneOffJob={oneOffJob} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button label="Cancel" type={'secondary'} onClick={handleClose} />
        <span className="ms-2"></span>
        <Button label="Save" onClick={saveButtonHandler} />
      </Modal.Footer>
    </Modal>
  );
}

export default ConvertToJobsModal;
