import React, { useCallback, useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TagIcon from "./TagIcon";
import { Spinner } from "@jobber/components/Spinner";
import EditableText from "../../../components/Common/EditableText";
import { Button } from "@jobber/components/Button";
import { InputText } from "@jobber/components/InputText";
import { deleteFromServer, getFromServer, getFromServerHardToken, postToServer, postToServerNoToken, putToServer } from "../../../components/Common/requests";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "@jobber/components/Toast";
import { addUniqueIds } from "../../../components/Common/Validations";
import tagsSlice, { setAllTags, setCurrentEditingTag, setUserTags } from "../../../store/tagsSlice";
import { Glimmer } from "@jobber/components/Glimmer";
import { Heading } from "@jobber/components/Heading";
import { useFilter } from "../FilterQuery/FilterQuery";
import { ImBin } from "react-icons/im";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";

// import { Tooltip } from "@jobber/components/Tooltip";
import { MdInfoOutline } from "react-icons/md";
import CustomTooltip from "./CustomTooltip";
import ConfirmModal from "./ConfirmModal";
import { FiEdit } from "react-icons/fi";
import { IoMdSave } from "react-icons/io";
import { IoClose } from "react-icons/io5";

import downFill from "../../../assets/icons/downFill.svg";
import sideFill from "../../../assets/icons/sideFill.svg";

const Tag = ({ tag, source, moveTag, id, sameTagsClickHandler, excludeTagIdList, includeTagIdList }) => {
	const tagSlice = useSelector((store) => store.tag);
	const [{ isDragging }, drag] = useDrag(() => ({
		type: "tag",
		item: { id: tag._id, source, cid: id, tag: tag },
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();
			if (item && dropResult && item.source !== dropResult.category) {
				moveTag(tagSlice.userTags, item.id, item.tag, item.source, item.cid, dropResult.cid);
			}
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	}));

	return (
		<div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
			<TagIcon name={tag.label} id={tag._id} count={tag.count} source={source} sameTagsClickHandler={sameTagsClickHandler} includeTagIdList={includeTagIdList} excludeTagIdList={excludeTagIdList} />
		</div>
	);
};

const Category = ({ name, index, des, tags, moveTag, id, setDeleteObject, isEditing, setEditing, cancelEditing, deleteTag, sameTagsClickHandler, excludeTagIdList, includeTagIdList, moveCategory, isShow, handleToggle }) => {
	const dispatch = useDispatch();
	const [, drop] = useDrop(() => ({
		accept: "tag",
		drop: () => ({ category: name, cid: id }),
	}));

	const [{ isDragging }, drag] = useDrag(() => ({
		type: "category",
		item: { index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	}));

	const [, catDrop] = useDrop({
		accept: "category",
		drop: (item) => {
			if (item.index !== index) {
				moveCategory(item.index, index);
				item.index = index;
			}
		},
	});

	const [save, setSaveHandler] = useState(false);
	const [loading, setLoading] = useState(false);

	return (
		<div ref={drop}>
			<div>
				<div
					className="d-flex gap-1 align-items-center"
					ref={(node) => drag(catDrop(node))}
					style={{
						opacity: isDragging ? 0 : 1,
						height: isDragging ? "1px" : "inherit",
					}}
				>
					{isShow ? <img src={downFill} className="me-2" width="3%" onClick={() => handleToggle(id)} /> : <img src={sideFill} className="me-2" width="3%" onClick={() => handleToggle(id)} />}
					<EditableText onClick={() => handleToggle(id)} initialText={name} des={des} id={id} isEditing={isEditing} cancelEditing={cancelEditing} save={save} setLoading={setLoading} setSaveHandler={setSaveHandler} />
					{des && (
						<CustomTooltip text={des}>
							<MdInfoOutline className="fs-6 pointer" />
						</CustomTooltip>
					)}
					{isEditing ? (
						loading ? (
							<Spinner size="small" />
						) : (
							<IoMdSave
								className="fs-5 pointer"
								title="save"
								onClick={() => {
									setSaveHandler(true);
									setLoading(true);
								}}
							/>
						)
					) : (
						<FiEdit className="pointer" onClick={setEditing} />
					)}
					<ImBin
						className="pointer text-danger"
						onClick={() => {
							setDeleteObject({ open: true, name, id });
							setTimeout(() => {
								var element = document.getElementsByClassName("QGRLFHPoV5E-")[0];
								if (element) {
									element.style.zIndex = "99999";
								}
							}, 10);
						}}
					/>
					{isEditing && <IoClose className="fs-5 pointer" onClick={cancelEditing} />}
				</div>
				{isShow ? (
					<div className="row mt-3 mb-2">
						{tags.map((tag) => (
							<div className="col-auto d-flex mb-2" style={{ marginRight: "-25px", position: "relative" }} key={tag._id}>
								<Tag
									tag={tag}
									source={name} // Source is the category name
									moveTag={moveTag}
									id={id}
									sameTagsClickHandler={sameTagsClickHandler}
									includeTagIdList={includeTagIdList}
									excludeTagIdList={excludeTagIdList}
								/>
								{isEditing && (
									<div style={{ position: "absolute" }} className="bg-light rounded" onClick={() => deleteTag(tag._id, id)}>
										<IoClose className="fs-5 pointer" onClick={deleteTag} />
									</div>
								)}
							</div>
						))}
					</div>
				) : (
					<div className="mb-3"></div>
				)}
			</div>
		</div>
	);
};

const TagCloudCategory = ({ reload, tagCloud, setPageLoad, isPageLoad }) => {
	const dispatch = useDispatch();
	const { updateFilterByListTags } = useFilter();
	const tagSlice = useSelector((store) => store.tag);
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(true);
	// const [allTags, setAlltags] = useState([]);
	const [showInputBox, setShowInputBox] = useState(false);

	const fetchResult = async () => {
		const [result, alltags] = await Promise.all([getFromServer("tags"), getFromServer("Client-Tags")]);
		if (result.status) {
			dispatch(setUserTags(result.data));
			setLoading(false);
		} else {
			showToast({
				message: result.message || result.error,
				variation: "error",
			});
		}
		if (alltags.status) {
			const addUniqueId = addUniqueIds(alltags.data);
			dispatch(setAllTags(addUniqueId));
			if (isPageLoad) {
				const tempFilter = JSON.parse(localStorage.getItem("tempFilter"));
				if (tempFilter?.includeTags || tempFilter?.excludeTags) {
					handleAppliedTagsFilter(result.data, addUniqueId, tempFilter);
				}
				setPageLoad(false);
			}
		} else {
			showToast({
				message: alltags.message || alltags.error,
				variation: "error",
			});
		}
	};
	useEffect(() => {
		console.log(tagSlice?.userTags);
		if (tagSlice?.userTags?.length <= 0 || tagSlice?.allTags?.length <= 0) {
			fetchResult();
		} else {
			setLoading(false);
		}
	}, []);

	const moveTag = async (userTags, tagId, tag, source, fromCid, toCid) => {
		if (fromCid == toCid) {
			return;
		}

		let data = await JSON.parse(JSON.stringify(userTags));
		let tagToMove = null;

		if (source == "All Tags") {
			const toCategory = await userTags.find((category) => category._id == toCid);
			if (toCategory) {
				// Create a new array by spreading the old one
				let newTag = [...toCategory.tags, tag];
				console.log("newtag", newTag);

				showToast({
					message: `Please wait until tag added`,
					variation: "info",
				});

				const result = await putToServer(`tags/${toCid}`, {
					tags: newTag,
				});

				if (result.status) {
					// Update the category with the new tags from the response
					// Find the category again in case `data` has changed
					const categoryToUpdate = await data.find((c) => c._id === toCid);
					if (categoryToUpdate) {
						categoryToUpdate.tags = result.data.tags;

						// Dispatch an action to update the state with the new data
						console.log(data);
						dispatch(setUserTags([...data]));

						data = data;
					}
					showToast({ message: `Tag Moved Successfully` });
				} else {
					showToast({
						message: result.message || result.error,
						variation: "error",
					});
				}
				// reload();
			}
		} else {
			// Find and remove the tag from the from-category
			const fromCategory = data.find((category) => category._id === fromCid);

			if (fromCategory) {
				const tagIndex = fromCategory.tags.findIndex((tag) => tag._id === tagId);
				if (tagIndex > -1) {
					[tagToMove] = fromCategory.tags.splice(tagIndex, 1);
				}
			}

			// If the tag was found and removed, add it to the to-category
			let toCategory = [];
			if (tagToMove) {
				toCategory = data.find((category) => category._id === toCid);
				if (toCategory) {
					toCategory.tags.push(tagToMove);
				}
			}
			const result = await putToServer(`tags/${toCid}`, { tags: toCategory?.tags });
			if (result.status) {
				const result2 = await putToServer(`tags/${fromCid}`, { tags: fromCategory?.tags });
				if (result2.status) {
					showToast({
						message: "Tags moved successfully",
					});
					dispatch(setUserTags(data));
				} else {
					showToast({
						message: result2.message || result2.error,
						variation: "error",
					});
				}
			} else {
				showToast({
					message: result.message || result.error,
					variation: "error",
				});
			}
		}
		updateFilterByListTags({ includeTags: [], excludeTags: [] });
		localStorage.removeItem('tagCloudInclude');
		localStorage.removeItem('notesTagCloudInclude');
		localStorage.removeItem('tagCloudExclude');
		localStorage.removeItem("notesTagCloudExclude");
		reload();
	};

	const deleteTag = async (tagId, catId) => {
		// Find the index of the category
		const categoryIndex = tagSlice?.userTags.findIndex((category) => category._id === catId);

		if (categoryIndex > -1) {
			// Make a shallow copy of the category to avoid mutating the original state
			const newCategory = { ...tagSlice.userTags[categoryIndex] };

			// Find the index of the tag to delete
			const tagIndex = newCategory.tags.findIndex((tag) => tag._id === tagId);

			if (tagIndex > -1) {
				// Make a shallow copy of the tags array
				const newTags = [...newCategory.tags];

				// Remove the tag using splice on the copied array
				newTags.splice(tagIndex, 1);

				// Update the copied category's tags
				newCategory.tags = newTags;

				// Make a shallow copy of the userTags and replace the updated category
				const newUserTags = [...tagSlice.userTags];
				newUserTags[categoryIndex] = newCategory;

				// Dispatch the updated userTags
				dispatch(setUserTags(newUserTags));

				const result = await putToServer(`tags/${catId}`, {
					tags: newTags,
				});

				if (result.status) {
					showToast({ message: `Tag deleted Successfully` });
					reload();
				} else {
					showToast({
						message: result.message || result.error,
						variation: "error",
					});
				}
			}
		}
	};

	// useEffect(() => {
	//   console.log('first')
	//   setMoveTag(() => moveTagFunction);
	// }, [tagSlice?.userTags])

	// const [tempData, setTempData] = useState(tagSlice?.userTags)
	// useEffect(()=>{
	//   console.log('changed', tagSlice?.userTags)
	//   setTempData(tagSlice?.userTags)
	// },[tagSlice?.userTags])

	// adding category
	const [categoryName, setCategoryName] = useState("");
	const [description, setDescription] = useState("");
	const handleAddCategory = async () => {
		if (!categoryName) {
			showToast({ message: "Please provide catgory name", variation: "error" });
			return;
		}
		setSaving(true);
		const result = await postToServer("tags", { categoryName, description });
		console.log(result);
		if (result.status) {
			const newdata = [...tagSlice?.userTags, result.data];
			dispatch(setUserTags(newdata));
			showToast({
				message: "Category added successfully",
				variation: "success",
			});
			setCategoryName("");
			setShowInputBox(false);
			reload();
		} else {
		}
		setSaving(false);
	};

	const [deleteObject, setDeleteObject] = useState({
		open: false,
		name: "",
		id: null,
	});
	const [isApiCalled, setApiCalled] = useState(false);
	const deleteCategoryHandler = async () => {
		setDeleteObject((prev) => ({ ...prev, open: false }));
		if (!isApiCalled) {
			setLoading(true);
			setApiCalled(true);
			const result = await deleteFromServer(`tags/${deleteObject.id}`);
			if (result.status) {
				showToast({
					message: "Category deleted sucessfully",
					variation: "success",
				});
				const result2 = await getFromServer("tags");
				if (result2.status) {
					dispatch(setUserTags(result2.data));
				} else {
					showToast({
						message: result2.message || result2.error,
						variation: "error",
					});
				}
				reload();
			} else {
				showToast({
					message: result.message || result.error,
					variation: "error",
				});
			}

			setCurrentlyEditing(null);
			dispatch(setCurrentEditingTag(null));
			setLoading(false);
			setApiCalled(false);
		}
	};

	// Handling edit mode for hiding other catgrories
	const [currentlyEditing, setCurrentlyEditing] = useState(tagSlice?.currentEditingTag || null);

	const entry = useSelector((store) => store.filters);
	const [sameTagIdList, setSameTagIdList] = useState({
		includeTags: [],
		excludeTags: [],
	});
	const handleAppliedTagsFilter = (userTags, allTags, query) => {
		let includeTagIdList = [];
		let exlcudeTagIdList = [];
		userTags?.forEach((cat) => {
			cat?.tags.forEach((t) => {
				if (query?.includeTags?.includes(t.label)) {
					includeTagIdList.push(t._id);
				}
				if (query?.excludeTags?.includes(t.label)) {
					exlcudeTagIdList.push(t._id);
				}
			});
		});
		allTags?.forEach((t) => {
			if (query?.includeTags?.includes(t.label)) {
				includeTagIdList.push(t._id);
			}
			if (query?.excludeTags?.includes(t.label)) {
				exlcudeTagIdList.push(t._id);
			}
		});

		console.log(includeTagIdList, exlcudeTagIdList);
		setSameTagIdList({
			includeTags: includeTagIdList,
			excludeTags: exlcudeTagIdList,
		});
	};

	useEffect(() => {
		handleAppliedTagsFilter(tagSlice?.userTags, tagSlice.allTags, entry?.filterQuery);
		console.log('called again', entry?.filterQuery)
	}, [entry?.isSavedFilterApplied]);

	const sameTagsClickHandler = (tagText, key) => {
		let tagIdList = [];
		tagSlice?.userTags?.forEach((cat) => {
			cat?.tags.forEach((t) => {
				if (t.label === tagText) {
					tagIdList.push(t._id);
				}
			});
		});
		tagSlice.allTags?.forEach((t) => {
			if (t.label === tagText) {
				tagIdList.push(t._id);
			}
		});

		setSameTagIdList({ ...sameTagIdList, [key]: tagIdList });
	};

	const moveCategory = (dragIndex, hoverIndex) => {
		const dragItem = tagSlice?.userTags[dragIndex];
		const newFilters = [...tagSlice?.userTags];
		newFilters.splice(dragIndex, 1);
		newFilters.splice(hoverIndex, 0, dragItem);
		dispatch(setUserTags(newFilters));
		handleRearrangeCategory(newFilters);
	};

	const handleRearrangeCategory = async (newFilters) => {
		const result = await postToServer("Update-Category-Position", {
			categories: newFilters,
		});
		if (result.status) {
			showToast({
				message: "Categories order updated successfully",
			});
		} else {
			showToast({
				message: result.message || result.error,
				variation: "error",
			});
		}
		reload();
	};

	const handleToggle = (id) => {
		if (id == "all") {
			const newData = (tagSlice?.userTags || []).map((t) => ({
				...t,
				isShow: t.hasOwnProperty("isShow") ? !t.isShow : true,
			}));
			dispatch(setUserTags(newData));
		} else {
			const newCat = [...tagSlice?.userTags];
			const catIndex = tagSlice?.userTags?.findIndex((cat) => cat._id === id);
			if (catIndex > -1) {
				newCat[catIndex] = {
					...newCat[catIndex],
					isShow: !newCat[catIndex]?.isShow,
				};
			}
			dispatch(setUserTags(newCat));
		}
		reload();
	};

	return (
		<div>
			<ConfirmModal
				title={`Delete ${deleteObject.name}?`}
				message={"Deleting this will remove all related tags from your account."}
				open={deleteObject.open}
				onConfirm={deleteCategoryHandler}
				onCancel={() => setDeleteObject((prev) => ({ ...prev, open: false }))}
			/>
			<div className="text-success mb-2 pointer" style={{ width: "fit-content" }} onClick={() => handleToggle("all")}>
				Toggle all
			</div>
			{!loading ? (
				tagSlice?.userTags?.map((t, index) => {
					if (currentlyEditing == null || currentlyEditing == t._id) {
						return (
							<Category
								index={index}
								key={t?.categoryName}
								isShow={t?.isShow}
								handleToggle={handleToggle}
								name={t?.categoryName}
								des={t?.description}
								tags={t?.tags}
								moveTag={moveTag}
								id={t._id}
								setDeleteObject={setDeleteObject}
								isEditing={currentlyEditing === t._id}
								moveCategory={moveCategory}
								setEditing={() => {
									setCurrentlyEditing(t._id);
									dispatch(setCurrentEditingTag(t._id));
								}}
								cancelEditing={() => {
									setCurrentlyEditing(null);
									dispatch(setCurrentEditingTag(null));
								}}
								deleteTag={deleteTag}
								sameTagsClickHandler={sameTagsClickHandler}
								includeTagIdList={sameTagIdList?.includeTags}
								excludeTagIdList={sameTagIdList?.excludeTags}
							/>
						);
					}
				})
			) : (
				<>
					<Glimmer shape={"rectangle"} size={"large"} timing={"base"} />
					<div className="mt-3"></div>
					<Glimmer shape={"rectangle"} size={"base"} timing={"base"} />
				</>
			)}

			<div className="mt-4">
				{showInputBox ? (
					<div className="mt-1">
						<InputText
							placeholder="Category name"
							name="name"
							size="small"
							value={categoryName}
							onChange={(e) => setCategoryName(e)}
							validations={{
								required: {
									value: true,
									message: "Tell us your category name",
								},
								minLength: {
									value: 2,
									message: "Your name is too short.",
								},
							}}
						/>
						<div className="mt-2">
							<InputText placeholder="Description" size="small" value={description} onChange={(e) => setDescription(e)} />
						</div>
						<div>
							{saving ? (
								<div className="mt-3 ">
									<Spinner size={"small"} />
								</div>
							) : (
								<div className="mt-2">
									<Button label={"Add"} size="small" onClick={handleAddCategory} />
									<span className="ms-2"></span>
									<Button label={"Close"} size="small" type="secondary" onClick={() => setShowInputBox(false)} />
								</div>
							)}
						</div>
					</div>
				) : (
					<Button label="Add Category" icon={"plus"} onClick={() => setShowInputBox(true)} />
				)}
			</div>

			<div className="mt-4">
				<div>
					<Heading level={4}>All Tags</Heading>
					<div className="row mt-3 mb-2" style={currentlyEditing ? { overflowY: "scroll", maxHeight: "50vh" } : { overflow: "auto" }}>
						{tagSlice?.allTags
							? tagSlice.allTags.map((tag) => (
								<div className="col-auto d-flex mb-2" style={{ marginRight: "-25px" }} key={tag._id}>
									<Tag tag={tag} source={"All Tags"} moveTag={moveTag} sameTagsClickHandler={sameTagsClickHandler} includeTagIdList={sameTagIdList?.includeTags} excludeTagIdList={sameTagIdList?.excludeTags} />
								</div>
							))
							: null}

						{loading && (
							<div className="p-2">
								<Glimmer shape={"rectangle"} size={"large"} timing={"base"} />
								<div className="mt-3"></div>
								<Glimmer shape={"rectangle"} size={"base"} timing={"base"} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TagCloudCategory;
