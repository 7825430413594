import { createSlice } from "@reduxjs/toolkit";

const AutomationTable = createSlice({
  name: "AutomationTable",
  initialState: {
    data: {},
    search: '',
    isPreserved: false,
  },
  reducers: {
    setAutomationDataR(state, action) {
      state.data = action.payload;
    },
    setSearchR(state, action) {
      state.search = action.payload;
    },
    setPreservedR(state, action) {
      state.isPreserved = action.payload;
    },

  },
});

export const { setAutomationDataR, setSearchR, setPreservedR } = AutomationTable.actions;
export default AutomationTable.reducer;
