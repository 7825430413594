import { Spinner } from "@jobber/components/Spinner";
import React from "react";

function Loader() {
  return (
    <div className="loader-container">
      <Spinner size="base" />
    </div>
  );
}

export default Loader;
