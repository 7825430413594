import { createSlice } from "@reduxjs/toolkit";

const quoteFilterSlice = createSlice({
  name: "quotefilter",
  initialState: {
    quoteFilters: [],
    quoteFilterQuery: {},
    status:'All',
    entries: 0,
    runing: true,
    isSavedFilterApplied: false,
  },
  reducers: {
    setQuoteSavedFilters(state, action) {
      state.quoteFilters = action.payload;
    },
    setIsSavedFilterAppliedFilters(state, action) {
      state.isSavedFilterApplied = !state.isSavedFilterApplied;
    },
    setStatusFilters(state, action) {
      state.status = action.payload;
    },
    setReduxEntries(state, action) {
      state.entries = action.payload;
      state.runing = false;
    },
    setRuning(state, action) {
      state.runing = action.payload;
    },
    setQuoteFilterQuery(state, action) {
      state.quoteFilterQuery = action.payload;
    },
  },
});

export const { setQuoteSavedFilters, setReduxEntries, setRuning, setQuoteFilterQuery,setStatusFilters, setIsSavedFilterAppliedFilters } =
  quoteFilterSlice.actions;
export default quoteFilterSlice.reducer;
