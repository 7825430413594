import React, { useState, useRef, useEffect } from 'react'
import { Combobox } from '@jobber/components/Combobox';
import { Chip } from '@jobber/components/Chip';
import BulkTagSVG from '../../../Index/BulkTagMangement/BulkTagSVG';
import { IoClose } from 'react-icons/io5';
import { Icon } from '@jobber/components/Icon';
import { useSelector } from 'react-redux';

function useDebounce(callback, delay) {
  const timeoutRef = useRef(null);

  function debouncedFunction(...args) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return debouncedFunction;
};

export default function Filters({ getTags }) {
  const tagSlice = useSelector((store) => store.tag);

  const [includedSelected, setIncludedSelected] = useState([]);
  const [excludedSelected, setExcludedSelected] = useState([]);

  const updateTags = useDebounce((included, excluded) => {
    getTags({
      include: included.map(tag => tag.label),
      exclude: excluded.map(tag => tag.label),
    });
  }, 500);

  function getSelected(value, type) {
    if (type === 'include') {
      setIncludedSelected(value);
      updateTags(value, excludedSelected);
    }
    if (type === 'exclude') {
      setExcludedSelected(value);
      updateTags(includedSelected, value);
    }
  }

  function removeHandler(index, type) {
    if (type === 'include') {
      const updatedIncluded = includedSelected.filter((_, i) => index !== i);
      setIncludedSelected(updatedIncluded);
      updateTags(updatedIncluded, excludedSelected);
    }
    if (type === 'exclude') {
      const updatedExcluded = excludedSelected.filter((_, i) => index !== i);
      setExcludedSelected(updatedExcluded);
      updateTags(includedSelected, updatedExcluded);
    }
  }

  return (
    <div className='d-flex flex-column gap-2'>
      <Combo heading={'Include Tags'} type={'include'} selected={includedSelected} slice={tagSlice} getSelected={getSelected} removeHandler={removeHandler} />
      <Combo heading={'Exclude Tags'} type={'exclude'} selected={excludedSelected} slice={tagSlice} getSelected={getSelected} removeHandler={removeHandler} />
    </div>
  )
}

function Combo({ heading, type, selected, slice, getSelected, removeHandler }) {
  return (
    <div className='d-flex align-items-center gap-2'>
      <Combobox
        multiSelect
        onSelect={(selection) => {
          getSelected(selection, type);
        }}
        selected={selected}
      >
        <Combobox.Activator>
          <Chip heading={heading} variation={'subtle'}>
            <Chip.Suffix>
              <Icon name={'add'} size={'large'} />
            </Chip.Suffix>
          </Chip>
        </Combobox.Activator>
        {slice?.allTags ? slice.allTags.map((t) => <Combobox.Option key={t._id} id={t.label} label={t.label} />) : null}
      </Combobox>
      <div style={{ flexWrap: 'wrap', maxHeight: '60px', overflow: 'auto' }} className={`${selected?.length == 1 ? '' : 'd-flex gap-2'}`}>
        {selected &&
          selected?.map((t, index) => {
            return (
              <div className="d-flex" style={{ position: 'relative' }} key={t._id}>
                <BulkTagSVG text={t.label} id={t} count={1} />
                <div
                  style={{ position: 'absolute' }}
                  className="bg-light h-100 border rounded"
                  onClick={() => removeHandler(index, type)}
                >
                  <IoClose className="fs-6 pointer" />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  )
}
