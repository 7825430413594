import React, { useContext, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@jobber/components/Button';
import io from 'socket.io-client';

import { showToast } from '@jobber/components/Toast';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useQuoteFilter } from '../FilterQuery/QuoteFilterQuery';
import { formdataToServer, getFromServer, postToServer } from '../../../components/Common/requests';
import { SocketContext } from '../../../SocketProvider';
import { setLoginUser } from '../../../store/userSlice';
import QuoteDateRangeModal from '../../../components/Common/QuoteDateRangeModal';
import { useLocation, useNavigate } from 'react-router';
import { setStartSyncR } from '../../../store/layoutSlice';

function LoadData({ handleSynQuotes }) {
	const dispatch = useDispatch();
	const { socket, socketHandler } = useContext(SocketContext);
	const { addFilterList } = useQuoteFilter();
	const [comingMsg, setComingMsg] = useState(null);
	const [currentStatus, setCurrentStatus] = useState('');
	const [stopPrgress, setStopPrgress] = useState(null);
	const [progressValue, setProgressValue] = useState(1);
	const [dateModal, setDateModal] = useState(false);

	const userStore = useSelector((store) => store.user);
	const user = userStore?.user?.data;

	// for header button sync
	const location = useLocation();
	useEffect(() => {
		if (location?.state?.sync) {
			setDateModal(true)
			window.history.replaceState({}, '')
		}
	}, [location]);
	// for header button sync  end

	useEffect(() => {
		if (comingMsg) {
			setProgressValue(comingMsg?.progress == 'NaN' ? 0 : comingMsg?.progress);
		}
	}, [comingMsg]);

	useEffect(() => {
		if (stopPrgress) {
			showToast({
				message: stopPrgress?.message,
				variation: 'error'
			});
			handleSynQuotes();
			setLoading(false);
		}
	}, [stopPrgress]);

	useEffect(() => {
		if (!socket) {
			const newSocket = io(process.env.REACT_APP_API_URL);
			socketHandler(newSocket);
		}

		if (socket) {
			socket.on(`Genie_Quote_progress_${user?._id}`, (notify) => {
				setCurrentStatus(notify?.message);
				setComingMsg(notify);
			});
			socket.on(`Genie_Quote_Status_${user?._id}`, (notify) => {
				setCurrentStatus(notify?.status);
				showToast({
					message: notify?.status,
					variation: 'info'
				});
			});

			socket.on(`Genie_Quote_syncCompleted_${user?._id}`, (notify) => {
				showToast({
					message: 'Fetch Completed'
				});
				setCurrentStatus('');
				setProgressValue(1);
				handleSynQuotes();
				setLoading(false);
				dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: false, progress: '1' } }));
			});

			socket.on(`Genie_Quote_syncStarted_${user?._id}`, (notify) => {
				setDateModal(false);
				setLoading(true);
				showToast({
					message: 'Please wait while we are syncing.',
					variation: 'info'
				});
				dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: true, progress: '1' } }));
			});

			socket.on(`Genie_error_${user?._id}`, (notify) => {
				setStopPrgress(notify);
			});

			socket.on('error', (errorMessage) => {
				console.error(errorMessage);
			});

			socket.on('connect', (successMessage) => {
				console.log('socket connected');
			});

			socket.on(`QuickBooksSyncStart_${user?._id}`, (msg) => {
				setLoading(true);
				setProgressValue(msg.progress);
				setCurrentStatus(msg.message);
				showToast({
					message: msg.message,
					variation: 'info'
				});
				dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: true, progress: msg.progress } }));
			});

			socket.on(`QuickBooksSyncProgress_${user?._id}`, (msg) => {
				setLoading(true);
				setCurrentStatus(msg.message);
				setProgressValue(msg.progress);
				dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: true, progress: msg.progress } }));
			});

			socket.on(`QuickBooksSyncComplete_${user?._id}`, (msg) => {
				setLoading(false);
				setProgressValue(msg.progress);
				setCurrentStatus(msg.message);
				showToast({
					message: msg.message,
					variation: 'info'
				});
				dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: false, progress: msg.progress } }));
			});

			socket.on(`QuickBooksSyncError_${user?._id}`, (msg) => {
				setLoading(false);
				setCurrentStatus(msg.message);
				setProgressValue(msg.progress);
				showToast({
					message: msg.message,
					variation: 'info'
				});
				dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: false, progress: msg.progress } }));
			});
		}
	}, [socket]);

	const joinRoom = async () => {
		if (socket && user?._id) {
			await socket.emit('joinGenieRoom', { userId: user?._id });
		}
	};

	useEffect(() => {
		joinRoom();
	}, [user?._id, socket]);

	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (user?.isQuoteSyncing) {
			setLoading(true);
			setProgressValue(user?.progress);
		} else {
			setLoading(false);
			setProgressValue(1);
		}
	}, [user]);
	const syncAllClientHandler = async (s, e, statuses) => {
		setDateModal(false);
		setLoading(true);
		dispatch(setStartSyncR(true))
		showToast({
			message: 'Please wait while we are syncing.',
			variation: 'info'
		});
		const result = await postToServer('sync-quotes', { startDate: s, endDate: e, statuses });
		if (result.status) {
			dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: true, progress: '1' } }));
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
			dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: false, progress: '1' } }));
			setLoading(false);
		}
	};

	async function handleSyncQB() {
		setLoading(true);
		showToast({
			message: 'Please wait while we are syncing.',
			variation: 'info'
		});
		const response = await postToServer('qb/sync-clients')
		if (response.status) {
			dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: true, progress: '1' } }));
		} else {
			showToast({
				message: response.message || response.error,
				variation: 'error'
			});
			dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: false, progress: '1' } }));
			setLoading(false);
		}
	}

	return (
		<>
			<QuoteDateRangeModal open={dateModal} onConfirm={syncAllClientHandler} onCancel={() => setDateModal(false)} />
			{loading ? (
				<div className="mb-2">
					<p className="text-center">{currentStatus}</p>
					<ProgressBar animated variant="success" label={`${progressValue}%`} now={progressValue} />
					<p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }} className="mt-1">
						Do not perform any operation, while we are syncing
					</p>
				</div>
			) : (
				<>
					<Button label={'Sync Advance Quotes'} onClick={() => setDateModal(true)} />
					<Button icon='quickbooks' iconOnRight label={'Sync'} onClick={handleSyncQB} />
				</>
			)}
		</>
	);
}

export default LoadData;
